import { Component, EventEmitter, OnInit, Output, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { ACTIVATE_SCENARIO, GET_SCENARIO_ACTIVITIES } from 'src/app/core/constants/graphql.query.constants';
import { StateService } from '../../../../core/services/state.service';
import { PatientService } from '../../../../core/services/patient.service';
import { Activity, IEventActivityType } from 'src/app/core/model/patient.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { APP_CONSTANTS } from 'src/app/core/constants/app.constants';
import { MedicalContent, MedicalContentNames } from 'src/app/core/model/common.interface';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const COMPONENT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
  }
};

@Component({
  selector: 'app-multi-scenario',
  templateUrl: './multi-scenario.component.html',
  styleUrls: ['./multi-scenario.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: COMPONENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
      }
  ]
})
export class MultiScenarioComponent implements OnInit {
  @Output() public closeOn = new EventEmitter();
  @Output() public activateSuccess = new EventEmitter();
  public startDate = new FormControl(moment(), Validators.required);
  public offset = new FormControl(0, Validators.required);
  public viewType = new FormControl('month', Validators.required);
  hospital: string;
  public hospitals: string[];
  public intervalsLength: number;
  events$ = new BehaviorSubject(null);
  public calendar_start_date = moment(new Date()).subtract(1, 'year').toDate();
  public activityTypes: IEventActivityType[] = [];
  public carepathwaySubs: Subscription;
  intervalType: string;
  intervalIndexes: number[] = [];
  scenarioActivities: Activity[];
  medicalContent: MedicalContent

  constructor(private fb: FormBuilder, private stateService: StateService, private patientStateService: PatientStateService,
    public dialogRef: MatDialogRef<MultiScenarioComponent>, private apollo: Apollo, private dateAdapter: DateAdapter<any>,
    private patientService: PatientService, @Inject(MAT_DIALOG_DATA) public data, @Inject(MAT_DATE_FORMATS) public dateFormatsData: any) {
       dateFormatsData.display.dateInput = this.stateService.getMedicalContent.formatting.shortDateFormat.replace('dd', 'DD');
  }

  ngOnInit(): void {
    this.medicalContent = this.stateService.getMedicalContent;
    // this.startDate.setValue(moment(moment().format(this.stateService.getMedicalContent.formatting.shortDateFormat.replace('dd', 'DD')));
    this.getPreviewData();
    if (this.patientStateService.activityTypes.length === 0) {
      this.getActivityTypes();
    } else {
      this.activityTypes = this.patientStateService.activityTypes;
      this.startDate.setValue(moment());
    }
   
  }

  getPreviewData() {
    this.apollo.query({
      query: GET_SCENARIO_ACTIVITIES,
      variables: {
        scenarioId: this.data.scenarioId,
        startOffsetInDays: this.viewType.value === 'month' ? this.offset.value * 30 : this.offset.value * 7,
        startDate: moment(this.startDate.value).format(APP_CONSTANTS.YYYY_MM_DD_FORMAT).toString()
      }
    }).subscribe((response) => {
      this.scenarioActivities = response.data['getScenarioActivities'];
    }), (error) => {
      console.log('Error in fetching scenario activities', error);
    }
  }

  activate(): any {

    /*Enable new treatment activation*/
    this.apollo.mutate({
      mutation: ACTIVATE_SCENARIO,
      variables: {
        diseaseId: this.patientStateService.diseaseId,
        scenarioIdToActivate: this.data.scenarioId,
        startDate: moment(this.startDate.value).format(APP_CONSTANTS.YYYY_MM_DD_FORMAT).toString(),
        startOffset: this.viewType.value === 'month' ? this.offset.value * 30 : this.offset.value * 7
      }
    }).subscribe(({ data }) => {
      this.patientStateService.setTreatmentId = this.data.scenarioId;
      const addedActivities: Activity[] = (data['activateScenario'].addedActivities ?
        data['activateScenario'].addedActivities.map((activity) => ({ ...activity, status: null })) : []);
      const canceledActivites = new Set<string>();
      // IE11 does not support new Set(array)
      data['activateScenario'].canceledActivities?.forEach(activity => {
        canceledActivites.add(activity.activityId);
      });

      const filteredVal = this.patientStateService.carePathwayActivities?.length > 0 ? this.patientStateService.carePathwayActivities.filter(
        (activity) => (!canceledActivites.has(activity.activityId))) : [];
      this.patientStateService.setCarePathwayActivites = [].concat(filteredVal, addedActivities);
      this.activateSuccess.emit(true);
      this.dialogRef.close({ canceled: false, startDate: this.startDate.value });
    }, (error) => {
      this.activateSuccess.emit(false);
      this.dialogRef.close({ canceled: true });
    });
  }

  /**
   * Function call to get the details of activity Types
   */
  getActivityTypes() {
    this.medicalContent = this.stateService.getMedicalContent;
    const medicalContentNames: MedicalContentNames = this.stateService.getMedicalContentNames;
    const filteredDisease = this.medicalContent.diseases.filter((disease) => disease.id === this.patientStateService.diseaseId)[0];
    const uniquetypes = [...new Set(filteredDisease.availableActivities.map(item => item.type))];
    uniquetypes.forEach((type) => {
      const filteredTypes = filteredDisease.availableActivities.filter((activity) => activity.type === type);
      this.activityTypes.push({
        type: type,
        activities: filteredTypes,
        name: medicalContentNames.activityLabels[type]
      })
    });
    this.patientStateService.setActivityTypes = this.activityTypes;
  }

  onCloseClick(): void {
    this.dialogRef.close({ canceled: true });
  }
}
