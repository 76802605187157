import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AuthTokenResponse } from 'src/app/core/model/auth.interface';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'src/app/core/services/message.service';
import { StateService, LangType } from 'src/app/core/services/state.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

const OVERVIEW_URL = '/overview';
const PATIENT_URL = '/patient/{patientId}';

@Component({
  selector: 'app-after-launch',
  templateUrl: './after-launch.component.html',
  styleUrls: ['./after-launch.component.scss']
})
export class AfterLaunchComponent implements OnInit {

  hasError = false;
  lang: LangType;
  hasNoPatient = false;
  hasNoPractitioner = false;

  constructor(private authService: AuthService, private translate: TranslateService, private router: Router, private route: ActivatedRoute, private stateService: StateService,
    private messageService: MessageService, private http: HttpClient) { }


  ngOnInit(): void {
    this.stateService.lang.subscribe((lang) => {
      this.lang = lang;
      this.translate.use(lang);
    });
    const dummyPractitionerId = this.route.snapshot.queryParamMap.get('dummy_practitioner_id');
    const afterLaunchResponse$ = dummyPractitionerId ? this.getTokenForDev(dummyPractitionerId, this.route.snapshot.queryParamMap)
      : this.getTokenForEhrSystem(this.route.snapshot.queryParamMap);
    // Subscribe also to providerId to make sure the auth service finished updating local storage + stateStore
    combineLatest([afterLaunchResponse$, this.stateService.providerIdObs]).subscribe(([response, providerId]) => {
      this.stateService.setSso(response.sso);
      this.stateService.setEmbeddedLaunch(response.embeddedLaunch !== undefined ? response.embeddedLaunch : false);
      if (response.patientId) {
        sessionStorage.setItem('patientId', response.patientId);
      }
      const url = environment.endpoint + environment.services.get_practitioner
        .replace('{providerId}', providerId)
        .replace('{practitionerId}', response['practitionerId']);
      this.http.get(url).pipe(shareReplay(1)).subscribe(practitionerResponse => {
        sessionStorage.setItem('currentUser', JSON.stringify(practitionerResponse));
        const navigateUrl = response.patientId ? (response.isNewPatient ?
          PATIENT_URL.replace('{patientId}', response.patientId)
          : PATIENT_URL.replace('{patientId}', response.patientId)
        ) : OVERVIEW_URL;
        this.router.navigate([navigateUrl], { state: {patientAccessToken: response.token} });
      }, () => {
        this.hasError = true;
      });
    }, (ex) => {
      if (ex.error?.error === 'PRACTITIONER_NOT_FOUND') {
        this.hasNoPractitioner = true;
      } else if (ex.error?.error === 'PATIENT_NOT_FOUND') {
        this.hasNoPatient = true;
      }
      this.hasError = true;
    });
  }

  getTokenForDev(dummyPractitionerId: string, queryParamMap: ParamMap): Observable<AuthTokenResponse> {
    const dummyProviderId = queryParamMap.get('dummy_provider_id');
    const dummyPatientId = queryParamMap.get('dummy_patient_id');
    return this.authService.getDevToken(dummyProviderId, dummyPractitionerId, dummyPatientId);
  }

  getTokenForEhrSystem(queryParamMap: ParamMap): Observable<AuthTokenResponse> {
    const ehrSystem = queryParamMap.get('ehr_system');
    if (ehrSystem === 'EPIC') {
      return this.getTokenForEpic(queryParamMap);
    } else if (ehrSystem === 'HIX') {
      return this.getTokenForHix(queryParamMap);
    } else {
      this.messageService.showGenericError();
    }
  }

  getTokenForEpic(queryParamMap: ParamMap): Observable<AuthTokenResponse> {
    const code = queryParamMap.get('code');
    const state = queryParamMap.get('state');
    const error = queryParamMap.get('error');
    const errorDescription = queryParamMap.get('error_description');
    if (error) {
      this.messageService.showGenericError();
      return;
    }
    return this.authService.getTokenFromEpicAfterLaunch(code, state);
  }

  getTokenForHix(queryParamMap: ParamMap): Observable<AuthTokenResponse> {
    const physicianId = queryParamMap.get('physicianId');
    const patientId = queryParamMap.get('patientId');
    const time = queryParamMap.get('time');
    const hash = queryParamMap.get('hash');
    const providerId = queryParamMap.get('providerId');
    return this.authService.getHixAuthorizationToken(providerId, physicianId, patientId, time, hash);
  }
}
