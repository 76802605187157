import { Component, OnDestroy, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, Subscription } from 'rxjs';
import { distinct, filter } from 'rxjs/operators';
import { GET_PATIENT_QUERY } from 'src/app/core/constants/graphql.query.constants';
import { MedicalContent, MedicalContentNames } from 'src/app/core/model/common.interface';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { Patient } from '../../../core/model/patient.interface';
import { LangType, StateService } from '../../../core/services/state.service';

@Component({
    selector: 'app-property',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit, OnDestroy {
    patientData: Patient;
    providerId: string;
    diseaseId: string;
    diseases: string[];
    diseaseName: string;
    lang: LangType;
    dateFormat: string;
    medicalContentNames : MedicalContentNames;
    medicalContent: MedicalContent;
    private querySubscription: Subscription;

    constructor(private stateService: StateService, private patientService: PatientService,
        private patientStateService: PatientStateService, private apollo: Apollo, private communicationService: CommunicationService) {
    }

    ngOnInit(): void {
        this.stateService.lang.subscribe(res => {
            this.lang = res;
            this.getDisease(this.patientStateService.diseaseId);
        });
        this.stateService.medicalContentNamesObs.subscribe((data) => {
            this.medicalContentNames = data;
            if(data !== null) {
                this.getPatient();
            }
        });
        this.stateService.medicalContentObs.subscribe((data: MedicalContent) => {
            this.dateFormat = data?.formatting.shortDateFormat ;
        })
        this.communicationService.reloadPatientProperties$.subscribe(() => {
           this.getPatient(); 
        });

        this.patientStateService.patientDetailObs.subscribe((data) => {
            this.patientData = data as Patient;
        });
    }

    getPatient(): void {
        this.patientStateService.patientIdSubject$.pipe(filter(id => !!id), distinct()).subscribe((patientId) => {
            this.querySubscription = this.apollo
                .query({
                    query: GET_PATIENT_QUERY,
                }).subscribe((data) => {
                    const patientData = data.data['getPatient'];
                    this.patientData = patientData;
                    const diseaseAndPractitionerId = patientData['diseasesAndPractitioners'].substring(1, patientData['diseasesAndPractitioners'].length-1);
                    this.getDisease(diseaseAndPractitionerId.split('=')[0]);
                });
        });
    }

    // TODO : this needs to be changed , disease details needs to be picked from 
    getDisease(diseaseId): void {
            this.diseaseName = this.medicalContentNames?.diseases[diseaseId].name[this.lang];
            this.patientStateService.setDiseaseId = diseaseId;
            this.patientStateService.setDiseaseDetail = { diseaseId, diseaseName: this.diseaseName };
    }

    ngOnDestroy() {
        this.querySubscription.unsubscribe();
    }
}
