import {Component, Input, OnInit, TemplateRef} from '@angular/core';


@Component({
    selector: 'app-recommendation-tooltip',
    templateUrl: './recommendation-tooltip.component.html',
    styleUrls: ['./recommendation-tooltip.component.scss']
})
export class RecommendationTooltipComponent implements OnInit {

    @Input() text: string;

    @Input() contentTemplate: TemplateRef<any>;

    constructor() { }

    ngOnInit(): void {
    }

}
