<div class="delete-patient-dialog">
    <h2 mat-dialog-title>{{ 'overview_page.delete_text' | translate }}</h2>
    <mat-dialog-content class="mat-typography content">
        <p>{{ 'overview_page.delete_info_text' | translate }}</p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="dh-btn dh-btn-secondary mat-button mat-button-base">{{'cancel' | translate}}</button>
      <button (click)="deletePatient()" class="dh-btn dh-btn-primary mat-button mat-button-base"
       mat-button>{{'confirm' | translate}}</button>
    </mat-dialog-actions>
  </div>
  