<div class="questionnaire">
    <div class="row card dh-recommender">
        <div class="card-content row">
            <div class="card-header col-md-12" style="padding-bottom: 0;">
                <div class="card-title">
                    {{title}}
                </div>
            </div>

            <div class="card-header col-md-12" style="padding-top: 0; padding-bottom: 20px">
                <form id="recommendationsForm" [formGroup]="recommendationFormGroup">
                    <div id="recommendations" *ngIf="recommendations && recommendations.length > 0">
                        <div class="recommendation-card col" *ngFor="let r of recommendations | slice:0:3">
                                <mat-card>
                                    <mat-card-content style="text-align: center;" class="recommedation-block">
                                        <ng-container
                                            *ngIf="r.action === 'TEXT_RECOMMENDATION'; else scenarioTemplate ">
                                            <b>{{r.title[lang]}}</b>
                                            <div class="text-recommendation">
                                                <span>
                                                    {{r.recommendedText[lang]}}
                                                </span>
                                            </div>
                                        </ng-container>

                                        <ng-template #scenarioTemplate>
                                            <b>{{'recommend.recommendation_title' | translate}}</b>
                                            <div class="recommendation-scenario-name">
                                                <span>
                                                    {{names?.scenarios[r.activateScenario].name[lang]}}
                                                </span>
                                            </div>
                                        </ng-template>
                                    </mat-card-content>
                                    <ng-container [ngTemplateOutlet]="cardActionsTemplate"
                                        [ngTemplateOutletContext]="{ $implicit: r }"></ng-container>
                                </mat-card>
                        </div>
                    </div>
                </form>

                <div id="welcome" *ngIf="!recommendations || recommendations.length < 1">
                    <span *ngIf="processingRecommendation">
                        <h2 class="quote-style"> {{'recommend.processing' |translate }}</h2>
                        <div><mat-progress-spinner class="spinner" [diameter]="50"
                                [mode]="'indeterminate'"></mat-progress-spinner></div>
                    </span>

                    <span *ngIf="!processingRecommendation">
                        <h2 class="quote-style">{{'recommend.start_title' |translate }}</h2>
                        <p class="quote-style" *ngIf="showRecommender">{{'recommend.start_content' |translate }}</p>
                        <p class="quote-style" *ngIf="!showRecommender">{{'recommend.no_questionnaire_text' |translate
                            }}</p>

                        <div style="display: flex; justify-content: center;" *ngIf="showRecommender">
                            <button type="button" class="btn btn-success" (click)="showStep(true)">
                                {{'start' | translate}}
                            </button>
                        </div>
                    </span>
                </div>

                <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit(stepper)">
                    <mat-horizontal-stepper linear #stepper id="steps" style="display: none;">
                        <ng-template matStepperIcon="edit" let-index="index">
                            {{index + 1}}
                        </ng-template>

                        <div *ngFor="let question of activeQuestionData">
                            <mat-step [stepControl]="question.id" [completed]="completed">
                                <div>

                                    <p class="quote-style" [innerHTML]="getQuestionText(question)"></p>
                                </div>
                                <div class="mat-action" [ngSwitch]="question.questionType">
                                    <div *ngSwitchCase="'MULTIPLE_CHOICE_SINGLE_SELECT'">

                                        <mat-radio-group aria-label="Select an option"
                                            formControlName="{{question.id}}">
                                            <mat-radio-button style="margin-right: 10px;" (change)="stepValueSelect()"
                                                *ngFor="let option of question.optionMapping | keyvalue"
                                                [value]="option.id">
                                                {{option.text[lang] }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <div style="display: flex; justify-content: center;">
                                            <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                                (click)="stepNumDec()" matStepperPrevious>{{'back' | translate
                                                }}</button>
                                            <button type="button" class="btn btn-success"
                                                (click)="stepNumInc(question.id,stepper)">
                                                {{'next' | translate }}
                                            </button>
                                        </div>
                                    </div>

                                    <!-- <div *ngSwitchCase="'MULTIPLE_CHOICE_SINGLE_SELECT'">

                                        <mat-radio-group aria-label="Select an option"
                                            formControlName="{{question.id}}">
                                            <mat-radio-button style="margin-right: 10px;"
                                                (change)="stepValueSelect(question.id, $event)"
                                               

                                                *ngFor="let option of question.optionMapping | keyvalue"
                                                    [value]="option.key">
                                                    {{option.value.text[lang]}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <div style="display: flex; justify-content: center;">
                                            <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                                (click)="stepNumDec()" matStepperPrevious>{{'back' | translate
                                                }}</button>
                                            <button type="button" class="btn btn-success"
                                                (click)="stepNumInc(question.id,stepper)">
                                                {{'next' | translate }}
                                            </button>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'SWITCH'">

                                        <mat-form-field appearance="fill"
                                            style="display: flex; justify-content: center;">
                                            <mat-select formControlName="{{question.id}}"
                                                (selectionChange)="stepValueSelect(question.id, $event)"
                                                [compareWith]="optionCompareFunc">
                                                <mat-option  *ngFor="let option of question.optionMapping | keyvalue"
                                                [value]="option.id">
                                                {{option.text[lang] }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div style="display: flex; justify-content: center;">
                                            <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                                (click)="stepNumDec()" matStepperPrevious>{{'back' | translate}}
                                            </button>
                                            <button type="button" class="btn btn-success"
                                                (click)="stepNumInc(question.id,stepper)">{{'next' | translate}}
                                            </button>
                                        </div>
                                    </div> -->

                                    <div *ngSwitchCase="'MULTIPLE_CHOICE_MULTI_SELECT'">
                                        <mat-form-field appearance="fill"
                                            style="display: flex; justify-content: center;">
                                            <mat-select multiple formControlName="{{question.id}}"
                                                (selectionChange)="stepValueSelect()" [compareWith]="optionCompareFunc">
                                                <mat-option *ngFor="let option of question.optionMapping | keyvalue"
                                                    [value]="option">
                                                    {{option.choiceText | translatable:lang }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div style="display: flex; justify-content: center;">
                                            <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                                (click)="stepNumDec()" matStepperPrevious>{{'back' | translate}}
                                            </button>
                                            <button type="button" class="btn btn-success"
                                                (click)="stepNumInc(question.id,stepper)">{{'next' | translate}}
                                            </button>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'SELECT_ONE_BUTTON'">
                                        <mat-radio-group aria-label="Select an option"
                                            formControlName="{{question.id}}">
                                            <mat-radio-button *ngFor="let option of question.optionMapping | keyvalue"
                                                (change)="stepValueSelect()" [value]="option.key" class="m-2">
                                                {{ option.value.text | translatable:lang }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <div style="display: flex; justify-content: center;">
                                            <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                                (click)="stepNumDec()" matStepperPrevious>{{'back' | translate}}
                                            </button>
                                            <button type="button" class="btn btn-success"
                                                (click)="stepNumInc(question.id,stepper)"> {{'next' | translate}}
                                            </button>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'INT'">
                                        <mat-form-field class="example-full-width"
                                            style="display: flex; justify-content: center;">
                                            <input matInput type="number" formControlName="{{question.id}}">
                                        </mat-form-field>
                                        <div style="display: flex; justify-content: center;">
                                            <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                                (click)="stepNumDec()" matStepperPrevious>{{'back' | translate}}
                                            </button>
                                            <button type="button" class="btn btn-success"
                                                (click)="stepNumInc(question.id,stepper)"> {{'next' | translate}}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <!-- <div style="display: flex; justify-content: center;">
                                  <button style="margin-right: 10px;" type="button" class="btn btn-primary" (click)="stepNumDec()"
                                    matStepperPrevious>Back</button>
                                  <button type="button" class="btn btn-success" (click)="stepNumInc()" matStepperNext>Next</button>
                                </div> -->
                            </mat-step>
                        </div>
                        <mat-step>
                            <h2 class="quote-style">{{'recommend.submit_title' |translate }}</h2>
                            <p class="quote-style">{{'recommend.submit_content' | translate}}</p>
                            <div style="display: flex; justify-content: center;">
                                <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                    matStepperPrevious>
                                    {{'back' | translate}}
                                </button>
                                <button type="submit" class="btn btn-danger" matStepperNext
                                    (click)="stepNumInc('',stepper)">
                                    {{'submit' | translate}}
                                </button>
                            </div>
                        </mat-step>
                    </mat-horizontal-stepper>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #cardActionsTemplate let-r>
    <mat-card-actions style="margin-bottom: 0">
        <div class="card-action-content" fxLayout="row" fxLayoutAlign="center">
            <mat-icon aria-hidden="false" appRecommendationToolTip [contentTemplate]="template">
                info
            </mat-icon>

            <ng-template #template>
                <div style="display: flex; flex-direction: column">
                    <h3>{{'recommend.evidence' |translate}}</h3>
                    <span style="color: black; font-weight: bold;" [innerHTML]="r.comments[lang]"></span>
                    <span [innerHTML]="r.source[lang]"></span>
                </div>
            </ng-template>

            <button style="margin-right: 10px" type="submit" class="btn btn-warning"
                (click)="rejectRecommendation(r.recommendationId)">
                {{'recommend.reject' |translate }}
            </button>
            <button style="margin-right: 10px" type="submit" class="btn btn-success"
                (click)="acceptRecommendation(r.recommendationId, r.activateScenario, r.action)">
                {{'recommend.accept' |translate }}
            </button>
        </div>
    </mat-card-actions>
</ng-template>