import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogResponse} from '../../../../../core/constants/dialog_response.constant';


@Component({
  selector: 'confirm-deactivation-modal.component',
  templateUrl: 'deactivate-confirm-modal.component.html',
})
export class ConfirmDeactivationModal {

  scenarioName: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDeactivationModal>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.scenarioName = data.scenarioName;
  }

  confirm() {
    this.dialogRef.close(DialogResponse.CONFIRM);
  }

  cancel() {
    this.dialogRef.close(DialogResponse.CANCEL);
  }
}
