import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { LoaderComponent } from './components/loader/loader.component';
import { NgChartsModule } from 'ng2-charts';
import { HealthAssessmentGraphComponent } from './components/health-assessment-graph/health-assessment-graph.component';
import { NonHAGraphComponent } from './components/non-ha-graph/non-ha-graph.component'
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ActivityTypesComponent } from './components/activity-types/activity-types.component';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GeneralFormComponent } from './components/create-patient-forms/general-form/general-form.component';
import { ContactFormComponent } from './components/create-patient-forms/contact-form/contact-form.component';
import { CareFormComponent } from './components/create-patient-forms/care-form/care-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormField } from './components/create-patient-forms/card.form.field.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormlyModule } from '@ngx-formly/core';
import { FormModalComponent } from './components/formly/form-modal/form-modal.component';
import { FormDisplayComponent } from './components/formly/form-display/form-display.component';
import { LineGraphComponent } from './components/graphs/line-graph/line-graph.component';
import { BarGraphComponent } from './components/graphs/bar-graph/bar-graph.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoaderComponent,
    HealthAssessmentGraphComponent,
    NonHAGraphComponent,
    ActivityTypesComponent,
    ConfirmationDialogComponent,
    GeneralFormComponent,
    ContactFormComponent,
    CareFormComponent,
    FormModalComponent,
    FormDisplayComponent,
    FormField,
    // ProfilePageContainer,
    // DialogContainer,
    // LabelComponent,
    // ProfileFormModal,
    // DhInput,
    // DhSelect,
    LineGraphComponent,
    BarGraphComponent
  ],
  providers: [
    MatDialog
  ],
  imports: [
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
        // types: [
        //    { name: 'profile-page-container', component: ProfilePageContainer },
        //    { name: 'dialog-container', component: DialogContainer },
        //    { name: 'label', component: LabelComponent},
        //    { name: 'dh-input', component: DhInput},
        //    { name: 'dh-select', component: DhSelect}
        // ]
    }),
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatDialogModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMenuModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    NgChartsModule,
    MatTooltipModule,
    MatExpansionModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PipesModule
  ],
  exports: [
    LoaderComponent,
    HealthAssessmentGraphComponent,
    NonHAGraphComponent,
    ActivityTypesComponent,
    TranslateModule,
    MatDialogModule,
    MatStepperModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    GeneralFormComponent,
    ContactFormComponent,
    CareFormComponent,
    FormField,
    LineGraphComponent,
    BarGraphComponent,
    FormModalComponent,
    FormDisplayComponent            
  ]
})
export class DhCommonModule { }
