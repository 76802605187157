<div class="add-activity-container">
    <h2 mat-dialog-title *ngIf="!data.edit">{{'care_pathway.add_activity.add_activity_label' | translate}}</h2>
    <h2 mat-dialog-title *ngIf="data.edit">{{'care_pathway.edit_activity.edit_activity_label' | translate}} {{data.selectedActivityData.name}}</h2>
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="activityForm">
          <mat-form-field appearance="legacy" *ngIf="!data.edit">
            <mat-label>{{'care_pathway.add_activity.type_of_activity'| translate}} </mat-label>
            <select matNativeControl required formControlName='activity'>
              <option>-- Select --</option>
              <option *ngFor="let activity of data.activityType.activities" value="{{activity.id}}">{{activity.title | translatable:lang}}</option>
            </select>
            <mat-error *ngIf="activityCtrl.hasError('required')"> {{'required_field' | translate}} </mat-error>
          </mat-form-field>

          <!-- <mat-form-field appearance="legacy">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker" required>
              <input required formControlName='startDate' (click)='picker.open()'  matStartDate placeholder="Start date">
              <input required formControlName='endDate' (click)='picker.open()' matEndDate placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field> -->

          <mat-form-field appearance="legacy">
            <mat-label>{{'care_pathway.edit_activity.date_text' | translate}}</mat-label>
            <input matInput [required]="true" formControlName='startDate'  (click)='picker.open()' [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [startAt]='calendarOpenDate' #picker></mat-datepicker>
            <mat-error *ngIf="startDateCtrl.hasError('required')"> {{'required_field' | translate}} </mat-error>
          </mat-form-field>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="dh-btn dh-btn-secondary">{{'cancel' | translate}}</button>
      <button (click)="activate()" class="dh-btn dh-btn-primary"
       mat-button >{{'save' | translate}}</button>
    </mat-dialog-actions>
  </div>
  