<div class="row feature-block patient-overview">
    <div class="card col-md-12 ">
        <div class="card-header">
            <div class="card-title">
                {{'patients' | translate}}
            </div>
            <div>
                <button class="dh-btn dh-btn-primary rounded-btn" (click)="openCreatePatientModal()">
                    <strong class='button-content-wrapper'>
                        <i aria-hidden="true" class="fa fa-plus pr-2"></i>
                        <span>{{'overview_page.new_text' | translate}}</span>
                    </strong>
                </button>
            </div>
        </div>
        <div class="card-content row">
            <div class="col-md-4">
                <div class="patientSelection">
                    <label id="patientSelectionLabel">{{'overview_page.select_patients' | translate}}</label>
                    <mat-radio-group aria-labelledby="patientSelectionLabel" value="">
                        <div class="radio-helper">
                            <mat-radio-button value="{{sourceTypes.BY_BOTH}}" [checked]="true"
                                (change)="selectPatientsAssigned($event)">{{'overview_page.all_patients' | translate}}
                            </mat-radio-button>
                        </div>
                        <mat-radio-button value="{{sourceTypes.BY_ASSIGNMENT}}"
                            (change)="selectPatientsAssigned($event)">
                            {{'overview_page.assigned_patients' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row ">
                    <div class="col-sm-8 input-icons">
                        <i class="fa fa-search icon"></i>
                        <input class="form-control mr-sm-2 dh-input-field" type="search"
                            placeholder="{{'overview_page.search_placeholder_text' | translate}}" aria-label="Search"
                            incremental="true" (keyup)="searchEvent.next($event.target.value)"
                            (search)="searchEvent.next($event.target.value)">
                    </div>
                    <div class="col-sm-4 pl-0 d-flex align-items-center">
                        <a class="button-link" (click)="toggleFilter()"><i class="fa fa-filter"></i>{{!hasFilterEnabled
                            ? ['overview_page.filters_text' | translate] : ['overview_page.clear_filters_text' |
                            translate] }}</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ml-auto">
                <mat-paginator (page)="onChangePageSize($event)" [length]="lengthSubject$.value"
                    [pageSize]="pageSizeSubject$.value" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>

            <div class="col-md-12 patient-list">
                <table mat-table matSort matSortDisableClear [matSortDirection]="initialDirection"
                    (matSortChange)="sortData($event)" [matSortActive]="initialSort" [dataSource]="dataSource"
                    class="mat-elevation-z8 patient-overview-table">
                    <ng-container matColumnDef="externalId">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'overview_page.patient_id' | translate |
                            uppercase}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="patient-id" (click)="goToPatient(element.patientId)">
                                <span class='external-id'>{{element.externalId }}</span>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'overview_page.last_name' | translate |
                            uppercase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="initials">
                        <th mat-header-cell *matHeaderCellDef> {{'overview_page.initials' |
                            translate | uppercase}}</th>
                        <td mat-cell *matCellDef="let element"> {{element.initials}} </td>
                    </ng-container>
                    <ng-container matColumnDef="birthDate">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'overview_page.date_of_birth' |
                            translate | uppercase}}</th>
                        <td mat-cell *matCellDef="let element"> {{element.birthDate | date: dateFormat }} </td>
                    </ng-container>
                    <ng-container matColumnDef="lastLogin">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'overview_page.last_login' | translate
                            | uppercase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.lastLoginDate}} </td>
                    </ng-container>
                    <ng-container matColumnDef="disease">
                        <th mat-header-cell *matHeaderCellDef class="custom-filter-wrapper"> {{'overview_page.disease' |
                            translate | uppercase }}
                            <mat-form-field appearance="outline" *ngIf="hasFilterEnabled" style="width:150px">
                                <mat-label>{{"overview_page.select_disease_text" | translate}}</mat-label>
                                <mat-select [formControl]="diseaseFilter" panelClass="custom-filter-dd"
                                    (selectionChange)="updateDiseaseFilter()">
                                    <mat-option [value]="">{{"overview_page.select_disease_text" | translate}}</mat-option>
                                    <mat-option *ngFor="let disease of diseases"
                                        [value]="disease.id">
                                        {{disease.name | translatable: lang}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div>{{ element.diseaseName | translatable:lang | replaceNullWithText}}</div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="treatmentPlan">
                        <th mat-header-cell *matHeaderCellDef class="custom-filter-wrapper">
                            {{'overview_page.treatment_plan' | translate |
                            uppercase}}
                            <mat-form-field appearance="outline" *ngIf="hasFilterEnabled">
                                <mat-label>{{"overview_page.select_treatmentPlan_text" | translate}}</mat-label>
                                <mat-select [formControl]="scenarioFilter" panelClass="custom-filter-dd"
                                    (selectionChange)="updateFilterData()" [disabled]="diseaseFilter.value === ''">
                                    <mat-option [value]="">{{"overview_page.select_treatmentPlan_text" | translate}}
                                    </mat-option>
                                    <mat-option value="EMPTY">{{"overview_page.empty_treatmentplan_text" | translate}}</mat-option>
                                    <mat-option *ngFor="let scenario of scenarios" [value]="scenario.id">
                                        {{scenario.name | translatable: lang}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="truncate" matTooltip="{{element.scenarioName | translatable: lang }}"
                                TruncatedTooltip>
                                {{element.scenarioName  | translatable: lang | replaceNullWithText}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="notifications">
                        <th mat-header-cell mat-sort-header [disabled]=true *matHeaderCellDef
                            class="custom-filter-wrapper"> {{'overview_page.notification' |
                            translate | uppercase}}
                            <mat-form-field appearance="outline" *ngIf="hasFilterEnabled" style="width:150px">
                                <mat-label>{{"overview_page.select_notification_text" | translate}}</mat-label>
                                <mat-select [formControl]="notificationFilter" panelClass="custom-filter-dd"
                                    (selectionChange)="updateFilter($event.value)">
                                    <mat-option [value]="">{{"overview_page.select_notification_text" | translate}}
                                    </mat-option>
                                    <mat-option *ngFor="let notificationType of notificationTypes | keyvalue"
                                        [value]="notificationType.value">
                                        {{ 'overview_page.'+ notificationType.value |lowercase | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="notification-container" *ngIf="element.notifications.length > 0">
                                <div [ngSwitch]="element.notifications[0].type" (click)="goToQuestionnaire(element)">
                                    <div *ngSwitchCase="notificationTypes.NEW_RESULTS" class="notification-block">
                                        <i class="fa fa-question-circle"></i>
                                        {{'overview_page.new_results' | translate}}
                                    </div>
                                    <div *ngSwitchCase="notificationTypes.WORSENED_RESULTS" class="notification-block">
                                        <i class="fa fa-exclamation-triangle"></i>
                                        {{'overview_page.worsened_results' | translate}}
                                    </div>
                                    <div *ngSwitchCase="notificationTypes.LOW_HEALTH_RESULTS"
                                        class="notification-block">
                                        <i class="fa fa-exclamation-triangle"></i>
                                        {{'overview_page.low_health_results' | translate}}
                                    </div>
                                    <div *ngSwitchCase="notificationTypes.NOT_WORSENED_RESULTS"
                                        class="notification-block">
                                        <i class="fa fa-exclamation-triangle"></i>
                                        {{'overview_page.not_worsened_results' | translate}}
                                    </div>
                                    <div *ngSwitchCase="notificationTypes.RECOMMENDATION_MADE"
                                        class="notification-block">
                                        {{'overview_page.recommendation_made' | translate}}
                                    </div>
                                </div>
                                <div [matMenuTriggerFor]="notifications" class="total-notifications"
                                    [matMenuTriggerData]="{element: element}" *ngIf="element.notifications.length > 1">
                                    <a class="button-link"
                                        (click)="setCurrentElement(element)">+{{element.notifications.length-1}}</a>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="more">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button [matMenuTriggerFor]="moreMenu"
                                [matMenuTriggerData]="{element: element}" (click)="setCurrentElement(element)"
                                class="more">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="no-results" *ngIf="lengthSubject$.value === 0">{{'overview_page.no_results_found' | translate}}</div>
            </div>
        </div>
    </div>
</div>

<mat-menu #moreMenu>
    <ng-template matMenuContent let-data="element">
        <button mat-menu-item *ngIf="data.notifications.length > 0"
            (click)="openSolveNotificationDialog()">{{"overview_page.mark_as_solved_text"
            | translate}}</button>
        <button mat-menu-item (click)="goToEditPatient()">{{"overview_page.edit_text"
            | translate}}</button>
    </ng-template>
</mat-menu>

<mat-menu #notifications class="notification-overlay">
    <ng-template matMenuContent let-data="element">
        <div *ngFor="let notification of data.notifications" mat-menu-item (click)="goToQuestionnaire()">
            <ng-container [ngSwitch]="notification.type">
                <div *ngSwitchCase="notificationTypes.NEW_RESULTS" class="notification-block">
                    <i class="fa fa-question-circle"></i>
                    {{'overview_page.new_results' | translate}}
                </div>
                <div *ngSwitchCase="notificationTypes.WORSENED_RESULTS" class="notification-block">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{'overview_page.worsened_results' | translate}}
                </div>
                <div *ngSwitchCase="notificationTypes.LOW_HEALTH_RESULTS" class="notification-block">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{'overview_page.low_health_results' | translate}}
                </div>
                <div *ngSwitchCase="notificationTypes.NOT_WORSENED_RESULTS" class="notification-block">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{'overview_page.not_worsened_results' | translate}}
                </div>
                <div *ngSwitchCase="notificationTypes.RECOMMENDATION_MADE" class="notification-block">
                    {{'overview_page.recommendation_made' | translate}}
                </div>
            </ng-container>
        </div>
    </ng-template>
</mat-menu>