<div class="patient-overview">
    <div class="patient-img not-mvp"></div>
    <div class="patient-information" *ngIf="patientData as patient else noData">
        <div class="patient-name">{{patient.firstName }} {{patient.lastName}}</div>
        <div class="patient-medical-information">
            <ul>
                <li>
                    <span class="primary-head">{{'patient.id' | translate}}</span>
                    <span>{{ patient.externalId }}</span>
                </li>
                <li>
                    <span class="primary-head">{{'patient.dob' | translate}}</span>
                    <span>{{ patient.dob | date: dateFormat}}</span>
                </li>
                <li>
                    <span class="primary-head">{{'patient.gender.title' | translate}}</span>
                    <span>{{ 'patient.gender.' + patient.gender | translate }}</span>
                </li>
                <li>
                    <span class="primary-head">{{'patient.disease' | translate}}</span>
                    <span>{{ diseaseName }} </span>
                </li>
            </ul>
        </div>
    </div>
    <ng-template class="patient-information" #noData>{{'patient.no_data_text' | translate}}</ng-template>
    <!-- TODO: DISABLED for version 1.0 -->
    <div class="sync-health-action" *ngIf="false">
        <button class="btn dh-transparant">Sync with EMR</button>
        <button class="btn dh-transparant">Health Summary</button>
    </div>
</div>
