<div class="month-calendar-container" [ngClass]="{'edit': editable}">
    <div class="year text-center">
        <b class="flex-row">
            <mat-icon (click)="yearBack()" class="orange-text cursor-pointer"> keyboard_arrow_left</mat-icon>
            <span class="year-text">{{currentYear}}</span>
            <mat-icon (click)="yearForward()" class="orange-text cursor-pointer"> keyboard_arrow_right</mat-icon>
        </b>
    </div>
    <div class="indicator-month">
        <div *ngIf="currentYear == dateNow.year()">
        </div>
    </div>
    <div class="calendar-container flex-column">
        <div class="months flex-row space-between">
            <ng-container *ngIf='!editable'>
                <div *ngFor="let month of months; let i = index" class="month-class flex-column">
                    <div *ngIf="setDropdownbyStartDate(month)" class="mt-minus-28">
                        <mat-icon class="orange-text">arrow_drop_down</mat-icon>
                    </div>
                    <div>{{'month_names.' + month | translate}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf='editable'>
                <div *ngFor="let month of months; let i = index" class="month-class flex-column" 
                (mouseover)="showAddAction[i] = true"
                (mouseout)="showAddAction[i] = false"
                    [matMenuTriggerFor]="contextMenu" (click)='showActivityTypes($event, month)'>
                    <div *ngIf="(currentYear==dateNow.year()) && (i == dateNow.month())" class="mt-minus-28">
                        <mat-icon class="orange-text">arrow_drop_down</mat-icon>
                    </div>
                   <div>
                        <span *ngIf="!showAddAction[i]">{{'month_names.' + month | translate}}</span>
                        <span *ngIf="showAddAction[i]" class="plus">+</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="events-container flex-row space-between padding-side-10">
        <div *ngFor="let month of months" class="event-class margin-left-5" [ngClass]="{'current-month': setCurrentMonthClass(month) }" >
            <div *ngIf="getActivitiesForMonth(month).length == 0" class="width-24"></div>
            <div (click)="showInfo(activity)" *ngFor="let activity of getActivitiesForMonth(month);" class="flex-column"
                [matTooltip]="displayTooltipName(activity)" matTooltipPosition="below">
                <div class="vl margin-left-12"></div>
                <div class="image-container" [ngClass]="{'external-orders': activity.source?.startsWith('external'), 'prev-activities': activity.datePassed}" > 
                    <img class="cursor-pointer" src='{{defaultIconPath}}{{typesMap.get(activity?.type)}}'> 
                    <i *ngIf='editable' (click)="openDetelePopup($event, activity)" class="fa fa-trash"></i>
                    <i *ngIf='activity.statusNew === "DONE"' class="fa fa-check status completed"></i>
                    <i *ngIf='activity.statusNew === "PLANNED"' class="fa fa-calendar-alt status scheduled"></i>
                    <i *ngIf='activity.statusNew === "OVERDUE"' class="fa fa-times status overdue"></i>
                    <i *ngIf='activity.statusNew === "PENDING" || !activity.statusNew' class="fa fa-clock status pending"></i>
                </div>
            </div>
        </div>
    </div>

    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item *ngFor='let activityType of activityTypes' (click)="triggerEmit(activityType)">
                <img class="activity-icon" src='{{defaultIconPath}}{{typesMap.get(activityType?.type)}}'>
                <span>{{activityType?.name | translatable:lang}}</span>
            </button>
        </ng-template>
    </mat-menu>
</div>
