<div class="container auth-container text-center">
  <app-loader></app-loader>
  <div class="">
    <div>
      <img src="../../../assets/images/logo/dear_logo.png" alt="DEARHealth">
    </div>
    <div class="confirmation-text" *ngIf="navigationData?.userName" [innerHTML]="'passwordLess.email_code_confirmation_text' | translate : {USER_NAME : navigationData?.userName}"></div>  
    <form [formGroup]="form">
      <div class="text-left">
        <mat-label>{{'passwordLess.email_code' | translate}}</mat-label>
      </div>
      <div class="field">
        <mat-form-field appearance="outline">
          <input type="text" autocomplete="disabled" #emailCode autofocus matInput formControlName="emailCode" placeholder="{{'passwordLess.enter_your_email_code' | translate}}" />
          <mat-error *ngIf="form.get('emailCode')?.hasError('pattern')">{{'passwordLess.Invalid_mail_code' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="text-center mt-10">
        <button mat-raised-button
          class="mat-focus-indicator btn btn-primary dh-login shadow-none mat-flat-button mat-button-base"
          (click)="verifyCode()">{{'passwordLess.verify' | translate}}</button>
      </div>
    </form>
  </div>
</div>