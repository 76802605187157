<div class="row feature-block">
    <div class="card col-md-12 ">

        <div class="card-content row " *ngIf="hasHealthAssessment; else noHealthAssessment">
            <div class="col-md-6 row" style="padding-left: 0;">
                <div class="col-md-6" style="padding-left: 0;">
                    <div class="card-header">
                        <div class="card-title">
                            {{'health_assessment_title' | translate}}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card-header questionnaire-details">
                        <i class="fa fa-search details-icon" aria-hidden="true" (click)="openModal(cpActivityId,questionnaireId)"></i>
                    </div>
                </div>
                <div class="col-md-4">

                    <div class="chart-wrapper  percentage">
                        <div class="heart-symbol"
                            style=" background: url('assets/images/icons/heart.svg') no-repeat center;background-size: 60%;">
                            <canvas baseChart height="160px" width="160px" [datasets]="pieChartData"
                                [labels]="pieChartLabels" [type]="pieChartType" [options]="pieChartOptions"
                                [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                            </canvas>
                        </div>
                        <div class="total-score">
                            {{ totalScore | number:'1.0-0'}}%
                        </div>
                    </div>
                </div>
                <div class="col-md-8 patient-progress" style="min-height: 200px;">
                    <div class="health-progress" *ngFor="let item of mappedResponse; trackBy: trackByFn">
                        <div [ngStyle]="{'color': item.color}">
                            {{item.name | translatable:lang}}
                            <span class="text-md-right" [ngStyle]="{'color': item.color}">{{item.score}} %</span>
                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" [ngStyle]="{'background-color': item.color}"
                                style.width="{{item.score}}%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="chart-container">
                    <div class="ha-chart"></div>
                </div>
            </div>
        </div>
        <ng-template #noHealthAssessment>
            <div class="card-content row">
                <div class="col" [innerHTML]="'health_assessment.no_health_assessment' | translate"></div>
            </div>
        </ng-template>
    </div>
</div>