import { Component, Inject, OnInit, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { LangType, StateService } from '../../../../core/services/state.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientService } from '../../../../core/services/patient.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';
import { APP_CONSTANTS } from 'src/app/core/constants/app.constants';
import { MessageService } from 'src/app/core/services/message.service';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { ActivateMedicationModal } from './activate-medication-warn-dlg/activate-medication-modal.component';
import { ActivateScenarioResponse, Activity, DeactivateScenarioResponse, AddonData } from 'src/app/core/model/patient.interface';
import { ActiveScenario } from 'src/app/core/model/common.interface'
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { Apollo } from 'apollo-angular';
import { ACTIVATE_ADDON, DEACTIVATE_ADDON, GET_ADDON } from 'src/app/core/constants/graphql.query.constants';
import { distinct } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DhMatDateFormat } from 'src/app/core/adapters/date-format-factory';

interface AddonNode {
  id: string;
  type: any;
  name: any;
  active?: ActiveState;
  startDate?: string;
  startInterval?: number;
  intervals?: Array<any>;
  intervalType?: string;

}

interface AddonsGroupNode {
  type: string;
  addons?: AddonNode[];
}

export interface AddonDialogResult {
  activatedAddons: Array<string>;
  deactivatedAddons: Array<string>;
}

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss'], 
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ]
})
export class AddonsComponent implements OnInit {

  scenario: any;
  scenarioId: string;
  scenarioStartDate: Date;
  diseaseId: string;
  availableAddonGroups: AddonsGroupNode[] = [];
  activatedAddonGroups: AddonsGroupNode[] = [];
  selectedAddOns = new SelectionModel<Addon>(true);
  addonStartDateIntervals = [];
  addonIdsToAdd: string[] = [];
  addonIdsToRemove: string[] = [];
  lang: LangType;

  availableTreeControl = new NestedTreeControl<AddonsGroupNode>(node => node.addons);
  activatedTreeControl = new NestedTreeControl<AddonsGroupNode>(node => node.addons);

  availableDataSource = new MatTreeNestedDataSource<AddonsGroupNode>();
  activatedDataSource = new MatTreeNestedDataSource<AddonsGroupNode>();

  private isMedicineAddonActivating = false;

  constructor(private patientStateService: PatientStateService,  private dateAdapter: DateAdapter<any>,
    @Inject(MAT_DATE_FORMATS) public config: DhMatDateFormat,
    private stateService: StateService,
    public dialogRef: MatDialogRef<AddonsComponent>, private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: { scenario: ActiveScenario },
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog, private apollo: Apollo,
    private viewRef: ViewContainerRef) {
    this.scenario = data.scenario;
  }

  ngOnInit(): void {
    const availableAddons: Addon[] = [];
    const activatedAddons: Addon[] = [];

    this.stateService.lang.pipe(distinct()).subscribe((lang) => {
      this.lang = lang;
      this.dateAdapter.setLocale(lang);
      this.scenarioId = this.scenario.id;
      const startDate = this.scenario.startDate ? new Date(this.scenario.startDate) : new Date();
      this.scenarioStartDate = startDate;
      const addons = this.scenario.addons;
      for (const key of Object.keys(addons)) {
        if (addons[key].active != null) {
          const addon = new Addon(addons[key].id, addons[key].addonType, addons[key].name,
            new ActiveState(addons[key].active.startDate, addons[key].active.startOffset, addons[key].active.offsetType));
          activatedAddons.push(addon);
          availableAddons.push(addon);
        } else {
          const addon = new Addon(addons[key].id, addons[key].addonType, addons[key].name, null);
          availableAddons.push(addon);
        }
      }
      const availableGroups = {};
      availableAddons.forEach(a => {
        availableGroups[a.type.en] = availableGroups[a.type.en] ? availableGroups[a.type.en].concat([a]) : [a];
        if (a.active != null) {
          this.selectedAddOns.toggle(a);
        }
      });

      for (const group of Object.keys(availableGroups)) {
        this.availableAddonGroups.push({ type: availableGroups[group][0].type, addons: availableGroups[group] });
      }

      activatedAddons.forEach((node) => {
        let addonNode: AddonNode = node;
        this.activatedDataSource.data.push({
          type: node.type,
          addons: [addonNode]
        });
      });

      this.availableDataSource.data = this.availableAddonGroups;
      this.changeDetectorRef.markForCheck();
    });
    this.config.dateFormat = this.stateService.getMedicalContent.formatting.shortDateFormat.replace('dd', 'DD');
  }

  hasAddons = (_: number, node: AddonsGroupNode) => !!node.addons && node.addons.length > 0;

  addonToggle(node, isRemoved: boolean): void {
    if (this.selectedAddOns.selected.length > 0) {
      if (this.selectedAddOns.selected.some(element => element.id === node.id)) {
        isRemoved = true;
      }
    }
    this.checkMedicineAddonActivation(node, !isRemoved);
    this.selectedAddOns.toggle(node);
    this.formActivatedDataSource(node, isRemoved);
  }

  checkMedicineAddonActivation(node, toActivate: boolean): void {
    if (node.type.en.includes('Medicine') || node.type.en.includes('Medication')) {
      this.isMedicineAddonActivating = toActivate;
    }
  }

  formActivatedDataSource(node: AddonNode, isRemoved: boolean): void {
    const previouslyActivatedAddons = this.scenario.addons.filter((e) => e.active != null);
    const previouslyNonActivatedAddons = this.scenario.addons.filter((e) => e.active == null);
    if (!isRemoved) {
      this.addonIdsToAdd.push(node.id);
      node.active = { startOffset: 0, startDate: this.scenarioStartDate, offsetType:"week" };
      this.activatedDataSource.data.push({
        type: node.type,
        addons: [node]
      });
    } else { // if isRemoved
      this.activatedDataSource.data.forEach((element, index) => {
        if (element.addons[0].id === node.id) {
          this.activatedDataSource.data.splice(index, 1);
          this.addonIdsToAdd = this.addonIdsToAdd.filter(id => id!==node.id)
        }
      });
      if (previouslyActivatedAddons.length > 0) {
        if (previouslyActivatedAddons.some(element => element.id === node.id)) {
          this.addonIdsToRemove.push(node.id);
        }
      }

    }
    this.updateView();
  }

  updateView(): void {
    // To update the view the below 3 lines are needed
    const tempData = this.activatedDataSource.data;
    this.activatedDataSource.data = null;
    this.activatedDataSource.data = tempData;
  }

  removeAddon(addon: AddonNode): void {
    this.addonToggle(addon, true);
    this.updateView();
  }

  saveAddon(): void {
    if (this.isMedicineAddonActivating) {
      const dialogRef = this.dialog.open(ActivateMedicationModal, {
        viewContainerRef: this.viewRef,
        disableClose: true,
        maxWidth: '500px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.saveAddons();
      });
    } else {
      this.saveAddons();
    }
  }

  private alreadyActive(childNode: AddonNode): any {
    const previouslyActivatedAddons = this.scenario.addons.filter((e) => e.active != null);
    return previouslyActivatedAddons.filter((node: AddonNode) => childNode.id === node.id).length > 0;
  }

  private calculateOffset(offset: number=0, type: string="week") {
    return type=="week" ? offset * 7: offset * 30
  }

  private saveAddons(): void {
    let addonsToActivate: Array<Record<string, string>> = [];
    let activateAddonObs = null;
    let deactivateAddonObs = null;
    if (this.activatedDataSource.data.length > 0) {

      this.activatedDataSource.data.forEach((data) => {
        addonsToActivate.push(
          {
            id: data.addons[0].id,
            startDate: data.addons[0].active.startDate.toISOString(),
            startOffset: this.calculateOffset( data.addons[0].active.startOffset, data.addons[0].active.offsetType).toString()
          }
        )
      });

      activateAddonObs = this.apollo.mutate({
        mutation: ACTIVATE_ADDON,
        variables: {
          diseaseId: this.patientStateService.diseaseId,
          scenarioId: this.scenarioId,
          addonsToActivate
        }
      });
    }
    if (this.addonIdsToRemove.length > 0) {
      deactivateAddonObs = this.apollo.mutate({
        mutation: DEACTIVATE_ADDON,
        variables: {
          diseaseId: this.patientStateService.diseaseId,
          scenarioId: this.scenarioId,
          addonIdsToDeactivate: this.addonIdsToRemove
        }
      })

    }

    if (activateAddonObs && deactivateAddonObs) {
      activateAddonObs.subscribe((activateResponse: ActivateScenarioResponse) => {
        deactivateAddonObs.subscribe((deactivateResponse: ActivateScenarioResponse) => {
          // activate
          const addedActivities: Activity[] = (activateResponse['data'].activateAddons.addedActivities ?
            activateResponse['data'].activateAddons.addedActivities.map((activity) => ({ ...activity, status: null })) : []);
          const canceledActivites = new Set<string>();
          // IE11 does not support new Set(array)
          activateResponse['data'].activateAddons.addedActivities.canceledActivities?.forEach(activity => {
            canceledActivites.add(activity.activityId);
          });
          deactivateResponse['data'].deactivateAddons.canceledActivities?.forEach(activity => {
            canceledActivites.add(activity.activityId);
          });
          this.patientStateService.setCarePathwayActivites = [].concat(this.patientStateService.carePathwayActivities.filter(
            (activity) => (!canceledActivites.has(activity.activityId))), addedActivities);
          this.dialogRef.close({
            activatedAddons: this.addonIdsToAdd,
            deactivatedAddons: this.addonIdsToRemove
          });
        }, error => {
          this.messageService.showGenericError();
        })
      }, error => {
        this.messageService.showGenericError();
      });
    } else if (activateAddonObs) {
      activateAddonObs.subscribe((response: ActivateScenarioResponse) => {
        const addedActivities: Activity[] = (response['data'].activateAddons.addedActivities ?
          response['data'].activateAddons.addedActivities.map((activity) => ({ ...activity, status: null })) : []);
        const canceledActivites = new Set<string>();
        // IE11 does not support new Set(array)
        response['data'].activateAddons.canceledActivities?.forEach(activity => {
          canceledActivites.add(activity.activityId);
        });
        this.patientStateService.setCarePathwayActivites = [].concat(this.patientStateService.carePathwayActivities.filter(
          (activity) => (!canceledActivites.has(activity.activityId))), addedActivities);

        this.dialogRef.close({
          activatedAddons: this.buildAddons(),
          deactivatedAddons: []
        });
      }, error => {
        this.messageService.showGenericError();
      });
    } else if (deactivateAddonObs) {
      deactivateAddonObs.subscribe((response: DeactivateScenarioResponse) => {
        const canceledActivites = new Set<string>();
        // IE11 does not support new Set(array)
        response['data'].deactivateAddons.canceledActivities?.forEach(activity => {
          canceledActivites.add(activity.activityId);
        });
        this.patientStateService.setCarePathwayActivites = this.patientStateService.carePathwayActivities.filter(
          (activity) => (!canceledActivites.has(activity.activityId)));
        this.dialogRef.close({
          activatedAddons: [],
          deactivatedAddons: this.addonIdsToRemove
        });
      }, error => {
        this.messageService.showGenericError();
      });
    }
  }

  buildAddons() {
    const result = {};
    this.activatedDataSource.data.filter(activatedAddon => this.addonIdsToAdd.includes(activatedAddon.addons[0].id))
      .forEach(filteredAddon => result[filteredAddon.addons[0].id] = {
        startDate: filteredAddon.addons[0].active.startDate,
        startOffset: filteredAddon.addons[0].active.startOffset,
        offsetType: filteredAddon.addons[0].active.offsetType
      });
    return result;
  }

}

class ActiveState {
  startDate: Date;
  startOffset: number;
  offsetType: string

  constructor(startDate: Date, startInterval: number, offsetType: string) {
    this.startDate = startDate;
    this.startOffset = startInterval;
    this.offsetType = offsetType
  }
}

class Addon {
  id: string;
  type: any;
  name: any;
  active: ActiveState;


  constructor(id: string, type: any, name: any, active: ActiveState) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.active = active;
  }
}
