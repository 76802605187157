<!-- TODO: DISABLED for version 1.0 -->
<nav class="mainmenu navbar navbar-expand-lg" *ngIf="false">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav col-md-12">
            <li class="nav-item">
                <a class="nav-link" href="#">Summary</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Profile</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Medications</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">To Do List</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Wellness and Education</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Consent</a>
            </li>
        </ul>
    </div>
</nav>
