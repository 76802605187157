<div class="from-display-wrapper">
    <div class="card-form" [ngClass]="{'disabled-card': displayMode}">
        <div class="header">
            <div class="card-title">
                {{ form.formName }}
            </div>
            <div *ngIf="!displayMode">
                <a class="button-link" (click)="openFormModal()">edit </a>
            </div>
        </div>
        <div class="content" >
            <div *ngFor="let field of flattenFormFields" class="field-display">
                <div class="field-label"> {{ field.templateOptions.label }} </div>
                <div class="field-value" [ngClass]="{'disabled-field':field?.expressions && field?.expressions['props.disabled'] == 'true'}">  {{ displayFieldValue(field.key, formData[field.key]) }} </div>
            </div>
        </div>
    </div>
</div>