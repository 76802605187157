import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { APP_CONSTANTS, GENDER } from 'src/app/core/constants/app.constants';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { MedicalContent } from 'src/app/core/model/common.interface';

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss']
})
export class GeneralFormComponent implements OnInit, OnChanges {

  @Input() formGroup: FormGroup;
  @Input() isEditable: boolean;
  calendarStartDate: Date;
  calendarEndDate: Date;
  lang: LangType;
  genderList = GENDER;
  translatedGender: string;
  dateFormat: string;


  constructor(private stateService: StateService, private translate: TranslateService) { }

  ngOnInit(): void {

    this.calendarStartDate = moment(new Date()).subtract(120, 'y').toDate();
    this.calendarEndDate = moment(new Date()).add(1, 'd').toDate();
    this.stateService.lang.subscribe(res => {
      this.lang = res;
      this.translate.use(this.lang);
      this.translateGender();
    });
    this.stateService.medicalContentObs.subscribe((data: MedicalContent) => {
      this.dateFormat = data?.formatting.shortDateFormat;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.translateGender()
  }

  public translateGender() {
    this.genderList.forEach((gender) => {
      if (gender.value.toLowerCase() === this.formGroup.value.gender.toLowerCase()) {
        this.translatedGender = gender.name[this.lang];
      }
    })
  }

  public getDob() {
    const date = this.dateCtrl.value < 10 ? `0${this.dateCtrl.value}` : this.dateCtrl.value
    const month = this.monthCtrl.value < 10 ? `0${this.monthCtrl.value}` : this.monthCtrl.value;
    const year = this.yearCtrl.value;
    
    if (date && month && year) {
      return moment(`${month}-${date}-${year}`).format(APP_CONSTANTS.YYYY_MM_DD_FORMAT);
    }
    return "-";
  }

  public get dateCtrl() {
    return this.formGroup.get('date');
  }

  public get monthCtrl() {
    return this.formGroup.get('month');
  }

  public get yearCtrl() {
    return this.formGroup.get('year');
  }

  public get firstNameCtrl() {
    return this.formGroup.get('firstName');
  }

  public get lastNameCtrl() {
    return this.formGroup.get('lastName');
  }

  public get initialsCtrl() {
    return this.formGroup.get('initials');
  }

  public get genderCtrl() {
    return this.formGroup.get('gender');
  }

  public changeInitial() {
    let data = "";
    let initial: string = this.initialsCtrl.value;
    initial = initial.toUpperCase();
    [...initial].forEach((curr, index) => {
      if (index + 1 < [...initial].length) {
        data += curr;
        if ([...initial][index + 1] !== '.' && curr !== '.') {
          data += ".";
        }
      } else {
        data += curr;
      }
    });
    this.initialsCtrl.setValue(data);
  }

  changeDate() {
    const number = parseInt(this.dateCtrl.value);
    number < 10 ? this.dateCtrl.setValue("0" + number) : '';
    this.checkDOB();
  }

  changeMonth() {
    const number = parseInt(this.monthCtrl.value);
    number < 10 ? this.monthCtrl.setValue("0" + number) : '';
    this.checkDOB();
  }

  public checkDOB() {
    const date = parseInt(this.dateCtrl.value);
    const month = parseInt(this.monthCtrl.value);
    const year = parseInt(this.yearCtrl.value);
    if (date <= 28 && month <= 12 && year <= new Date().getFullYear()) {
      return null;
    } else if ((month == 4 || month == 6 || month == 9 || month == 11) && date > 30) {
      this.dateCtrl.setErrors({ invalidDate: true });
    } else if ((month == 2 && date > 28 && year % 4 !== 0) || (month == 2 && year % 4 === 0 && date >= 30)) {
      this.dateCtrl.setErrors({ invalidDate: true });
    }
  }
}
