import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { PatientListComponent } from '../components/patient-list/patient-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule} from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { DhCommonModule } from 'src/app/dh-common/dh-common.module';
import { HealthAssessmentComponent } from '../components/health-assessment/health-assessment.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18nService } from './mat-paginator-i18n.service';
import { CreatePatientComponent } from '../components/create-patient/create-patient.component';
import { SolveNotificationComponent } from '../components/patient-list/solve-notification/solve-notification.component';
import { TruncatedTooltipDirective } from '../components/patient-list/truncate-tooltip-directive';
import { ReplaceNullWithTextPipe } from '../components/patient-list/replace-null.pipe';

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    OverviewComponent,
    HealthAssessmentComponent,
    PatientListComponent,
    CreatePatientComponent,
    SolveNotificationComponent,
    TruncatedTooltipDirective, 
    ReplaceNullWithTextPipe
  ],
  imports: [
    SharedModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PipesModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatIconModule,
    DhCommonModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    }
  ]
})
export class OverviewModule { }
