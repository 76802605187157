import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-sso-session-logout',
  templateUrl: './sso-session-logout.component.html',
  styleUrls: ['./sso-session-logout.component.scss']
})
export class SsoSessionLogoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
