import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview_module/overview.component';
import { AfterLaunchComponent } from './shared/component/after-launch/after-launch.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { SsoGuard } from './core/guards/sso.guard';
import { SsoSessionLogoutComponent } from './shared/component/sso-session-logout/sso-session-logout.component';
import { PasswordlessInitiationComponent } from './auth/passwordless-initiation/passwordless-initiation.component';
import { SMSVerificationComponent } from './auth/sms-verification/sms-verification.component';
import { EmailVerificationComponent } from './auth/email-verification/email-verification.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'email'},
    {
        path: 'email',
        pathMatch: 'full',
        component: PasswordlessInitiationComponent,
    },
   
    {
        path: 'sms-verify',
        component: SMSVerificationComponent
    },
    {
        path: 'email-verify',
        component: EmailVerificationComponent
    },
    {
        path: 'sessionlogout',
        component: SsoSessionLogoutComponent
    },
    {
        path: 'afterlaunch',
        component: AfterLaunchComponent
    },
    {
        path: 'patient',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./patient/patient_module/patient.module').then(m => m.PatientModule),
    },
    {
        path: 'overview',
        component: OverviewComponent,
        canActivate: [AuthGuardService, SsoGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
