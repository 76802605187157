import { forwardRef, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { PatientRoutingModule } from './patient-routing.module';
import { PatientComponent } from './patient.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { QuestionnaireComponent } from '../components/questionnaire/questionnaire.component';
import { ChatComponent } from '../components/chat/chat.component'

import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { TreatmentPlanComponent } from '../components/treatment/treatment-plan/treatment-plan.component';
import { CarePathwayComponent } from '../components/care-pathway/care-pathway.component';
import { EditCarePathwayComponent } from '../components/care-pathway/edit-care-pathway/edit-care-pathway.component';
import { MultiScenarioComponent } from '../components/treatment/multi-scenario/multi-scenario.component';
import { FormsModule, Validators } from '@angular/forms';
import { IntervalCalendarComponent } from '../components/calendar/interval-calendar/interval-calendar.component';
import { MonthlyCalendarComponent } from '../components/calendar/monthly-calendar/monthly-calendar.component';
import { DiseasesPhysiciansComponent } from '../components/treatment/diseases-physicians/diseases-physicians.component';
import { HistoryDetailsComponent } from '../components/treatment/history-details/history-details.component';

import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DhCommonModule } from '../../dh-common/dh-common.module';
import { PropertyComponent } from '../components/property/property.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HealthAssessmentComponent } from '../components/health-assessment/health-assessment.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { ToolTipRendererDirective } from '../components/questionnaire/recommendation-tooltip-render.directive';
import { RecommendationTooltipComponent } from '../components/questionnaire/recommendation-tooltip/recommendation-tooltip.component';
import { EventDialogComponent } from '../components/event-dialog/event-dialog.component';
import { UpdateActivityComponent } from '../components/care-pathway/edit-care-pathway/update-activity/update-activity.component';
import { DeleteActivityComponent } from '../components/care-pathway/edit-care-pathway/delete-activity/delete-activity.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AddonsComponent } from '../components/treatment/addons/addons.component';
import { MatTreeModule } from '@angular/material/tree';
import { QuestionnaireResponseComponent } from '../components/questionnaire-response/questionnaire-response.component';
import { QuestionnaireResponseListComponent } from '../components/questionnaire-response/components/questionnaire-response-list/questionnaire-response-list.component';
import { QuestionnaireResponseModalComponent } from '../components/questionnaire-response/components/questionnaire-response-modal/questionnaire-response-modal.component';
import { MatTableModule } from '@angular/material/table';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from 'src/app/core/adapters/custom-date-adapter';
import { ProfileComponent } from '../components/profile/profile.component';
import { ScenarioAlreadyActivatedModal } from '../components/questionnaire/scenario-already-activated-modal/scenario-already-activated-modal.component';
import { ScenarioNotFoundModal } from '../components/questionnaire/scenario-not-found-modal/scenario-not-found-modal.component';
import { RejectRecommendedScenarioModal } from '../components/questionnaire/reject-recommended-scenario-modal/reject-recommended-scenario-modal.component';
import { CarePathwayRemindedModal } from '../components/questionnaire/care-pathway-reminded-modal/care-pathway-reminded-modal.component';
import { ActivateMedicationModal } from '../components/treatment/addons/activate-medication-warn-dlg/activate-medication-modal.component';
import { PatientMenuComponent } from '../components/patient-menu/patient-menu.component';
import { DeletePatientComponent } from '../components/delete-patient/delete-patient.component';
import { MultiTreatmentPlanComponent } from '../components/treatment/multi-treatment-plan/multi-treatment-plan.component';
import { ConfirmSwitchDeactivationModal } from '../components/treatment/confirmation-modals/switch-deactivate-confirm-modal/switch-deactivate-confirm-modal.component';
import { ConfirmDeactivationModal } from '../components/treatment/confirmation-modals/deactivation-confirm-modal/deactivate-confirm-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyMaterialModule } from '@ngx-formly/material';
// import { ProfilePageContainer } from 'src/app/dh-common/components/formly/profile-page-container/profile-page-container.component';
// import { DialogContainer } from 'src/app/dh-common/components/formly/dialog-container/dialog-container.component';
// import { LabelComponent } from 'src/app/dh-common/components/formly/label/label.component';
// import { DhInput } from 'src/app/dh-common/components/formly/input/dh-input.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DhMatDateFormat } from 'src/app/core/adapters/date-format-factory';
import { DiseaseSpecificGraphComponent } from '../components/disease-specific-graph/disease-specific-graph.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
// import { DhSelect } from 'src/app/dh-common/components/formly/input/dh-select.component';



export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        PatientComponent,
        NavbarComponent,
        PropertyComponent,
        PatientMenuComponent,
        DeletePatientComponent,
        QuestionnaireComponent,
        TreatmentPlanComponent,
        MultiTreatmentPlanComponent,
        CarePathwayComponent,
        EditCarePathwayComponent,
        ChatComponent,
        MultiScenarioComponent,
        AddonsComponent,
        IntervalCalendarComponent,
        MonthlyCalendarComponent,
        DiseasesPhysiciansComponent,
        HistoryDetailsComponent,
        HealthAssessmentComponent,
        RecommendationTooltipComponent,
        ToolTipRendererDirective,
        EventDialogComponent,
        UpdateActivityComponent,
        DeleteActivityComponent,
        QuestionnaireResponseComponent,
        QuestionnaireResponseListComponent,
        QuestionnaireResponseModalComponent,
        ProfileComponent,
        ConfirmSwitchDeactivationModal,
        ConfirmDeactivationModal,
        ScenarioAlreadyActivatedModal,
        ScenarioNotFoundModal,
        RejectRecommendedScenarioModal,
        CarePathwayRemindedModal,
        ActivateMedicationModal,
        DiseaseSpecificGraphComponent
    ],
    providers: [MatDialog, DatePipe, { provide: MAT_DATE_FORMATS, useClass: DhMatDateFormat }, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] }],
    imports: [
        FormlyMaterialModule,
        FormlyMatDatepickerModule,
        MatDatepickerModule,
        //    FormlyModule.forRoot({
        //     // types: [
        //     //    { name: 'profile-page-container', component: ProfilePageContainer },
        //     //    { name: 'dialog-container', component: DialogContainer },
        //     //    { name: 'label', component: LabelComponent},
        //     //    { name: 'dh-input', component: DhInput},
        //     //     { name: 'dh-select', component: DhSelect}
        //     // ]
        //     }),
        FormlyModule.forRoot({
            validationMessages: [
                { name: 'required', message: 'This field is required' },
            ],
            validators: [
                { name: 'required', validation: Validators.required },
                // Add other validators as needed
            ],
        }),
        ReactiveFormsModule, 
        CommonModule,
        DhCommonModule,
        PatientRoutingModule,
        MatDialogModule,
        MatInputModule,
        MatSlideToggleModule,
        MatIconModule,
        MatFormFieldModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatRadioModule,
        SharedModule,
        FormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PipesModule,
        MatGridListModule,
        MatCardModule,
        MatButtonModule,
        FlexModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatTreeModule,
        MatTableModule,
        MatIconModule,
        MatRadioModule,
        MatSlideToggleModule
    ],

    exports: [
        IntervalCalendarComponent
    ]
})
export class PatientModule {
}

