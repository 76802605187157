<div>
    <button mat-icon-button [matMenuTriggerFor]="moreMenu"
        class="more">
        <mat-icon>more_horiz</mat-icon>
    </button>
</div>

<mat-menu #moreMenu xPosition="before">
    <ng-template matMenuContent let-data="element">
        <div class="more-menu">
        <!-- <button *ngIf="isPatientOnboarded" mat-menu-item (click)="openResetPasswordModal()">{{"overview_page.reset_password_text" |
            translate}}</button> -->
        <button mat-menu-item class="delete-patient" (click)="openDeleteDialog()">{{"overview_page.delete_text" | translate}}</button>
    </div>
    </ng-template>
</mat-menu>