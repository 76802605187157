import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { NOTIFICATION_TYPES } from 'src/app/core/constants/app.constants';
import { READ_NOTIFICATION } from 'src/app/core/constants/graphql.query.constants';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-solve-notification',
  templateUrl: './solve-notification.component.html',
  styleUrls: ['./solve-notification.component.scss']
})
export class SolveNotificationComponent implements OnInit {
  public checked = false;
  notificationIds: string[] = [];
  notificationTypes = NOTIFICATION_TYPES;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<SolveNotificationComponent>,
    private apollo: Apollo, private messageService: MessageService, private translate: TranslateService) { }

  ngOnInit(): void {
    
  }

  solveNotification() {
    const readNotificaionObs = this.apollo.mutate({
      mutation: READ_NOTIFICATION,
      variables: {
        input: {
          diseaseId: this.data.diseaseId,
          patientId: this.data.patientId,
          notificationIds:this.notificationIds
        }
      }
    });
    readNotificaionObs
    .subscribe((data) => {
      this.dialogRef.close(this.notificationIds);
    }, (err) => {
      this.messageService.showGenericError();
    });
  }

  toggleNotification(id: string) {
    if(!this.notificationIds.includes(id)) {
      this.notificationIds.push(id);
    } else {
      this.notificationIds.splice(this.notificationIds.indexOf(id), 1);
    }
  }

}
