import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum'
import { environment } from 'src/environments/environment';
import packageJson from '../../../../package.json';

@Injectable()
export class DatadogService {
  declare require: any;

  init(): void {
    datadogLogs.init({
      clientToken: 'pubdeac6f668fcc52c63fa4c04461ae57c3',
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: 'practitioner_portal_-_ui',
      version: packageJson.version,
      env: environment.env
    });

    datadogRum.init({
      applicationId: '37c96b00-5a34-4a1b-a7b9-586200e187b7',
      clientToken: 'pubed48875ebc116e41cd8d5cda9124f9e1',
      site: 'datadoghq.eu',
      service: 'practitioner_portal_-_ui',
      version: packageJson.version,
      env: environment.env,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100, // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enableExperimentalFeatures: ["feature_flags"],
    })
  }
}
