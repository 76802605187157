import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/core/services/message.service';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { StateService } from 'src/app/core/services/state.service';
import { ConfirmationDialogComponent } from 'src/app/dh-common/components/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { DeletePatientComponent } from '../delete-patient/delete-patient.component';

import jwt_decode from "jwt-decode";
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-patient-menu',
  templateUrl: './patient-menu.component.html',
  styleUrls: ['./patient-menu.component.scss']
})
export class PatientMenuComponent implements OnInit {

  isPatientOnboarded: Boolean;

  constructor(private dialogRef: MatDialog,private patientStateService: PatientStateService, 
    private router: Router, private translate: TranslateService, private stateService: StateService, private http: HttpClient,
    private messageService: MessageService, private authService: AuthService) { }


  ngOnInit(): void {
    const decoded: any = jwt_decode(this.authService.getPatientAccessToken());
    this.isPatientOnboarded = decoded.patientOnboarded;
  }

  openDeleteDialog() {
    const deleteModal = this.dialogRef.open(DeletePatientComponent, {
      width: '400px',
      data: { patientName: '', patientId: this.patientStateService.patientId }
    })

    deleteModal.afterClosed().subscribe((data: boolean) => {
      if(data) {
        this.router.navigateByUrl('/overview');
      }
    });
  }

  resetPassword() {
    return new Promise((resolve, reject) => {
      const data = {
        "providerName": this.stateService.providerId,
        "username": this.patientStateService.patientId
      };
      const url = environment.endpoint + environment.services.force_new_password;
      this.http.post(url, data).subscribe(response => {
        resolve(response);
        this.messageService.showSuccessMessage(this.translate.instant('create_patient.reset_password_success_text'))
      }, error => {
        reject();
        this.messageService.showGenericError();
      });
    });
  }

  // openResetPasswordModal(element) {
  //   const dialogInterface: DialogInterface = {
  //     dialogHeader: this.translate.instant('overview_page.reset_password_text'),
  //     dialogContent: this.translate.instant('overview_page.reset_password_info_text', { PATIENT_NAME: '' }),
  //     cancelButtonLabel: this.translate.instant('cancel'),
  //     confirmButtonLabel: this.translate.instant('submit'),
  //     callbackMethod: () => {
  //       return this.resetPassword();
  //     },
  //   };
  //   this.dialogRef.open(ConfirmationDialogComponent, {
  //     width: '400px',
  //     data: dialogInterface,
  //   });
  // }

}
