<div class="card">
    <div class="card-body">
        <div class="card-text">
            About DEARhealth
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <div class="d-flex flew-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-manufacturer">
                                <span class="sr-only">
                                    Manufacturer
                                </span>
                            </i>
                        </div>
                        <div>
                            Dear Health Holding Company B.V.<br>
                            Bargelaan 200<br>
                            2333 CW Leiden<br>
                            www.dearhealth.com    
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-column mr-1">
                            <div>
                                <i class="ce-icon ce mr-2"></i>
                                <i class="ce-icon ce-md"></i>
                            </div>
                        </div>
                    </div>
                    <div class="ml-2">1912</div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-ref">  
                            </i>
                        </div>
                        <div>
                            08720299126015
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-udi">  
                            </i>
                        </div>
                        <div>
                            (01) 08720299126015 <br>
                            (11) 20230405 <br>
                            (8012) v2.2 
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-date-of-manufacture">  
                            </i>
                        </div>
                        <div>
                            2023-04-05
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-sn">  
                            </i>
                        </div>
                        <div>
                            v2.2
                        </div>
                    </div>
                </li>
                <li class="list-group-item"><div class="d-flex flex-row">
                    <div class="mr-2"><i class="ce-icon ce-instructions"><span class="sr-only">Instructions:</span></i></div>
                    <div>
                        {{'about.instruction_line1'| translate }} <a href="{{manualUrl}}" target="_blank">{{manualUrl}}</a>
                        <br>
                        {{'about.instruction_line2'| translate}}<br>
                        {{'about.instruction_line3'| translate}}<br>
                    </div>
                </div></li>
            </ul>
        </div>
    </div>
</div>