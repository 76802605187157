import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Apollo } from 'apollo-angular';
import jwt_decode from "jwt-decode";
import * as moment from 'moment';
import { DhMatDateFormat } from 'src/app/core/adapters/date-format-factory';
import { GET_PHYSICIANS_BY_DISEASE } from 'src/app/core/constants/graphql.query.constants';
import { CareTeam, Dropdown, MedicalContent } from 'src/app/core/model/common.interface';
import { LangType, StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-care-form',
  templateUrl: './care-form.component.html',
  styleUrls: ['./care-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
      }
  ]
})
export class CareFormComponent implements OnInit, OnChanges {
  diseaseList: Dropdown[];
  physiciansList: Dropdown[];
  calendarEndDate: Date;
  calendarStartDate: Date;
  @Input() formGroup: FormGroup;
  @Input() isEditable?: boolean;
  @Input() isEditScreen: boolean = false;
  @Input() emrIntergrationEnabled: boolean;
  medicalContent: MedicalContent;
  diseaseValue: string = '-';
  physicianValue: string = '-';
  programStartDateValue: string = '-';
  dateFormat: string;
  assignedPractitionerId: string;
  lang: LangType;

  constructor(private apollo: Apollo, private stateService: StateService, private datePipe: DatePipe, private dateAdapter: DateAdapter<any>,
    @Inject(MAT_DATE_FORMATS) public config: DhMatDateFormat) { }

  ngOnInit(): void {

    this.stateService.lang.subscribe((data) => {
      this.lang = data;
      this.dateAdapter.setLocale(data);
      this.getDisease();
    });
    this.medicalContent = this.stateService.getMedicalContent;
    this.dateFormat = this.medicalContent.formatting.shortDateFormat
    this.config.dateFormat = this.dateFormat.replace('dd', 'DD');
    this.calendarStartDate = moment(new Date()).subtract(1, 'y').toDate();
    this.calendarEndDate = moment(new Date()).add(5, 'y').toDate();
    const currntUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const defaultPhysiciansList: Dropdown[] = [{
      id: currntUser.id,
      name: currntUser.firstName + currntUser.lastName
    }];
    this.physiciansList = defaultPhysiciansList;
    this.assignedPractitionerId = this.formGroup.get('practitionerId').value;
    if (!this.isEditScreen) {
      this.formGroup.get('practitionerId').setValue(currntUser.id);
    }
  }

  getDisease() {
    const decoded: any = jwt_decode(sessionStorage.getItem('pracAuthToken'));
    this.stateService.medicalContentObs.subscribe((data: MedicalContent) => {
      if (data) {
        const careTeams: string[] = decoded.careTeams;
        const filteredDiseases = [];
        careTeams.forEach((careTeamId: string) => {
          const filderedData = data.careTeams?.filter((careTeam: CareTeam) => {
            return careTeamId === careTeam.id
          })[0];
          if (filderedData) {
            filteredDiseases.push(filderedData.diseases);
          }
        });
        this.diseaseList = filteredDiseases.flat();
        this.diseaseValue = this.diseaseList.filter((disease) => {
          return disease.id === this.formGroup.value.diseaseId
        })[0]?.name[this.lang];
      }
    })
  }

  ngOnChanges(): void {
    if (!this.physiciansList && this.formGroup.value['diseaseId']) {
      this.getPhysicians(this.formGroup.value['diseaseId']);
    } else {
      this.physicianValue = this.physiciansList?.filter((data) => {
        return data.id === this.formGroup.value['practitionerId']
      })[0]?.name as unknown as string;
    }
    this.programStartDateValue = this.datePipe.transform(this.formGroup.get('programStartDate').value, this.dateFormat);
    this.formGroup.get('programStartDate').setValue(new Date(this.formGroup.get('programStartDate').value));
  }


  getPhysicians(diseaseId: string): void {
    this.apollo.query({
      query: GET_PHYSICIANS_BY_DISEASE,
      variables: {
        diseaseId: diseaseId
      }
    }).subscribe((result) => {
      this.physiciansList = result.data['getPhysiciansByDisease'].practitioners;
      const name = this.physiciansList.filter((data) => {
        return data.id === this.assignedPractitionerId
      })[0]?.name
      this.physicianValue = name as unknown as string;
    });
  }
}
