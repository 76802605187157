import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { MessageService } from 'src/app/core/services/message.service';
import { PasswordLessAuthService } from 'src/app/core/services/passwordless.service';
import { LangType, StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['../auth-style.scss']
})
export class EmailVerificationComponent  {

  @ViewChild('emailCode') emailCode;

  form: FormGroup;
  public navigationData: any;
  public loading = false;
  constructor(private fb: FormBuilder, private translate: TranslateService, private messageService: MessageService, private stateService: StateService,
    private router: Router, private passwordLessAuthService: PasswordLessAuthService, private loaderService: LoaderService) {
    this.stateService.lang.subscribe(res => {
      this.translate.use(res);
    });
    let validPattern = "^[0-9]{6}$";
    this.form = fb.group({
      emailCode: ['', Validators.compose([Validators.required, Validators.pattern(validPattern)]) ],
    });
    this.navigationData = this.router.getCurrentNavigation().extras.state;
  }

  ngAfterViewChecked() {
    // this.element.nativeElement.querySelector('#textarea-1').focus()
    this.emailCode.nativeElement.focus();
  }

  async verifyCode(): Promise<void> {
    try {
      if (this.form.valid) {
        this.loaderService.show();
        const data = await this.passwordLessAuthService.confirmSignIn(this.form.value.emailCode.toString(), this.navigationData.nextStep.signInStep);
        if(data.nextStep.additionalInfo.challengeType === 'EMAIL') {
          this.messageService.showError(this.translate.instant('passwordLess.Invalid_mail_code'));
        } else {
          this.router.navigateByUrl('/sms-verify', { state: { ...data, ...{'userName': this.navigationData.userName} } });
        }
      }
    } catch (error) {
      this.messageService.showError(this.translate.instant('passwordLess.Invalid_mail_code'));
    } finally {
      this.loaderService.hide();
    }
  }

  resendEmailCode(): void {
    try {
      this.passwordLessAuthService.resendSignUpCode(this.navigationData.userName, this.navigationData.nextStep.signInStep); 
    } catch (error) {
      this.messageService.showError('Resend email code failed');
    }
  }
}
