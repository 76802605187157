import { Questionnaire, Component } from '../../../../core/model/common.interface'
import { QuestionnaireResponseDto, QuestionnaireResponse } from '../../../../core/model/patient.interface';
import _ from 'lodash'

const fractionTypes: string[] = ["cutoff", "nothing"]

export function showQuestionnaireAsFraction(questionnaire: Questionnaire){
  return questionnaire.components.every( component => fractionTypes.includes(component.scoreNormalisationType));
}

export function showComponentAsFraction(component: Component){
  return fractionTypes.includes(component.scoreNormalisationType)
}

export function isInverted(questionnaire: Questionnaire){
  return questionnaire.components.some(component => component.invertPercentage)
}

export function getMaxScore(element: QuestionnaireResponse | QuestionnaireResponseDto){
  return _.sumBy(element.componentResponses, component => component.maxScore);
}