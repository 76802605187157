import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProvidersFilterPipe } from './providers.filter.pipe';
import { TranslatablePipe } from './translatable.pipe';
import { LocalDatePipe } from './localdate.pipe';
import { FilterNameByIdPipe } from './filterNameById.pipe';


@NgModule({
  declarations: [ProvidersFilterPipe, TranslatablePipe, LocalDatePipe, FilterNameByIdPipe],
  imports: [
    CommonModule
  ],
  exports: [ProvidersFilterPipe, TranslatablePipe, LocalDatePipe, FilterNameByIdPipe]
})
export class PipesModule { }
