<div class="activate-addon">
    <div mat-dialog-title>
        <div class="row">
            <div class="col-md-6 border-right">
                <h3>{{'multi_scenario.addons.available' | translate}}</h3>
            </div>
            <div class="col-md-6">
                <h3>{{'multi_scenario.addons.enabled' | translate}}</h3>
            </div>
        </div>
    </div>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-6 border-right tree-container">
                <mat-tree [dataSource]="availableDataSource" [treeControl]="availableTreeControl">
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasAddons">
                        <div class="mat-tree-node">
                            <mat-card class="w-100 mb-3 addon-card" matTreeNodeToggle>
                                {{node.type | translatable:lang}}
                                <i class="fa fa-lg float-right"
                                    [ngClass]="{'fa-caret-down': availableTreeControl.isExpanded(node), 'fa-caret-right': !availableTreeControl.isExpanded(node)}"></i>
                            </mat-card>
                        </div>
                        <div [class.example-tree-invisible]="!availableTreeControl.isExpanded(node)" role="group">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </div>
                    </mat-nested-tree-node>
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                        <mat-card class="w-100 mb-3 ml-5" matTreeNodeToggle>
                            {{node.name | translatable:lang}}
                            <mat-checkbox class="checklist-leaf-node" [checked]="selectedAddOns.isSelected(node)"
                                (change)="addonToggle(node)">{{node.item}}</mat-checkbox>
                        </mat-card>
                    </mat-tree-node>
                </mat-tree>
            </div>
            <div class="col-md-6 activated-container border-right tree-container">
                <mat-tree [dataSource]="activatedDataSource" [treeControl]="activatedTreeControl">
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasAddons">
                        <div class="mat-tree-node">
                            <mat-card class="w-100 mb-3 addon-card" matTreeNodeToggle>
                                {{node.type | translatable: lang}}
                                <p>{{node.addons[0].name| translatable: lang}}</p>
                                <!-- <p *ngFor="let childNode of node.addons">
                                    {{childNode.name.en}}
                                </p> -->
                                <i class="fa fa-lg float-right"
                                    [ngClass]="{'fa-caret-down': activatedTreeControl.isExpanded(node), 'fa-caret-right': !activatedTreeControl.isExpanded(node)}"></i>
                            </mat-card>
                        </div>
                        <div [class.example-tree-invisible]="!activatedTreeControl.isExpanded(node)" role="group">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </div>
                    </mat-nested-tree-node>

                    <mat-tree-node *matTreeNodeDef="let childNode" matTreeNodeToggle>

                        <div matTreeNodeToggle class="child-container">

                            <mat-card>
                                <mat-form-field appearance="fill" class="ml-5">
                                    <mat-label>{{'multi_scenario.start_date_label' | translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker3" [(ngModel)]="childNode.active.startDate" disabled="{{alreadyActive(childNode)}}">
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                                <div>{{"offset" | translate}}</div>
                                <mat-form-field  class="mr-3 ml-5 w-25">
                                   <input matInput type="number" [(ngModel)]="childNode.active.startOffset" >
                                </mat-form-field>
                                <mat-form-field appearance="" class="w-25">
                                    <mat-select [(ngModel)]="childNode.active.offsetType">
                                       <mat-option value="week">Week</mat-option>
                                       <mat-option value="month">Month</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                            <div class="button-container"><button (click)="removeAddon(childNode)" class="dh-btn dh-btn-secondary mat-button">{{'remove' | translate }}</button></div>

                        </div>
                    </mat-tree-node>
                </mat-tree>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close class="dh-btn dh-btn-secondary">{{'cancel' | translate}}</button>
        <button mat-button (click)="saveAddon()" class="dh-btn dh-btn-primary">{{'save' | translate}}</button>
    </mat-dialog-actions>
</div>