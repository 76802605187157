import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { InMemoryCache } from "@apollo/client/core";
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from "apollo-angular/http";
import { environment } from '../environments/environment';
const uri = environment.services.graphql;

@NgModule({
  imports: [ApolloModule],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: (httpLink: HttpLink) => ({
      cache: new InMemoryCache(),
      link: httpLink.create({uri}),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        }
      }
    }),
    deps: [HttpLink]
    }
  ],
  exports: [HttpClientModule]
})

export class GraphQLModule {

}
