import { Pipe, PipeTransform } from '@angular/core';
import { Translatable } from 'src/app/core/model/patient.interface';

@Pipe({
  name: 'translatable'
})
export class TranslatablePipe implements PipeTransform {
  transform(value: Translatable, lang: string): string {
    return getTranslationForLanguage(value, lang);
  }
}

export function getTranslationForLanguage(translatable: any, lang: string): string {
  if (!translatable) {
    return '';
  } else if(translatable === '-') {
    return '-';
  } else if ((lang in translatable) && (translatable[lang])) {
    return translatable[lang];
  }
  return translatable.en;
}
