export const scoreSmiles = new Map<string, string>();
scoreSmiles.set('10', 'smiles/sad.png');
scoreSmiles.set('9', 'smiles/sad.png');
scoreSmiles.set('8', 'smiles/l_sad.png');
scoreSmiles.set('7', 'smiles/l_sad.png');
scoreSmiles.set('6', 'smiles/neutral.png');
scoreSmiles.set('5', 'smiles/neutral.png');
scoreSmiles.set('4', 'smiles/neutral.png');
scoreSmiles.set('3', 'smiles/l_happy.png');
scoreSmiles.set('2', 'smiles/l_happy.png');
scoreSmiles.set('1', 'smiles/happy.png');
scoreSmiles.set('0', 'smiles/happy.png');

export const ECARE_QUESTIONNAIRE = 'ECARE'
