// form-modal.component.ts

import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyInputFieldConfig } from '@ngx-formly/material/input';
import { Apollo } from 'apollo-angular';
import { SAVE_PATIENT_PROFILE_DATA } from 'src/app/core/constants/graphql.query.constants';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['../formly.component.scss'],
})
export class FormModalComponent {
  form = new FormGroup({});
  formFields: FormlyFieldConfig[];
  initialFormData: any;
  formData: any;
  formName: string;

  constructor(
    private dialogRef: MatDialogRef<FormModalComponent>,
    private apollo: Apollo,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.formFields = data.form.fields;
    this.formData = data.formData;
    this.initialFormData = {...data.formData};
    this.formName = data.form.formName;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  isSaveDisabled(): boolean {
    return this.form.invalid;
  }

  applyReadOnlyLogic(fields: any[]): any[] {
    return fields.map(field => {
      // Use the 'readOnly' property from the field-level configuration
    //   field.templateOptions = field.templateOptions || {};
    //   field.templateOptions.readOnly = field.readOnly || false;
  

      field.expressionProperties = field.expressionProperties || {};
      field.expressionProperties['props.disabled'] = 'field.readOnly' || false;

      // If the field has a fieldGroup, recursively apply the logic
      if (field.fieldGroup) {
        field.fieldGroup = this.applyReadOnlyLogic(field.fieldGroup);
      }
  
      return field;
    });
  }
  
  saveProfileData(): void {
    let modifiedFormdata = {};
    Object.keys(this.formData).forEach(key => {
      if(this.formData[key] !== this.initialFormData[key]) {
        modifiedFormdata[key] = this.formData[key];
      }
    });
    this.apollo.mutate({
        mutation: SAVE_PATIENT_PROFILE_DATA,
        variables: {
          inputJson: JSON.stringify(modifiedFormdata),
        }
      }).subscribe(() => {
        this.dialogRef.close(this.form.value);
      }, (error) => {
        this.messageService.showGenericError();
      });
  }
}
