import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading;

  constructor(private loaderService: LoaderService, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe(res => {
      this.isLoading = res;
      this.cdRef.detectChanges();
    });
  }
}
