import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class SsoGuard implements CanActivate {
  constructor(private stateService: StateService){}
  canActivate(): boolean  {
      let ssoValue: boolean;
      this.stateService.sso.subscribe((data) => {
        ssoValue = data;
      });
    return !ssoValue
  }
}
