import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retry, retryWhen } from 'rxjs/operators';
import { MessageService } from '../services/message.service';
export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;
@Injectable({   providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService) {}

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   return next.handle(request).pipe(
  //     retry(1),
  //     catchError((error: HttpErrorResponse) => {
  //       console.error(error);
  //       if (!error.error.details?.reason) {
  //         this.MessageService.showHttpError(error);
  //       }
  //       return throwError(error);
  //     }) 
  //   );
  // }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const hasWaitingForFilter = request.headers.get('x-dh-waiting-for-filter') != null && typeof request.headers.get('x-dh-waiting-for-filter') !== 'undefined';
    return next.handle(request).pipe(
      retryWhen(error => 
        error.pipe(
          concatMap((error, count) => {
            if (count < retryCount && error.status === 304 && hasWaitingForFilter ) {
              return of(error);
            }
            if (!error.error?.error) {
              this.messageService.showHttpError(error);
            }
            return throwError(error);
          }),
          delay(retryWaitMilliSeconds)
        )
      )
    )
  }
}
