<div class="treatment-block">
    <h2 mat-dialog-title *ngIf="!data.edit"> {{'multi_scenario.title' | translate}}</h2>
    <mat-dialog-content class="mat-typography">
        <div class="action diseases-physicians">
            <div class="row form-group align-items-center">
                <div class="col-md-4 start-time pr-0 ">
                    <div class="title">
                        {{'multi_scenario.start_date_label' | translate}}
                    </div>
                    <mat-form-field>
                        <input matInput [min]='calendar_start_date' [matDatepicker]="picker" (click)='picker.open()'
                            [formControl]="startDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="startDate.errors?.required">
                            {{'multi_scenario.error_message.start_date_required' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 offset pr-0" >
                    <div class="title">
                        {{'multi_scenario.skip_activities_label' | translate}}
                        <i class="fa fa-info-circle pl-2" matTooltip="{{'multi_scenario.move_activities_info_text' | translate}}" matTooltipPosition="right"></i>
                    </div>
                    <mat-form-field>
                        <input matInput type="text" [formControl]="offset">
                    </mat-form-field>
                </div>
                <div class="btn-container col-md-4">
                    <span class="pl-1" *ngIf="viewType.value == 'month'">{{'multi_scenario.months_label' | translate}}</span>
                    <span class="pl-1" *ngIf="viewType.value == 'week'">{{'multi_scenario.weeks_label' | translate}}</span>
                    <a class="button-link pl-3" href="javascript:void(0)" (click)="getPreviewData()">{{'multi_scenario.preview_label' | translate}}</a>
                </div>
            </div>

            <mat-button-toggle-group [formControl]="viewType" aria-label="Font Style" class="dh-btn-toggle">
                <mat-button-toggle value="month">{{'multi_scenario.interval_types.MONTH' | translate}}</mat-button-toggle>
                <mat-button-toggle value="week">{{'multi_scenario.interval_types.WEEK' | translate}}</mat-button-toggle>
            </mat-button-toggle-group>

            <div class="treatment-timeline">
                <app-monthly-calendar *ngIf="viewType.value == 'month'" [medicalContent]="medicalContent"  [activities]="scenarioActivities"
                    [isPreview]="true" [startDate]="startDate.value"></app-monthly-calendar>
                <app-interval-calendar *ngIf="viewType.value =='week'" [activities]="scenarioActivities"
                    [isPreview]="true" [startDate]="startDate.value"></app-interval-calendar>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div>
            <p><i class="fa fa-solid fa-caret-down orange-text"></i> {{'multi_scenario.treatment_start_label' |
                translate}}</p>
            <p *ngIf="viewType.value == 'week'"><span class="blue-block"></span>{{'multi_scenario.current_week_label' |
                translate}}</p>
            <p *ngIf="viewType.value =='month'"><span class="blue-block"></span>{{'multi_scenario.current_month_label' |
                translate}}</p>
        </div>
        <div>
            <button mat-button mat-dialog-close (click)="onCloseClick()" class="dh-btn dh-btn-secondary">{{'cancel' |
                translate}}</button>
            <button (click)="activate()" class="dh-btn dh-btn-primary" mat-button>{{'save' | translate}}</button>
        </div>
    </mat-dialog-actions>

</div>