import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';

import * as chartsData from '../../../shared/data/chartsjs';
import { ChartType } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import "billboard.js/dist/theme/insight.css";
import bb, { line } from "billboard.js";
import * as d3 from "d3";
import {QuestionnaireResponseModalComponent} from '../../../patient/components/questionnaire-response/components/questionnaire-response-modal/questionnaire-response-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ECARE_QUESTIONNAIRE} from '../../../core/constants/questionnaire.constant';
import { getTranslationForLanguage } from 'src/app/shared/pipes/translatable.pipe';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { MedicalContentNames, Questionnaire, QuestionnaireNames } from 'src/app/core/model/common.interface';


@Component({
  selector: 'app-health-assessment-graph',
  templateUrl: './health-assessment-graph.component.html',
  styleUrls: ['./health-assessment-graph.component.scss']
})
export class HealthAssessmentGraphComponent implements OnInit {
  private _totalScore: string;
  private historyData: any;
  activities: string[] = [];
  lang: LangType;
  questionnaireData: Array<Questionnaire>
  questionnaireNames: QuestionnaireNames;

  @Input() hasHealthAssessment;
  @Input() mappedResponse: any;
  @Input() cpActivityId: string;
  @Input() activityId: string;
  @Input() questionnaireId: string;
  @Input()
  set historyResponses(historyResponses: any) {
    if (historyResponses) {
      this.historyData = historyResponses;
      this.activities = historyResponses.activities;
      // setTimeout(() => {
      //   this.drawLineChart(this.historyData);
      // }, 1000)
      this.setLang();
    }
  }
  @Input()
  set totalScore(totalScore: string) {
    this._totalScore = totalScore;
    const pieFilledPercentage = Number(totalScore);
    this.pieChartData = [{
      data: [pieFilledPercentage, 100 - pieFilledPercentage],
      backgroundColor: ['#FF9803', '#ddd']
    }];
  }
  get totalScore(): string {
    return this._totalScore;
  }

  constructor(private translateService: TranslateService, private stateService: StateService,
    public dialog: MatDialog, private viewRef: ViewContainerRef) {

  }

  ngOnInit(): void {
    this.stateService.medicalContentObs.subscribe((data) => {
      if (data)
        this.questionnaireData = data.questionnaires;
    });
    this.stateService.medicalContentNamesObs.subscribe((data: MedicalContentNames) => {
      if (data) {
        this.questionnaireNames = data.questionnaires;
      }
    });
  }

  setLang() {
    this.stateService.lang.subscribe((lang) => {
      this.lang = lang;
      if (this.historyData) {
        setTimeout(() => {
        this.drawLineChart(this.historyData);
      }, 0)
      }
    });

  }

  pieChartOptions = chartsData.doughnutChartOptions;
  pieChartLabels = ['health'];
  pieChartData;
  pieChartType = 'doughnut';
  pieChartLegend = false;


  lineChartConfig = {
    lineChartOptions: {
      responsive: true,
      scales: {
        xAxes: [{
          ticks: {
            autoSkip: false,
            maxRotation: 90,
            minRotation: 90
          }
        }],
        yAxes: [
          {
            ticks: {
              min: 0,
              max: 100,
              stepSize: 20,
            }
          }
        ]
      },
      legend: {
        labels: {
          boxWidth: 0,
        }
      }
    },
    lineChartLegend: true,
    lineChartPlugins: [],
    lineChartType: 'line',
  }

  pieChartPlugins = [
    {
      id: 'drawHeart',
      beforeDraw(chart): void {
        const percentage = '';
        const ctx = chart.ctx;
        const txt = 'Center Text';
        ctx.textAlign = 'center';
        if (this._totalScore)
          ctx.textBaseline = 'middle';
        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
        ctx.font = 25 + 'px Arial';
        ctx.fillStyle = '#d6578b';
        ctx.fillText(percentage, centerX, centerY);
      },
    }
  ];

  translate(obj: any) {
    return getTranslationForLanguage(obj, this.lang);
  }

  getQuestionnaire(questionnaireId: string) {
    return this.questionnaireData.filter(questionnaire => questionnaire.id == questionnaireId)[0];
  }

  public openModal(sourceId: string, questionnaireId: string): void {
    const modalRef = this.dialog.open(QuestionnaireResponseModalComponent, {
      viewContainerRef: this.viewRef,
      width: `1000px`,
      height: '95%',
      data: {
        'sourceId': sourceId,
        'type': ECARE_QUESTIONNAIRE,
        'questionnaireId': questionnaireId,
        'questionnaireData': this.getQuestionnaire(questionnaireId),
        'questionnaireName': this.questionnaireNames[questionnaireId].name,

      }
    });
  }

  drawLineChart(historyResponses: any) {
    // document.getElementById('#chart').innerHTML = "";
    if (document.getElementsByClassName('ha-chart').length > 0) {
      let context = this;
      var chart = bb.generate({
        bindto: '.ha-chart',
        padding: {
          right: 30,
        },
        size: {
          height: 230
        },
        data: {
          type: line(),
          x: 'X',
          order: null,
          columns: historyResponses.columns,
          colors: Object.keys(historyResponses.config).reduce((acc, key) => { acc[key] = historyResponses.config[key].color; return acc; }, {}),
          names: Object.keys(historyResponses.config).reduce((acc, key) => { acc[key] = this.translate(historyResponses.config[key].name); return acc; }, {}),
          onclick(d: any, element: SVGElement) {
            context.openModal(Object.values(context.activities)[d.index], Object.keys(context.activities)[d.index]);
          }
        },
        axis: {
          x: {
            type: 'timeseries',
            padding: { left: 0, right: 0 },
            tick: {
              outer: false,
              fit: true,
              format: "%e %b '%y",
              culling: true,
              rotate: 90
            }
          },
          y: {
            max: 100,
            min: 0,
            padding: { top: 0, bottom: 0 },
            tick: {
              outer: false,
              count: 6,
            }
          }
        },
        point: {
          show: true
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          show: false
        }
      });

      d3.selectAll('.legend').remove();
      d3.selectAll('.chart-container')
        .insert('div', '.ha-chart')
        .attr('class', 'legend')
        .selectAll('span')
        .data(Object.entries(historyResponses.config).map(([key, value]) => ({ id: key, name: this.translate(value['name']) })), (d) => d['id'])
        .enter().append('span')
        .attr('data-id', function (id) { return id['id']; })
        .html(function (id) { return id['name']; })
        .each(function (id) {
          d3.select(this).style('color', chart.color(id['id']));
        })
        .on('mouseover', function (id) {
          chart.focus(id['id']);
        })
        .on('mouseout', function (id) {
          chart.revert();
        })
        .on('click', function (id, index, arr) {
          // arr[index].classList.toggle("toggled-legend");
          id.currentTarget.style.opacity = id.currentTarget.style.opacity == 0.5 ? 1 : 0.5;
          chart.toggle(index['id']);
        });
    }
  }
}
