import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogResponse} from '../../../../../core/constants/dialog_response.constant';
import {TranslateService} from '@ngx-translate/core';
import { getTranslationForLanguage } from 'src/app/shared/pipes/translatable.pipe';


@Component({
  selector: 'confirm-switch-deactivation-modal.component',
  templateUrl: 'switch-deactivate-confirm-modal.component.html',
})
export class ConfirmSwitchDeactivationModal {

  scenarioName: string;

  constructor(public dialogRef: MatDialogRef<ConfirmSwitchDeactivationModal>,
              @Inject(MAT_DIALOG_DATA) public data,
              private translate: TranslateService) {
    this.scenarioName = data.scenarioName
  }

  confirm() {
    this.dialogRef.close(DialogResponse.CONFIRM);
  }

  cancel() {
    this.dialogRef.close(DialogResponse.CANCEL);
  }
}
