import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, InterceptorSkipHeader } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      request = request.clone({ headers });
    } else {
      const url = new URL(request.url, 'https://dummy');
      if (url.pathname.startsWith('/graphql') || url.host.includes('medical-contents') || url.pathname.startsWith('/medical-contents')) {
        let token = this.authService.getApiToken();
        if(window.location.hash.includes('/overview')) {
          token = this.authService.getPractitionerToken();
        }
        request = request.clone({
          setHeaders: {
            'Authorization': token
          }
        })
      } else if(window.location.hash.includes('/patient')) {
        request = request.clone({
          setHeaders: {
            'auth-token': `Bearer ${this.authService.getApiToken()}`
          }
        });
      }
      else {
        request = request.clone({
          setHeaders: {
            'auth-token': `Bearer ${this.authService.getPractitionerToken()}`
          }
        });
      }
    }
    return next.handle(request);
  }
}
