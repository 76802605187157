import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Apollo } from "apollo-angular";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { distinct, filter } from "rxjs/operators";
import { APP_CONSTANTS } from "src/app/core/constants/app.constants";
import { GET_PATIENT_QUERY, UPDATE_PATIENT, GET_RECOMMENDER_KEYS } from "src/app/core/constants/graphql.query.constants";
import { MedicalContent, FormlyConfig } from "src/app/core/model/common.interface";
import { MessageService } from "src/app/core/services/message.service";
import { PatientStateService } from "src/app/core/services/patient-state.service";
import { StateService } from "src/app/core/services/state.service";
import { CreatePatientForms } from "src/app/dh-common/components/create-patient-forms/create-patient.forms";
import { LaunchDarklyService } from 'src/app/core/services/launchDarkly.service';
import { MatDialog } from '@angular/material/dialog';
import * as ld from 'launchdarkly-js-client-sdk';
import _ from 'lodash';
import { ApplicationRef } from '@angular/core';
import { PatientService } from "src/app/core/services/patient.service";
import { profile } from "console";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends CreatePatientForms implements OnInit {

    querySubscription: Subscription;
    patientData: any;
    hasDataLoaded: boolean = false;
    hasLoaded = false;
    isGeneralInfoEditable: boolean = false;
    isCareEditable: boolean = false;
    isContactEditable: boolean = false;
    medicalContent: MedicalContent;
    emrIntergrationEnabled: boolean;
    tabs: string[] = ["Patient"]
    selectedTab?: string = 'Patient';
    showTabs: boolean = false
    forms: Record<string, FormlyConfig> = {}
    launchDarklyClient: ld.LDClient;
    _subscription: any;
    model: any;
    tabDatas = [];
    formDatasMap = new Map<string, any>();
    formFieldsMap = new Map<string, any>();
    keySet = new Set<string>();

    constructor(private patientService: PatientService, private patientStateService: PatientStateService, private launchDarklyService: LaunchDarklyService, formBuilder: FormBuilder, private datePipe: DatePipe,
        private messageService: MessageService, private apollo: Apollo, private translate: TranslateService, private stateService: StateService, private appRef: ApplicationRef, private dialog: MatDialog) {
        super(formBuilder);
    }

    getModel() {
        return this.model;
    }

    ngOnInit(): void {
        this.getPatient();
        this.stateService.medicalContentObs.subscribe((data) => {
            this.medicalContent = this.stateService.getMedicalContent;
            const profileFormJson = this.medicalContent.diseases.filter((disease) => disease.id === this.patientStateService.diseaseId)[0]?.profileFormDocument;
            const tabNames = ["Patient"];
            if(Array.isArray(profileFormJson) ) { 
                this.createTabDatas(profileFormJson, tabNames);
            } else if(profileFormJson && Object.keys(profileFormJson).length > 0) { 
                this.createTabDatas([profileFormJson], tabNames);
            }
            this.emrIntergrationEnabled = this.medicalContent?.emrIntergrationEnabled;
        });
    }
    
    retrieveAndSetModel = () => {
        this.keySet.size > 0 &&
            this.apollo.query({
                query: GET_RECOMMENDER_KEYS,
                variables: {
                    keys: [...this.keySet]
                }
            }).subscribe((result) => {
                const savedResults = JSON.parse(result.data["getRecommenderKeys"]) ?? {};
                if (Object.keys(savedResults).length > 0) {
                    this.formDatasMap.forEach((formdata) => {
                        Object.keys(formdata).forEach((key) => {
                            formdata[key] = savedResults[key];
                        });
                    });
                    this.hasDataLoaded = true;
                }
            });
    }

    private createTabDatas(profileFormJson: any, tabNames: string[]) {
        profileFormJson.forEach((data) => {
            tabNames.push(data.tabName);
            this.tabDatas.push(data);
            const formNames = [];
            data.forms.forEach((form) => {
                formNames.push(form.formName);
                let formdata = {};
                this.formFieldsMap.set(form.formName, form.fields);
                form.fields.forEach((field) => {
                    field.fieldGroup.forEach((fieldGroup) => {
                        formdata[fieldGroup.key] = null;
                        this.keySet.add(fieldGroup.key);
                    });
                });
                this.formDatasMap.set(form.formName, formdata);
            });
        });
        this.retrieveAndSetModel();
        this.tabs = tabNames;
    }

    getPatient(): void {
        this.patientStateService.patientIdSubject$.pipe(filter(id => !!id), distinct()).subscribe((patientId) => {
            this.querySubscription = this.apollo
                .query({
                    query: GET_PATIENT_QUERY,
                }).subscribe((data) => {
                    const patientData = _.cloneDeep(data.data['getPatient']);
                    const dob = moment(patientData.dob);
                    // const dob = new Date(patientData.dob);
                    patientData.date = dob.date();
                    patientData.month = dob.month() + 1;
                    patientData.year = dob.year();
                    this.patientData = patientData;
                    this.generalFormGroup.patchValue(patientData);
                    this.contactFormGroup.patchValue(patientData);
                    const diseaseAndPractitionerId = patientData['diseasesAndPractitioners'].substring(1, patientData['diseasesAndPractitioners'].length - 1);
                    this.careFormGroup.patchValue({ diseaseId: diseaseAndPractitionerId.split('=')[0], practitionerId: diseaseAndPractitionerId.split('=')[1], programStartDate: patientData.programStartDate });
                    this.hasLoaded = true;
                }, () => {
                    this.messageService.showGenericError();
                });
        });
    }
    cancelContact() {
        this.isContactEditable = !this.isContactEditable;
        this.contactFormGroup.patchValue(this.patientData);
    }
    cancelCare() {
        this.isCareEditable = !this.isCareEditable;
        this.careFormGroup.patchValue(this.patientData);
    }
    cancelGeneralInfo() {
        this.isGeneralInfoEditable = !this.isGeneralInfoEditable;
        this.generalFormGroup.patchValue(this.patientData);
    }
    changeGeneralInfoEditState() {
        if (this.isGeneralInfoEditable) {
            this.savePatientInfo();
        }
        this.isGeneralInfoEditable = !this.isGeneralInfoEditable;
    }

    changeContactEditState() {
        if (this.isContactEditable) {
            this.savePatientInfo();
        }
        this.isContactEditable = !this.isContactEditable;
    }

    changeCareEditState() {
        if (this.isCareEditable) {
            this.savePatientInfo();
        }
        this.isCareEditable = !this.isCareEditable;
    }

    savePatientInfo() {
        const careFormPayload = {
            diseasePractitionerMapping: [{
                diseaseId: this.careFormGroup.get('diseaseId').value,
                practitionerId: this.careFormGroup.get('practitionerId').value
            }],
            departments: this.patientData.departments,
            programStartDate: this.careFormGroup.get('programStartDate').value,
        }

        const payload = { ...this.generalFormGroup.value, ...this.contactFormGroup.value, ...careFormPayload };
        payload.dob = moment(`${payload.month}-${payload.date}-${payload.year}`).format(APP_CONSTANTS.YYYY_MM_DD_FORMAT);
        payload.programStartDate = moment(payload.programStartDate).format(APP_CONSTANTS.YYYY_MM_DD_FORMAT);
        ['date', 'month', 'year'].forEach(prop => delete payload[prop]);
        const savePatientObs = this.apollo.mutate({
            mutation: UPDATE_PATIENT,
            variables: {
                input: payload
            }
        });
        savePatientObs.subscribe((data) => {
            this.patientStateService.setPatientDetail = payload;
            this.isCareEditable = false;
            this.isContactEditable = false;
            this.isGeneralInfoEditable = false;
            this.messageService.showSuccessMessage(this.translate.instant('patient_profile.save_text'))
        }, (err) => {
            this.messageService.showGenericError();
        })
    }

    updateFormDataMap(newData: any) {
        this.formDatasMap.set(newData.formName, newData.formData);
    }
}