// auth.service.ts
import { Injectable } from '@angular/core';
import  { Amplify} from 'aws-amplify';
import { resendSignUpCode, signIn, signOut,  } from 'aws-amplify/auth';
import { confirmSignIn, fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../../../environments/environment';
import { LaunchDarklyService } from './launchDarkly.service';
import { HttpClient } from '@angular/common/http';
import { preventMultipleConcurrentExecutions } from '../../dh-common/utilities/async-utils'

@Injectable({
  providedIn: 'root',
})
export class PasswordLessAuthService {
  static launchDarklyService: any;  
  constructor(private launchDarklyService: LaunchDarklyService, private http: HttpClient, ) {
    Amplify.configure({
      Auth: {
        Cognito: {
            userPoolId: environment.cognitoConfig.userPoolId,
            userPoolClientId: environment.cognitoConfig.clientId,
        }
      }
    });
  }

  async signIn(email: string): Promise<any> {
    try {
      await signOut();
      const user = await signIn({'username': email, 'options': {'authFlowType': 'CUSTOM_WITHOUT_SRP'}});
      return user;
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }

  async confirmSignIn(code: string, challenge: string): Promise<any> {
    try {
      const user = await confirmSignIn({challengeResponse: code, options : {'authFlowType': challenge}});
      return user;
    } catch (error) {
      // await fetchAuthSession({ forceRefresh: true });
      console.error('Error confirming sign in:', error);
      throw error;
    }
  }

  async getTokens(): Promise<any> {
    try {
      const user = await fetchAuthSession();
      return {'idToken': user.tokens.idToken.toString(), 'accessToken': user.tokens.accessToken.toString()};
    } catch (error) {
      console.error('Error getting current session:', error);
      throw error;
    }
  }

  async getCurrentSession (): Promise<any> {
    try {
      const user = await fetchAuthSession();
      return user;
    } catch (error) {
      console.error('Error getting current session:', error);
      throw error;
    }
  }

  async forceRefreshSession () : Promise<any> {
    return preventMultipleConcurrentExecutions('getPractitionerToken', async () => {
      const user = await fetchAuthSession({ forceRefresh: true });
      const idToken = user.tokens?.idToken?.toString();
      return this.getPracAuthToken(idToken, sessionStorage.getItem('providerId'));
    });
  }

  async getPracAuthToken(idToken: string, providerId: string): Promise<any> {
    try {
      const response = await this.http.get(`${environment.practitionerAuthTokenApi}${providerId}`, {
        headers: { "Authorization": idToken }
      }).toPromise();
      sessionStorage.setItem('pracAuthToken', response['pracAuthToken']);
      return response;
    } catch (error) {
      console.error('Error while fetching practitioner auth token', error);
      throw error;
    }
  }

  async signOut(): Promise<void> {
    try {
      await signOut({global:true});
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  }

  async resendSignUpCode(email: string, challenge: string): Promise<void> {
    try {
      await resendSignUpCode({ username: email, options: { clientMetadata: { 'email': email, 'challengeName': challenge }}});
    } catch (error) {
      console.error('Error resending sign up code:', error);
      throw error;
    }
  }
}
