
<div class="row justify-content-center provider-container">
    <div class="card col-sm-12 col-md-8 col-lg-8">
        <div class="card-header">
            <div class="card-title">
                <span class="select-providers">
                    {{ 'login.select_provider' | translate }}
                </span>
                <div class="has-search">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="round-input" #providerSearch (keyup)="onKey(providerSearch.value)">
                </div>
            </div>
        </div>
        <div class="card-content">
            <div id="providers" fxLayout.xs="column" >
                <div class="provider-wrapper" fxFlex.xs="100%" *ngFor="let provider of filterdProvidersList" >
                    <div class="provider-card" (click)="onProviderCardClick(provider)">
                        <img src="{{provider.logoUrl}}" class="img-fluid" alt="{{provider.name}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>