// import moment from 'moment-timezone';

// export class DateTimeUtilities {
//     static convertTZ(date: any, tzString: string) {
//         console.log("date:" + date);
//         console.log("timezone:" + tzString);
//         console.log("Is USA timezone:" + tzString.toLowerCase().includes("america"));
//         console.log("date:" + typeof date === "string");
//         console.log("type of date:" + typeof date);
//         console.log("EU formatted date:" + new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })));
//         console.log('usa formatted date' + new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })));
//         console.log('usa formatted date 1' + new Date(date).toLocaleString("en-US", { timeZone: tzString, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }));
//         console.log('usa formatted date 2' + new Date(date).toLocaleString("en-US", { timeZone: tzString }));
//         console.log('usa formatted date 3' + new Date(date.toString()).toLocaleString("en-US", { timeZone: tzString, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }));

//         return tzString.toLowerCase().includes("america") ?
//             new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })) :
//             new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
//     }
// }

export class DateTimeUtilities {
    static convertTZ(date: any , tzString: string) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
}