import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { PatientStateService } from '../services/patient-state.service';

@Injectable({ providedIn: 'root' })
export class PatientAuthResolver implements Resolve<string> {
  constructor(private authService: AuthService, private router: Router, public activatedRoute: ActivatedRoute) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string> | Observable<any> {
    const patientAccessToken = this.router.getCurrentNavigation().extras.state?.patientAccessToken ?? null;
    return this.authService.setCurrentPatientId(route.params.patientId, patientAccessToken);
  }
}
