<div class="interval-calendar-container">
    <div class="year text-center">
        <b class="flex-row">
            <mat-icon (click)="monthBack()" class="orange-text cursor-pointer"> keyboard_arrow_left</mat-icon>
            <span class="year-text">{{currentDate.format('MMM')}} {{currentDate.year()}}</span>
            <mat-icon (click)="monthForward()" class="orange-text cursor-pointer"> keyboard_arrow_right</mat-icon>
        </b>
    </div>
    <div class="calendar-container flex-column">
        <div class="months flex-row space-between">
            <ng-container>
                <div *ngFor="let interval of intervals" class="month-class flex-column">
                    <div *ngIf="setDropdownbyStartDate(interval)" class="mt-minus-28">
                        <mat-icon class="orange-text">arrow_drop_down</mat-icon>
                    </div>
                    <div>{{interval}}</div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="events-container flex-row space-between padding-side-10">
        <div *ngFor="let interval of intervals" class="event-class margin-left-5"[ngClass]="{'current-week': setCurrentIntervalClass(interval) }" >
            <div *ngIf="getActivitiesForWeek(interval).length == 0" class="width-24"></div>
            <div (click)="showInfo(activity)" *ngFor="let activity of getActivitiesForWeek(interval);" class="flex-column"
                [matTooltip]="displayTooltipName(activity)" matTooltipPosition="below">
                <div class="vl margin-left-12"></div>
                <div class="image-container" [ngClass]="{'external-orders': activity.source?.startsWith('external'), 'prev-activities': activity.datePassed}" > 
                    <img class="cursor-pointer" src='{{defaultIconPath}}{{typesMap.get(activity?.type)}}'> 
                    <i *ngIf='editable' (click)="openDetelePopup($event, activity)" class="fa fa-trash"></i>
                    <i *ngIf='activity.statusNew === "DONE"' class="fa fa-check status completed"></i>
                    <i *ngIf='activity.statusNew === "PLANNED"' class="fa fa-calendar-alt status scheduled"></i>
                    <i *ngIf='activity.statusNew === "OVERDUE"' class="fa fa-times status overdue"></i>
                    <i *ngIf='activity.statusNew === "PENDING" || !activity.statusNew' class="fa fa-clock status pending"></i>
                </div>
            </div>
        </div>
    </div>

    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item *ngFor='let activityType of activityTypes' (click)="triggerEmit(activityType)">
                <img class="activity-icon" src='{{defaultIconPath}}{{typesMap.get(activityType?.type)}}'>
                <span>{{activityType?.name | translatable:lang}}</span>
            </button>
        </ng-template>
    </mat-menu>
</div>
