import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { StateService } from 'src/app/core/services/state.service';
import { ChartConfiguration, LegendItem } from 'chart.js';
import { MedicalContent } from 'src/app/core/model/common.interface';
import * as chartsData from '../../../../shared/data/chartsjs';
import { BaseChartDirective } from 'ng2-charts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss']
})
export class LineGraphComponent implements OnInit, OnChanges {


  @Input() chartDatas: ChartConfiguration<'line'>['data'];
  @Input() type: string;

  medicalContents: MedicalContent;
  public chartOptions = {};

  @ViewChild(BaseChartDirective) private _chart: BaseChartDirective;

  constructor(private stateService: StateService) {
    this.stateService.medicalContentObs.subscribe((data) => {
      this.medicalContents = data;
    });
    // console.log(data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type?.currentValue !== changes.type?.previousValue) {
      this.chartOptions = chartsData.lineChartOptions(this.medicalContents.formatting.shortDateFormat);
    }
  }

  toggleLine(datasetIndex: number): void {
    const dataset = this.chartDatas.datasets[datasetIndex];
    dataset.hidden = !dataset.hidden;
    this._chart.update();
  }

  ngOnInit(): void {
    // if(this.data) {
    //   this.chartDatas = this.data.chartDatas;
    //   this.type = this.data.type;
    //   this.chartOptions = chartsData.lineChartOptions(this.medicalContents.formatting.shortDateFormat);
    // }
  }

  calculateWidth(): string {
    if(window.innerWidth > 1000) {
      const colCount = Math.floor(this.chartDatas.datasets.length / 5) + 1;
      return `${100 - (colCount* 17)-3 }%`;
    }
    else {
      return `100%`;
    }
  }

  calculateLeftLegendWidth(): string {
    const colCount = Math.floor(this.chartDatas.datasets.length / 5) + 1;
    return `${colCount * 17 }%`;
  }

}
