export const eventIconsMap = new Map<string, string>();
eventIconsMap.set('CLINIC_VISIT', 'clinic/clinic.png');
eventIconsMap.set('ConsultActivity', 'clinic/clinic.png');
eventIconsMap.set('CONSULTACTIVITY', 'clinic/clinic.png');
eventIconsMap.set('LAB', 'labs/labs.png');
eventIconsMap.set('LabActivity', 'labs/labs.png');
eventIconsMap.set('LABACTIVITY', 'labs/labs.png');
eventIconsMap.set('RADIOLOGY', 'radiology/radiology.png');
eventIconsMap.set('Radiology', 'radiology/radiology.png');
eventIconsMap.set('PATHOLOGY', 'pathology/pathology.png');
eventIconsMap.set('ENDOSCOPY', 'endoscopy/endoscopy.png');
eventIconsMap.set('Endoscopy', 'endoscopy/endoscopy.png');
eventIconsMap.set('ECARE', 'ecare/ecare.png');
eventIconsMap.set('Ecare', 'ecare/ecare.png');
eventIconsMap.set('MY_COACH', 'my-coach/my-coach.png');
eventIconsMap.set('SURGERY', 'surgery/surgery@2x.png');
eventIconsMap.set('POST_SURGERY', 'post-sugnery-self-assessment/post-surgery-self-assessment.png');
eventIconsMap.set('MY_MENU', 'my-menu/my-menu.png');

export const monthsMap = new Map();
monthsMap.set('Jan', 1);
monthsMap.set('Feb', 2);
monthsMap.set('Mar', 3);
monthsMap.set('Apr', 4);
monthsMap.set('May', 5);
monthsMap.set('Jun', 6);
monthsMap.set('Jul', 7);
monthsMap.set('Aug', 8);
monthsMap.set('Sep', 9);
monthsMap.set('Oct', 10);
monthsMap.set('Nov', 11);
monthsMap.set('Dec', 12);

export const eventTypes = ['Clinic_visit', 'Labs', 'Radiology', 'Pathology', 'Endoscopy', 'eCare', 'My Coach', 'Surgery', 'Post Surgnery Self Assessment', 'My Menu'];
