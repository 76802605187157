export class DhMatDateFormat {

    constructor() {
    }
    dateFormat: string;
    get display() {
        return {
            monthYearLabel: 'MMM YYYY',
            dateInput: this.dateFormat
        }
    }

    get parse() {
        return {
            dateInput: 'DD/MM/YYYY',
        }
    }
}