import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Activity, IEventActivityType, Patient } from '../model/patient.interface';

@Injectable()
export class PatientStateService {
  public patientIdSubject$: BehaviorSubject<string>;
  public diseaseIdSubject$: BehaviorSubject<string>;
  public treatmentIdSubject$: BehaviorSubject<string>;
  public treatmentInstanceIdSubject$: BehaviorSubject<string>;
  public activeAddonSubject$: BehaviorSubject<string>;
  public treatmentStartDateSubject$: BehaviorSubject<string>;
  public activityTypes$: BehaviorSubject<IEventActivityType[]>;
  public carePathWayActivities$: BehaviorSubject<Activity[]>;
  public diseaseDetail$: BehaviorSubject<string>;
  private patientDetail$: BehaviorSubject<Patient>;
  public patientDetailObs: Observable<Patient>;

  constructor() {
    this.resetState();
   }

  resetState(): void {
    this.patientIdSubject$ = new BehaviorSubject<string>(null);
    this.diseaseIdSubject$ = new BehaviorSubject<string>(null);
    this.diseaseDetail$ = new BehaviorSubject<any>({});
    this.treatmentIdSubject$ = new BehaviorSubject<string>(null);
    this.treatmentInstanceIdSubject$ = new BehaviorSubject<string>(null);
    this.activeAddonSubject$ = new BehaviorSubject<string>(null);
    this.treatmentStartDateSubject$ = new BehaviorSubject<string>(null);
    this.activityTypes$ = new BehaviorSubject<IEventActivityType[]>([]);
    this.carePathWayActivities$ = new BehaviorSubject<Activity[]>([]);
    this.patientDetail$ = new BehaviorSubject<Patient>(null);
    this.patientDetailObs = this.patientDetail$.asObservable();
  }

     /* Getters */
  public get patientId(): string {
    return this.patientIdSubject$.getValue();
  }

  public get diseaseId(): string {
    return this.diseaseIdSubject$.getValue();
  }

  public get diseaseDetail(): any {
    return this.diseaseDetail$.getValue();
  }

  public get treatmentId(): string {
    return this.treatmentIdSubject$.getValue();
  }

  public get treatmentInstanceId(): string {
    return this.treatmentInstanceIdSubject$.getValue();
  }

  public get treatmentStartDate(): string {
    return this.treatmentStartDateSubject$.getValue();
  }

  public get activeAddonId(): string {
    return this.activeAddonSubject$.getValue();
  }

  public get activityTypes(): IEventActivityType[] {
    return this.activityTypes$.getValue();
  }

  public get carePathwayActivities(): Activity[] {
    return this.carePathWayActivities$.getValue();
  }
  public set setTreatmentId(treatmentId: string) {
    this.treatmentIdSubject$.next(treatmentId);
  }

  public set setTreatmentInstanceId(treatmentInstanceId: string) {
    this.treatmentInstanceIdSubject$.next(treatmentInstanceId);
  }

  public set setDiseaseId(diseaseId: string) {
    this.diseaseIdSubject$.next(diseaseId);
  }

  public set setDiseaseDetail(diseaseDetail: any) {
    this.diseaseDetail$.next(diseaseDetail);
  }

  public set setPatientId(patientId: string) {
    this.patientIdSubject$.next(patientId);
  }


  public set setActiveAddon(addonId: string) {
    this.activeAddonSubject$.next(addonId);
  }

  public set setTreatmentStartDate(startDate: string) {
    this.treatmentStartDateSubject$.next(startDate);
  }

  public set setActivityTypes(activities: IEventActivityType[]) {
    this.activityTypes$.next(activities);
  }

  public set setCarePathwayActivites(activities: Activity[]) {
    this.carePathWayActivities$.next(activities);
  }

  public set setPatientDetail(patientDetail: any) {
    this.patientDetail$.next(patientDetail);
  }
}
