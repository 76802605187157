import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'activate-medication-modal.component',
  templateUrl: 'activate-medication-modal.component.html',
})
export class ActivateMedicationModal {
  constructor(public dialogRef: MatDialogRef<ActivateMedicationModal>,
              @Inject(MAT_DIALOG_DATA) public data) {
  }
}
