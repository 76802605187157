import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { distinct, filter } from 'rxjs/operators';
import { DELETE_PATIENT } from 'src/app/core/constants/graphql.query.constants';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-delete-patient',
  templateUrl: './delete-patient.component.html',
  styleUrls: ['./delete-patient.component.scss']
})
export class DeletePatientComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<DeletePatientComponent>,
    private apollo: Apollo, private messageService: MessageService, private communicationService: CommunicationService, private translate: TranslateService) { }

  ngOnInit(): void {
  }



  deletePatient() {
    const deletePatientObs = this.apollo.mutate({
      mutation: DELETE_PATIENT,
      variables: {
        patientId: this.data['patientId']
      }
    });
    deletePatientObs.subscribe((data) => {
      this.messageService.showSuccessMessage(this.translate.instant('create_patient.delete_patient_success_text'));
      this.dialogRef.close(true);
    }, (err) => {
      this.messageService.showGenericError();
    })

  }
}
