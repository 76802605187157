<div>
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{'questionnaire_response.questionnaire_title' | translate}}</th>
            <td mat-cell *matCellDef="let element">

                <ng-container *ngIf="questionnaireType == ECARE">
                    {{'questionnaire_response.my_health_text' | translate}} {{diseaseName}}
                </ng-container>
                <ng-container *ngIf="questionnaireType != ECARE">
                    {{buildQuestionnaireName(element) | translatable:lang}}
                </ng-container>

            </td>
        </ng-container>
        <ng-container  matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef>{{'questionnaire_response.result_title' | translate}}</th>
            <td mat-cell  *matCellDef="let element">
                <div *ngIf="isScorable(element.questionnaireId)">
                    <div [class.low-score-notification] = "badScore(element)">
                        <i class="fa fa-exclamation-triangle" *ngIf="badScore(element)"></i>
                        {{ getScore(element) }}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>{{'questionnaire_response.date_title' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.dateCompleted}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row class="cursor-pointer questionnaire-row" *matRowDef="let row; columns: columns;"
            (click)="openModal(row.responseId, row.questionnaireId)"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="4">{{'questionnaire_response.no_data' | translate}}</td>
        </tr>
    </table>
</div>