<app-loader></app-loader>
<div class="container auth-container" *ngIf="!showProviderSelection">
  <div>
    <div>
      <img src="../../../assets/images/logo/dear_logo.png" alt="DEARHealth">
    </div>
    <form [formGroup]="form">
      <div>
        <mat-label>{{'passwordLess.sms_code' | translate}}</mat-label>
      </div>
      <div class="field">
        <mat-form-field appearance="outline">
          <input #smsCode type="text" autocomplete="disabled" autofocus matInput formControlName="smsCode" placeholder="{{'passwordLess.enter_your_sms_code' | translate}}" />
          <mat-error *ngIf="form.get('smsCode')?.hasError('pattern')">{{'passwordLess.Invalid_mail_code' |
            translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="text-center">
        <button mat-raised-button
          class="mat-focus-indicator btn btn-primary dh-login shadow-none mat-flat-button mat-button-base"
          (click)="verifySMSCode()">{{'passwordLess.verify' | translate}}</button>
      </div>
    </form>
  </div>
</div>
<div *ngIf="showProviderSelection" class="container">
  <app-provider-selection [providers]="availableProviders"
    (providerSelected)="handleProviderSelected($event)"></app-provider-selection>
</div>