import { Component, EventEmitter, Inject, Output, OnInit, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivityDetails, Translatable } from '../../../core/model/patient.interface';
import { PatientService } from '../../../core/services/patient.service';
import { LangType, StateService } from '../../../core/services/state.service';
import { BehaviorSubject } from 'rxjs';
import { distinct, filter } from 'rxjs/operators';
import { QuestionnaireResponseModalComponent } from '../questionnaire-response/components/questionnaire-response-modal/questionnaire-response-modal.component';
import { ECARE_QUESTIONNAIRE } from '../../../core/constants/questionnaire.constant';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { MedicalContent, MedicalContentNames, Questionnaire, QuestionnaireNames } from 'src/app/core/model/common.interface';

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.scss']
})
export class EventDialogComponent implements OnInit {
  cpActivityId: string;
  questionnaireCompleted: boolean = false;
  activity = new BehaviorSubject<ActivityDetails>(null);
  @Output() public closeOn = new EventEmitter();
  questionnaireNames: QuestionnaireNames;
  questionnaireData: Array<Questionnaire>
  medicalContentNames: MedicalContentNames;
  medicalContent: MedicalContent;
  activityName: string
  lang: LangType;

  constructor(public dialogRef: MatDialogRef<EventDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private patientService: PatientService, private stateService: StateService,
    private patientStateService: PatientStateService, public dialog: MatDialog,
    private viewRef: ViewContainerRef) {
    this.cpActivityId = data.activityId;
    this.questionnaireCompleted = data.questionnaireCompleted;
    this.medicalContentNames = this.stateService.getMedicalContentNames;
    this.questionnaireNames = this.stateService.getMedicalContentNames.questionnaires;
    this.questionnaireData = this.stateService.getMedicalContent.questionnaires;
  }

  ngOnInit(): void {
    this.stateService.lang.subscribe(res => {
      this.lang = res;
    });
    this.getActivityDetails();
    this.medicalContent = this.stateService.getMedicalContent;
  }

  getActivityDetails(): any {
    this.patientStateService.patientIdSubject$.pipe(filter(id => !!id), distinct()).subscribe(patientId => {
      if (patientId) {
        this.patientService.getActivityDetails(patientId, this.cpActivityId).subscribe(activityDetail => {
          if (activityDetail.status == null) {
            activityDetail.status = 'UPCOMING'
          }

          if (this.medicalContentNames?.activities[activityDetail['activityDefinitionId']]?.title) {
            activityDetail.name = this.medicalContentNames.activities[activityDetail['activityDefinitionId']]?.title;
          }
          this.activity.next(activityDetail);

        });
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  getQuestionnaire(questionnaireId: string){
    return this.questionnaireData.filter(questionnaire => questionnaire.id ==questionnaireId)[0];
  }

  openQuestionnaire(): void {
    let responseId = null;
    let questionnaireId = null;
    this.activity.subscribe(a => {
      responseId = a.responseId;
      questionnaireId = a.questionnaireId
    })
    const modalRef = this.dialog.open(QuestionnaireResponseModalComponent, {
      viewContainerRef: this.viewRef,
      width: `1000px`,
      height: '700px',
      data: {
        'sourceId': responseId,
        'type': ECARE_QUESTIONNAIRE,
        'questionnaireId': questionnaireId,
        'questionnaireName': this.questionnaireNames[questionnaireId].name,
        'questionnaireData': this.getQuestionnaire(questionnaireId)
      }
    });
  }
}
