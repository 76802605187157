import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SHOULD_SKIP_LOADER } from './loader.interceptor'
import { environment } from '../../../environments/environment';
import {
  ActivateScenarioResponse,
    Activity,
    ActivityDetails,
    AddActivityRequest,
    AddActivityResponse,
    HealthAssessment, QuestionnaireResponseComparison,
    QuestionnaireResponseDto,
    UpdateActivityRequest
} from '../model/patient.interface';
import { AuthService } from './auth.service';
import { StateService } from '../../core/services/state.service';


@Injectable({
  providedIn: 'root'
})
export class PatientService {
    endpoint = {
        patient(providerId: string, patientId: string): string {
            return environment.endpoint + environment.services.patient.replace('{providerId}', providerId).replace('{patientId}', patientId);
        },
        patient_overview_remove_notification(providerId: string, patientId: string): string {
            return environment.endpoint + environment.services.patient_overview_remove_notification.replace('{providerId}', providerId)
                .replace('{patientId}', patientId);
        },
        disease(providerId: string): string {
            return environment.endpoint + environment.services.disease.replace('{providerId}', providerId);
        },
        scenario(providerId: string, patientId: string, diseaseId: string): string {
            return environment.endpoint + environment.services.treatment_plan.replace('{providerId}', providerId).replace(/{patientId}/g,
              patientId) + `/${diseaseId}`;
        },
        getAddonUrl(providerId: string, diseaseId: string, scenarioId: string, addonIds: string ): string {
          let url= environment.endpoint+ environment.services.addons_details.replace('{providerId}', providerId).replace('{diseaseId}', diseaseId).replace('{scenarioId}', scenarioId);
          return url+ '?addon_ids='+ addonIds;
        },
        
        care_pathway(providerId: string, patientId: string): string {
            return environment.endpoint + environment.services.care_pathway.replace('{providerId}', providerId).replace(/{patientId}/g,
                patientId);
        },
        activity_details(providerId: string, patientId: string, cpActivityId: string): string {
            return environment.endpoint + environment.services.care_pathway_activity_details
                .replace('{providerId}', providerId)
                .replace('{patientId}', patientId)
                .replace('{cpActivityId}', cpActivityId);
        },
       
        health_assesment(providerId: string, patientId: string, diseaseId: string): string {
            return environment.endpoint + environment.services.health_assesment.replace('{providerId}', providerId).replace('{patientId}',
                patientId).replace('{diseaseId}', diseaseId);
        },
        health_assesment_history(providerId: string, patientId: string, diseaseId: string, from_date: string): string {
            return environment.endpoint + environment.services.health_assesment_history.replace('{providerId}', providerId).replace('{patientId}',
                patientId).replace('{diseaseId}', diseaseId) + `?from_date=${from_date}`;
        },
        treatment_plan(providerId: string, patientId: string): string {
            return environment.endpoint + environment.services.treatment_plan.replace('{providerId}', providerId).replace('{patientId}',
                patientId);
        },
        questionnaire(providerId: string): string {
            return environment.endpoint + environment.services.questionnaire.replace('{providerId}', providerId);
        },
        questionnaire_res(providerId: string, patientId: string, questionnaireType?: string): string {
            let url = environment.endpoint + environment.services.questionnaire_res.replace('{providerId}', providerId).replace('{patientId}',
                patientId);
            if (questionnaireType) {
                url = `${url}?type_filter=${questionnaireType}`;
            }
            return url;
        },
        questionnaire_resp_comparison(providerId: string, patientId: string, sourceId: string,
                                      previousDate?: string, sourceDate?: string): string {
            let url = environment.endpoint + environment.services.questionnaire_res.replace('{providerId}', providerId).replace('{patientId}',
                patientId);
            url = `${url}/${sourceId}/comparison`;
            if (previousDate) {
                url = `${url}?previous_date=${previousDate}`;
            }
            if (sourceDate) {
                url = `${url}&source_date=${sourceDate}`
            }
            return url;
        },
        questionnaire_resp_dates(providerId: string, patientId: string, questionnaireId: string): string {
            let url = environment.endpoint + environment.services.questionnaire_res.replace('{providerId}', providerId).replace('{patientId}',
                patientId);
            url = `${url}/dates?questionnaire_id=${questionnaireId}`;
            return url;
        },
        recommendations(providerId: string, patientId: string): string {
            return environment.endpoint + environment.services.recommendations.replace('{providerId}', providerId).replace('{patientId}',
                patientId);
        },
        open_recommendations(providerId: string, patientId: string): string {
            return this.recommendations(providerId, patientId);
        },
        make_recommendation(providerId: string, patientId: string, diseaseId: string): string {
            return this.recommendations(providerId, patientId) + `?disease_id=${diseaseId}`;
        },
        recommendation_action(providerId: string, patientId: string, recommendationId: string): string {
            return this.recommendations(providerId, patientId) + '/' + recommendationId;
        },
        
        update_actiity(providerId: string, patientId: string, cpActivityId?: string): string {
            const url = environment.endpoint + environment.services.update_activity.replace('{providerId}', providerId).replace('{patientId}', patientId);
            return cpActivityId?.length > 0 ? `${url}/${cpActivityId}` : url;
        }
  };

  constructor(private http: HttpClient, private authService: AuthService, private stateService: StateService,
              private injector: Injector) {
  }

  getPatient(patientId: string): any {
    const authService = this.injector.get(AuthService);
    return this.http.get(
      this.endpoint.patient(this.stateService.providerId, patientId));
  }

  putPatientGeneralInfo(patientId: string, data: any): any {
    return this.http.put(this.endpoint.patient(this.stateService.providerId, patientId), data)
  }


  getDiseaseConfig(diseaseId: string): Observable<any> {
    return this.http.get(this.endpoint.disease(this.stateService.providerId) + `/${diseaseId}/config`);
  }

  getTreatmentHistory(patientId: string, diseaseId: string): Observable<any> {
    return this.http.get<any>(this.endpoint.treatment_plan(this.stateService.providerId, patientId) + `?disease_id=${diseaseId}`);
  }

  activateAddOn(patientId: string, diseaseId: string, payload: any): Observable<ActivateScenarioResponse> {
    return this.http.put<ActivateScenarioResponse>(this.endpoint.scenario(this.stateService.providerId, patientId, diseaseId) + `/addons/`,
     payload);
  }


  getCarePathway(patientId: string, filter?: Map<string, string>): Observable<Activity[]> {
    if (filter) {
      const headers = new HttpHeaders().set('x-dh-waiting-for-filter', 'true');
      let params = new HttpParams();
      filter.forEach((value, key) => params = params.set(key, value));
      return this.http.get<Activity[]>(this.endpoint.care_pathway(this.stateService.providerId, patientId), {headers, params});
    } else {
      return this.http.get<Activity[]>(this.endpoint.care_pathway(this.stateService.providerId, patientId));
    }
  }

  getActivityDetails(patientId: string, cpActivityId: string): Observable<ActivityDetails> {
    return this.http.get<ActivityDetails>(this.endpoint.activity_details(this.stateService.providerId, patientId, cpActivityId));
  }

  /* Questionnaire */
  getQuestionnaire(questionId: string): Observable<any> {
    // const params = new HttpParams().set('questionId', questionId);
    return this.http.get(this.endpoint.questionnaire(this.stateService.providerId) + `/${questionId}`);
  }

  postQuestionnaire(patientId: string, payload: string): Observable<any> {
    return this.http.post(this.endpoint.questionnaire_res(this.stateService.providerId, patientId), payload);
  }

  postQuestionnaire1(patientId: string, diseaseId: string, payload: string): Observable<any> {
    return this.http.post(this.endpoint.make_recommendation(this.stateService.providerId, patientId, diseaseId), payload);
  }

  getRecommendations(patientId: string, disable_loader: boolean =false): Observable<any> {
    return this.http.get(this.endpoint.open_recommendations(this.stateService.providerId, patientId),
      {
         context: new HttpContext().set(SHOULD_SKIP_LOADER, disable_loader)
      });
  }

  acceptRejectRecommendation(patientId: string, recommendationId: string, payload: string): Observable<any> {
    return this.http.patch(this.endpoint.recommendation_action(this.stateService.providerId, patientId, recommendationId), payload);
  }

  removeNotification(patientId: string, notificationId: string = null): Observable<any> {
    if (notificationId) {
      return this.http.delete(this.endpoint.patient_overview_remove_notification(this.stateService.providerId, patientId) + `/${notificationId}`);
    } else {
      return this.http.delete(this.endpoint.patient_overview_remove_notification(this.stateService.providerId, patientId));
    }
  }

  getHealthAssessment(providerId: string, patientId: string, diseaseId: string): Observable<HealthAssessment> {
    return this.http.get<HealthAssessment>(this.endpoint.health_assesment(providerId, patientId, diseaseId));
  }

  getHealthAssessmentHistory(providerId: string, patientId: string, diseaseId: string, fromDate: string): Observable<HealthAssessment[]> {
    return this.http.get<HealthAssessment[]>(this.endpoint.health_assesment_history(providerId, patientId, diseaseId, fromDate));
  }

  addActivity(providerId: string, patientId: string, payload: AddActivityRequest): Observable<AddActivityResponse> {
    return this.http.post<AddActivityResponse>(this.endpoint.update_actiity(providerId, patientId), payload);
  }
  editActivity(providerId: string, patientId: string, activityId: string, payload: UpdateActivityRequest): Observable<any> {
    return this.http.patch(this.endpoint.update_actiity(providerId, patientId, activityId), payload);
  }
  deleteActivity(providerId: string, patientId: string, activityId: string): Observable<any> {
    return this.http.delete(this.endpoint.update_actiity(providerId, patientId, activityId));
  }
  getQuestionnaireResponse(patientId: string, type: string): Observable<QuestionnaireResponseDto[]> {
      return this.http.get<QuestionnaireResponseDto[]>(this.endpoint.questionnaire_res(this.stateService.providerId, patientId, type))
  }

    getQuestionnaireResponseComparison(patientId: string, sourceId: string,
                                       previousDate?: string, sourceDate?: string): Observable<QuestionnaireResponseComparison> {
        return this.http.get<QuestionnaireResponseComparison>(this.endpoint.questionnaire_resp_comparison(
            this.stateService.providerId, patientId, sourceId, previousDate, sourceDate
        ));
    }

    getQuestionnaireResponseDates(patientId: string, questionnaireId: string): Observable<string[]> {
        return this.http.get<string[]>(this.endpoint.questionnaire_resp_dates(
            this.stateService.providerId, patientId, questionnaireId));
    }
    getProfileFoms(documentName: string): Observable<any> {
        return this.http.get(`${environment.medicalContentEndpoint}/providers/${sessionStorage.getItem('providerId')}/${documentName}`);
    }
}
