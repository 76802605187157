<!-- <app-header></app-header> -->
<ng-container *ngIf="!showMaintenancePage">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</ng-container>

<div *ngIf="showMaintenancePage" class="maintenance-page-wrapper">
    <div>
        <div>
            <img src="assets/images/logo/maintenance.png" alt="maintenance page">
        </div>
        <div class="maintenance-text">{{'maintenance.service_update_text' | translate}}</div>
        <div class="info_text">
            <div>{{'maintenance.info_text1' | translate}}</div>
            <div>{{'maintenance.info_text2' | translate}}</div>
        </div>
        <div class="logo-container">
            <img src="assets/images/logo/logo.png" alt="DEARHealth">
        </div>
        <div class="footer-container">
            <app-footer></app-footer>
        </div>
    </div>
</div>