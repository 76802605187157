import { Component, Input } from "@angular/core";

@Component({
    selector: 'form-field',
    template: `
        <ng-container *ngIf="!isEdit || isEditScreen">
            <div class="field-display">
                <div class="field-label"> {{ label }} </div>
                <div class="field-value"> {{ value }} </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isEdit && !isEditScreen">
            <ng-content></ng-content>
        </ng-container>
    `,
    host: {'class': 'field'},
    styleUrls: ['./card.form.field.component.scss']
})
export class FormField {
    @Input() isEdit?: boolean = true;
    @Input() isEditScreen? : boolean = false;
    @Input() label: string;
    @Input() value: string;
}