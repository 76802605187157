<div class="row" *ngIf="rowsChartData && rowsChartData.length > 0">
    <div class="card col">
        <div class="pb-0 pt-4 pl-3">
            <div class="card-title m-0 pl-1 float-left">
                {{title}}
            </div>
            <div class="m-0 float-right" style="width: 35%" *ngIf="type === 'bar'">
                <span class="float-left mr-4"><div class="square past"></div> {{'questionnaire_response.past_results' | translate }}</span>
                <span class="float-left ml-4"><div class="square latest"></div> {{'questionnaire_response.last_results' | translate }}</span>
                <span class="float-right mr-4"><a class="button-link" (click)="setTab()" >Details</a></span>
            </div>

            <!-- <div class="m-0 float-right" style="width: 4.5%" *ngIf="type === 'line'">
                <i class="fa fa-ellipsis-h" aria-hidden="true" (click)="openPopup()"></i>
            </div> -->

        </div>
        <div class="pb-4 pt-4">
            <ng-container *ngIf="type === 'bar'">
                <app-bar-graph *ngFor="let data of rowsChartData" [chartDatas]="data" class="graph" [ngClass]="{'graph-width': data.datasets[0].data.length < 2}"
                    [type]="type">
                </app-bar-graph>
            </ng-container>

            <ng-container *ngIf="type === 'line'">
                <app-line-graph *ngFor="let data of rowsChartData"  [customLegend]="true" [chartDatas]="data" class="line-graph" [ngClass]="{'line-graph-width': data.datasets.length == 1 }"
                    [type]="type">
                </app-line-graph>
            </ng-container>
        </div>
    </div>
</div>