<div class="card col-md-12 ">
    <div class="container">
        <mat-tab-group [(selectedIndex)]="selectedIndexBinding" mat-align-tabs="center" class="questionnaire-tab">
            <mat-tab label="{{'questionnaire_response.ha_tab_title' | translate }}">
                <app-questionnaire-response-list [questionnaireType]="'ECARE'"></app-questionnaire-response-list>
            </mat-tab>
            <mat-tab label="{{'questionnaire_response.other_tab_title' | translate}}">
                <ng-template matTabContent>
                    <app-questionnaire-response-list [questionnaireType]="'!RECOMMENDER%26%26!ECARE'"></app-questionnaire-response-list>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
