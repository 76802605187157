<div class="card col-md-12 treatment-plan" *ngIf="treatmentPlans.length > 0">
    <div class="card-header">
        <div class="card-title">
          {{'treatment_plan_title' | translate}}
        </div>
    </div>
    <div class="card-content treatment-plan">

        <p class="active-title">{{mainActiveScenarioTitle}} <i *ngIf="otherActiveScenarioTitles.length > 0" class="fa fa-info-circle" matTooltipClass="my-custom-tooltip" matTooltip="{{otherActiveScenarioTitles.join('\r\n')}}" matTooltipPosition="right"></i></p>

        <mat-accordion multi [togglePosition]="'before'">
            <mat-expansion-panel  *ngFor="let treatmentPlan of treatmentPlans" (afterExpand)="afterExpandHandler()">
              <mat-expansion-panel-header (click)='afterExpandHeaderHandler($event)'>
                <mat-panel-title>
                  {{treatmentPlan.title | translatable:lang}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row" *ngFor="let scenario of treatmentPlan.scenarios">
                <div class="col-md-8">
                    <div class="treatment-induction" (click)='openMultiScenarioView(scenario, false)'>
                        <mat-slide-toggle class="treatment-toggle" [(ngModel)]="scenario.active">
                            {{medicalContentNames?.scenarios[scenario.id].name | translatable:lang }}
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="col-md-4">
                    <button mat-stroked-button *ngIf="scenario.addons.length > 0" [disabled]="!scenario.active" (click)="addOnAction(scenario)" [ngClass]="scenario.active === true ? 'addon-button-activated' : 'addon-button'">
                        {{'multi_scenario.medication_label'| translate}}
                    </button>
                </div>
            </div>
          
            </mat-expansion-panel>
          </mat-accordion>
    </div>
</div>