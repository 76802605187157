import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog'
import { DialogResponse } from 'src/app/core/constants/dialog_response.constant'

@Component({
  selector: 'care-pathway-reminded-modal.component',
  templateUrl: 'care-pathway-reminded-modal.component.html',
})
export class CarePathwayRemindedModal {
  constructor(public dialogRef: MatDialogRef<CarePathwayRemindedModal>) { }

  closeConfirm() {
    this.dialogRef.close(DialogResponse.CONFIRM);
  }
}