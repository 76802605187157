<div class="question-response">
  <mat-dialog-content class="response-content">
    <div>
      <h2>{{ questionnaireName | translatable: lang}}</h2>
      <table class="mat-table cdk-table" role="table">
        <thead role="rowgroup">
          <tr class="border-bottom border-top">
            <th class="table-control">
              <mat-slide-toggle class="pb-5" [color]="'primary'" *ngIf="previousResponse != null"
                [checked]="compareEnabled" (change)="enableComparison($event, currentResponse?.questionnaireId)">
                {{ "questionnaire_response.compare_title" | translate }}
              </mat-slide-toggle>
            </th>
            <th class='px-4 pt-2 pb-3 table-control'>
              {{"questionnaire_response.current_text" | translate}} <br />
              <ng-container>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="{{'questionnaire_response.select_compare_date_msg' | translate }}" id="source_date_select" (selectionChange)="selectEcareDate($event)" [value]="sourceDate">
                    <mat-option *ngFor="let date of ecareDates" [value]="date">
                      {{ date | localdate: medicalContent.timeZone : medicalContent.formatting.dateTimeFormat}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </th>
            <th class="px-4 pt-2 pb-3 table-control" *ngIf="compareEnabled">
              {{"questionnaire_response.previous_text" | translate}} <br />
              <mat-form-field appearance="outline">
                <mat-select placeholder="{{'questionnaire_response.select_compare_date_msg' | translate}}"id="compare_date_select" (selectionChange)="selectEcareDate($event)" [value]="compareDate">
                  <mat-option *ngFor="let date of ecareDates" [value]="date">
                    {{ date | localdate: medicalContent.timeZone : medicalContent.formatting.dateTimeFormat}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </th>
          </tr>
        </thead>
        <tr class="border-bottom">
            <th class="section-header">
              {{ "total" | uppercase | translate}}
            </th>
            <th class="p-2 px-4" *ngIf="isScorable() && currentResponse">
              {{getQuestionnaireScore(currentResponse)}}
            </th>
            <td class="p-2 px-4" *ngIf="isScorable() && compareEnabled && previousResponse" >
               {{getQuestionnaireScore(previousResponse)}}
            </td>
        </tr>
        <tr><td class="py-2"></td></tr>
        <ng-container *ngFor="let component of comparison">
          <tr class="border-bottom border-top" >
            <th class="section-header">
              {{ component.current?.component.name | translatable: lang | uppercase }}
            </th>
            <td class='p-2 px-4' *ngIf="component.current">
              {{ getComponentScore(component.current) }}
            </td>
            <td *ngIf="compareEnabled and component.compare" class="p-2 px-4">
              {{ getComponentScore(component.compare) }}
            </td>
          </tr>

          <ng-container *ngFor="let response of component.responses">
            <tr class="border-bottom">
              <ng-container >
              <td class='p-2 pr-5'>
                <div [outerHTML]="response.current.questionText | translatable: lang"></div>
              </td>



              <td class='p-2 px-4'>
                
                <div [ngSwitch]="response.current.type">
                  <div *ngSwitchCase="
                  [
                    'SWITCH',
                    'MULTIPLE_CHOICE_SINGLE_SELECT',
                    'SELECT_ONE_BUTTON',
                    'DROP_DOWN_LIST',
                    'SCROLLER_TEXT',
                    'MULTIPLE_CHOICE_MULTI_SELECT',
                    'SLIDER'
                  ].includes(response.current.type)
                    ? response.current.type
                    : ''
                ">
                    {{ getResponseOptionsToView(response.current.responseOptions) }}
                  </div>
                  <div *ngSwitchCase="'SCROLLER_SMILE'">
                    <img height="20px" *ngIf="response.current.responseOptions[0]" src="{{ defaultImagePath }}{{
                      smiles.get(
                        response.current.responseOptions[0].optionText.en.trim()
                      )}}"
                    />
                    <span class="pr-1"> {{response.current.responseOptions[0].optionText.en.trim()}}</span>
                  </div>
                  <div *ngSwitchCase="
                  [
                    'TEXT',
                    'DATE_TIME',
                    'INT',
                    'FLOAT',
                    'DATE',
                    'STATIC_TEXT'
                  ].includes(response.current.type)
                    ? response.current.type
                    : ''
                ">
                    {{ response.current.responseText }}
                  </div>
                </div>
              </td>
            </ng-container>
              <td class='px-4' *ngIf="compareEnabled">
                <div [ngSwitch]="response.compare?.type">
                  <div *ngSwitchCase="
                  [
                    'SWITCH',
                    'MULTIPLE_CHOICE_SINGLE_SELECT',
                    'SELECT_ONE_BUTTON',
                    'DROP_DOWN_LIST',
                    'SCROLLER_TEXT',
                    'MULTIPLE_CHOICE_MULTI_SELECT',
                    'SLIDER'
                  ].includes(response.compare?.type)
                    ? response.compare.type
                    : ''
                ">
                    {{ getResponseOptionsToView(response.compare.responseOptions) }}
                  </div>

                  <div *ngSwitchCase="'SCROLLER_SMILE'">
                    <img height="20px" *ngIf="response.compare?.responseOptions[0]" src="{{ defaultImagePath }}{{
                      smiles.get(
                        response.compare.responseOptions[0].optionText.en.trim()
                      )}}"
                    />
                    <span class="pr-1"> {{response.compare.responseOptions[0].optionText.en.trim()}}</span>
                  </div>
                  <div *ngSwitchCase="
                  [
                    'TEXT',
                    'DATE_TIME',
                    'INT',
                    'FLOAT',
                    'DATE',
                    'STATIC_TEXT'
                  ].includes(response.compare?.type)
                    ? response.compare.type
                    : ''
                ">
                    {{ response.compare.responseText }}
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="dh-btn dh-btn-secondary mat-button mat-button-base">{{'close' | translate}}</button>
  </mat-dialog-actions>
</div>