import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { initialize, LDClient, LDFlagSet, LDUser } from 'launchdarkly-js-client-sdk';
import { environment } from '../../../environments/environment';
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';

@Injectable()
export class LaunchDarklyService {
    ldClient: LDClient;
    flags: LDFlagSet;
    flagChange: Subject<Object> = new Subject<Object>();

    constructor() {
        this.flags = { 'show-maintenance-page': false };
        const user: LDUser = { anonymous: true };

        this.ldClient = initialize(environment.launchDarklyId, user, { inspectors: [
            {
                type: "flag-used",
                name: "dd-inspector",
                method: (key: string, detail) => {
                    datadogRum.addFeatureFlagEvaluation(key, detail.value);
                },
            },
        ]});

        this.ldClient.on('change', (flags) => {
            if (flags['show-maintenance-page'] !== undefined) {
                this.flags['show-maintenance-page'] = flags['show-maintenance-page'];
                this.flagChange.next(this.flags);
            }
        });

        this.ldClient.on('ready', () => {
            this.setFlags(['show-maintenance-page']); // Specify only the necessary flag here
        });

        this.ldClient.on('error', (err) => {
            datadogLogs.logger.info('Launch Darkly Error', err);
        });

        this.ldClient.on('failed', (err) => {
            datadogLogs.logger.info('Launch Darkly failed', err);
        });
    }

    setFlags(flagKeys: string[]) {
        this.flags = flagKeys.map(key => this.ldClient.variationDetail(key));
    }
}
