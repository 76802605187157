import { Component, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { DialogResponse } from 'src/app/core/constants/dialog_response.constant'
import { RejectRecommendedScenarioModal } from '../reject-recommended-scenario-modal/reject-recommended-scenario-modal.component'

@Component({
  selector: 'scenario-already-activated-modal.component',
  templateUrl: 'scenario-already-activated-modal.component.html',
})
export class ScenarioAlreadyActivatedModal {
  constructor(public dialogRef: MatDialogRef<ScenarioAlreadyActivatedModal>, public dialog: MatDialog,
              private viewRef: ViewContainerRef) { }

  closeConfirm() {
    this.dialogRef.close(DialogResponse.CONFIRM);
  }

  closeReject() {
    const rejectRecommendedScenarioModal = this.dialog.open(RejectRecommendedScenarioModal, {viewContainerRef: this.viewRef});

    rejectRecommendedScenarioModal.afterClosed().subscribe(result => {
      if (result === DialogResponse.CONFIRM) {
        this.dialogRef.close(DialogResponse.REJECT);
      }
    });
  }
}