export const APP_CONSTANTS = {
    DATE_FORMAT: 'dd-MM-yyyy',
    MM_DD_FORMAT: 'MM-DD',
    DD_MM_FORMAT: 'DD-MM',
    DD_MM_YYYY_FORMAT: 'DD-MM-YYYY',
    YYYY_MM_DD_FORMAT: 'YYYY-MM-DD',
    YYYY_FORMAT: 'YYYY',
    ISO_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
    DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm',
    MOMENT_DATE_TIME_FORMAT: 'DD-MM-yyyy HH:mm',
    DEFAULT_ICON_PATH: '../assets/icons/',
    DEFAULT_IMAGE_PATH: '../assets/images/'
}

export enum NOTIFICATION_TYPES {
    NEW_RESULTS = "NEW_RESULTS",
    WORSENED_RESULTS = "WORSENED_RESULTS",
    LOW_HEALTH_RESULTS = "LOW_HEALTH_RESULTS",
    NOT_WORSENED_RESULTS = "NOT_WORSENED_RESULTS",
    RECOMMENDATION_MADE = "RECOMMENDATION_MADE"
}

export enum LOGIN_STATES {
    SELECT_PROVIDERS = "SELECT_PROVIDERS",
    LOGIN_FORM = "LOGIN_FORM",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",
    CODE_VERIFICATION = "CODE_VERIFICATION",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    FORGOT_PASSWORD_CONFIRM = "FORGOT_PASSWORD_CONFIRM"
}

export enum PatientOverviewSource {
	BY_ASSIGNMENT = "BY_ASSIGNMENT",
	BY_DEPARTMENT = "BY_DEPARTMENT",
    BY_BOTH = "BOTH"
}

export enum SortOrder {
	asc = "ASC",
	desc =  "DESC"
}

export enum PatientOverviewOrderBy {
	lastName = "lastName",
	initials = "initials",
	birthDate = "birthDate",
	lastLogin = "lastLogin",
	scenarioNameEN = "scenarioNameEN",
	scenarioNameNL = "scenarioNameNL",
	diseaseNameEN = "diseaseNameEN",
	diseaseNameNL = "diseaseNameNL"
}

export const GENDER = [{
    name: { 'en': 'Male', 'nl': 'man' },
    value: 'Male'
},
{
    name: { 'en': 'Female', 'nl': 'vrouw' },
    value: 'Female'
},{
    name: { 'en': 'Other', 'nl': 'anders' },
    value: 'Other'
},{
    name: { 'en': 'Unknown', 'nl': 'onbekend' },
    value: 'Unknown'
}
]

export const PASSWORD_PATTERN = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#^(),.\/_-])[A-Za-z0-9@$!%*?&#^(),.\/_-]{8,}';
export const UCLA_PASSWORD_PATTERN = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#^(),.\/_-])[A-Za-z0-9@$!%*?&#^(),.\/_-]{14,}';
export const PHONE_NUMBER_PATTERN = '^(([\\+]1)\\d{10})|(([\\+]316)\\d{8})$';