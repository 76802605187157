import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, EMPTY } from 'rxjs';
import { APP_CONSTANTS, PatientOverviewOrderBy, PatientOverviewSource } from 'src/app/core/constants/app.constants';
import { GET_OVERVIEW } from 'src/app/core/constants/graphql.query.constants';
import { Disease, Scenario, MedicalContent } from 'src/app/core/model/common.interface';
import { PatientOverviewItem } from 'src/app/core/model/patient.interface';
import { PatientService } from 'src/app/core/services/patient.service';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { environment } from 'src/environments/environment';
import _ from 'lodash'
import { DateTimeUtilities } from 'src/app/dh-common/utilities/date-time.utilities';
import { expand, map, tap } from 'rxjs/operators';
import { CommunicationService } from 'src/app/core/services/communication.service';

export class PatientListDataSource implements DataSource<PatientOverviewItem> {
    // Inspired by https://nils-mehlhorn.de/posts/angular-material-pagination-datasource
    private patientListSubject$ = new BehaviorSubject<PatientOverviewItem[]>([]);
    lengthSubject$ = new BehaviorSubject<number>(null);
    private loadingSubject$ = new BehaviorSubject<boolean>(false);
    private cancelLoadingSubject$ = new Subject<void>();
    private pageKeys = { 0: null };
    public loading$ = this.loadingSubject$.asObservable();
    private environment;
    private dateTimeFormat;

    lang: LangType;
    medicalContent: MedicalContent;
    constructor(private patientService: PatientService, private translate: TranslateService,
        private stateService: StateService, private apollo: Apollo, private http: HttpClient, private communicationService: CommunicationService) {
        this.environment = environment;
        this.stateService.lang.subscribe((data) => {
            this.lang = data;
            this.translate.use(this.lang);
            this.refreshData();
        });
        this.medicalContent = this.stateService.getMedicalContent;
        this.dateTimeFormat = this.medicalContent?.formatting.dateTimeFormat;

        this.communicationService.refreshOverviewSource$.subscribe((patientData: any) => {
            patientData['lastLoginDate'] = this.changeLoginData(patientData['lastLogin']);
            const diseases: Disease[] = this.medicalContent.diseases;
            patientData['diseaseName'] =  this.getById(diseases, patientData.diseaseId).name;
            this.patientListSubject$.value.unshift(patientData);
            this.lengthSubject$.next(this.lengthSubject$.value + 1);
            const patients =this.patientListSubject$.value;
            this.patientListSubject$.next(patients);
        });
    }

    connect(collectionViewer: CollectionViewer): Observable<PatientOverviewItem[]> {
        return this.patientListSubject$.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.patientListSubject$.complete();
        this.loadingSubject$.complete();
    }

    canFetchPage(pageIndex: number): boolean {
        return this.pageKeys.hasOwnProperty(pageIndex);
    }

    refreshData() {
        setTimeout(() => {
            const patients =this.patientListSubject$.value;
            patients.map(patient => {
                patient.lastLoginDate = this.changeLoginData(patient.lastLogin);
            });
            this.patientListSubject$.next(patients);
        }, 0)
    }

    loadPatientList(pageIndex = 0, pageSize: number, filter?: any, sortField?: string,
        sortDirection?: string, source?: string, filterData?: any): void {
        this.loadingSubject$.next(true);
        this.cancelLoadingSubject$.next();
        let params = {
            limit: pageSize,
            offset: pageIndex * pageSize,
            source: source ? source : PatientOverviewSource.BY_BOTH,
            ...(sortDirection?.length > 0) && { sortOrder: sortDirection },
            ...(sortField?.length > 0) && { orderByField: PatientOverviewOrderBy[sortField] },
            ...(filterData) && { filterBy: filterData }
        }
        if(filter) {
            params = {...params, ...filter};
        }
        const overviewCount = this.lengthSubject$.getValue();
        this.getOverview(params).pipe(
            // expand((res: any) => checkNewPatient && overviewCount === res.data['getPatientOverview'].count ? this.getOverview(params) : EMPTY ),
            tap(overviewData => {
                this.lengthSubject$.next(overviewData.data['getPatientOverview'].count);
                const medicalContent: MedicalContent = this.stateService.getMedicalContent;
                const diseases: Disease[] = medicalContent.diseases;
                const scenarios: Scenario[] = this.buildScenarios(diseases);
                const patients = _.cloneDeep(overviewData.data['getPatientOverview'].patients);
                patients.forEach(patient => {
                    patient.diseaseName =  this.getById(diseases, patient.diseaseId).name;
                    patient.scenarioName = this.getById(scenarios, patient.scenarioId).name;
                    patient.lastLoginDate = this.changeLoginData(patient.lastLogin);
                });
                this.patientListSubject$.next(patients);
            }),
        ).subscribe();
    }

    getOverview(param): Observable<any> {
        return this.apollo
        .query({
            query: GET_OVERVIEW,
            variables: {
                patientOverviewInput: param
            }
        });
    }

    getById(list: (Disease|Scenario)[], id: string){
       const filteredList = list.filter(element => element.id ===id );
       return filteredList.length > 0 ? filteredList[0] : {name: '-'};
    }

    buildScenarios(diseases: Disease[]): Scenario[] {
        const scenarios = [];
        diseases.forEach(disease =>
          disease.treatmentPlan.forEach(treatmentPlan =>
           treatmentPlan.scenarios.forEach(scenario =>
             scenarios.push({...scenario, diseaseId: disease.id})
           )
          )
        );
        return scenarios;
    }

    clearPageKeys(): void {
        this.pageKeys = { 0: null };
    }

    changeLoginData(lastLoginDate: any): string {
        if (lastLoginDate === null) {
            return this.translate.instant('overview_page.no_last_login_text');
        } else {
            const lastLogin = moment(lastLoginDate);
            const currentDate = moment();
            const difference = currentDate.diff(lastLogin, 'month');
            if (difference === 0) {
                const utcDate = moment.utc(lastLoginDate).toDate();
                return moment(DateTimeUtilities.convertTZ(utcDate, this.medicalContent.timeZone)).format(this.dateTimeFormat.replace("dd", "DD"));
            } else if (difference === 1) {
                return `> ${difference} ${this.translate.instant('overview_page.month_text')}`;
            } else {
                return `> ${difference} ${this.translate.instant('overview_page.months_text')}`
            }
        }
    }
}
