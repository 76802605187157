import { Component, OnInit, Input } from '@angular/core';
import { Activity, IEventActivityType } from '../../../../core/model/patient.interface';
import { eventIconsMap, eventTypes } from '../../../../core/constants/calendar.constant';
import { MatDialog } from '@angular/material/dialog';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { monthsMap } from 'src/app/core/constants/calendar.constant';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { APP_CONSTANTS } from 'src/app/core/constants/app.constants';
import { MedicalContent, MedicalContentNames } from 'src/app/core/model/common.interface';
import { DateTimeUtilities } from 'src/app/dh-common/utilities/date-time.utilities';

@Component({
  selector: 'app-interval-calendar',
  templateUrl: './interval-calendar.component.html',
  styleUrls: ['./interval-calendar.component.scss']
})

export class IntervalCalendarComponent implements OnInit {
  monthsMap = monthsMap;
  startInterval: number;
  endInterval: number;
  intervals: number[] = [];
  tmpMap: Map<string, Activity[]> = new Map();
  currentDate = moment();
  dateFormat: string;
  medicalContentNames: MedicalContentNames;
  medicalContent: MedicalContent;
  typesMap: Map<string, string> = eventIconsMap;
  lang: LangType;


  readonly defaultIconPath = '../assets/icons/';

  @Input() isPreview?: boolean;
  @Input() startDate: Date;
  @Input() set activities(activities: Activity[]) {
    if (activities) {
      this.tmpMap = new Map();
      activities?.forEach(it => {
        const date: any = moment(it.datetime);
        const activityDate = moment(date, APP_CONSTANTS.DD_MM_YYYY_FORMAT);
        const startDate = moment(this.startDate, APP_CONSTANTS.DD_MM_YYYY_FORMAT);
        it['datePassed'] = ((this.isPreview) && activityDate.isBefore(startDate, 'day'));
        const convertedDate = moment(DateTimeUtilities.convertTZ(it.datetime, this.medicalContent.timeZone));
        const key = convertedDate.year() + ' ' + this.getWeekNumberByMonth(convertedDate.year(), convertedDate.month(), convertedDate.date());
        if (this.tmpMap.has(key)) {
          const existingValues: Activity[] = this.tmpMap.get(key);
          existingValues.push(it);
          this.tmpMap.set(key, existingValues);
        } else {
          this.tmpMap.set(key, [it]);
        }
      });
    }
  }

  constructor(private datePipe: DatePipe, private stateService: StateService) {
    this.stateService.lang.subscribe(res => {
      this.lang = res;
      moment.locale(this.lang);
    });
    this.stateService.medicalContentNamesObs.subscribe(data => {
      this.medicalContentNames = data;
    });
    this.stateService.medicalContentObs.subscribe((data) => {
      this.medicalContent = this.stateService.getMedicalContent;
      this.dateFormat = this.medicalContent.formatting.shortDateFormat;
    });
  }

  ngOnInit(): void {
    // Get the week numbers of prev month, current month and next month
    this.generateIntervals();
  }

  generateIntervals() {
    this.intervals = [];
    for (let i = -1; i <= 1; i++) {
      this.startInterval = this.getWeekNumberByMonth(this.currentDate.year(), this.currentDate.month() + i, 1);
      this.endInterval = this.getWeekNumberByMonth(this.currentDate.year(), this.currentDate.month() + i + 1, 0)
      if (this.endInterval > this.startInterval) {
        this.intervals.push(...Array((this.endInterval - this.startInterval) + 1).fill(this.startInterval).map((x, y) => x + y));
      } else {
        this.intervals.push(...Array((this.endInterval - 0)).fill(1).map((x, y) => x + y));
      }
    }
    this.intervals = [...new Set(this.intervals)];
  }

  monthBack(): void {
    this.currentDate.add(-1, 'month');
    this.generateIntervals();
  }

  monthForward(): void {
    this.currentDate.add(1, 'month');
    this.generateIntervals();
  }

  getWeekNumberByMonth(year: number, month: number, day: number): number {
    const date = new Date(year, month, day);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
      - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  getActivitiesForWeek(weekNumber: string): Activity[] {
    const key: string = this.currentDate.year() + ' ' + weekNumber;
    if (this.tmpMap.has(key)) {
      return this.tmpMap.get(key);
    } else {
      return [];
    }
  }

  displayTooltipName(activity): string {
    let tooltip = "";
    if (this.medicalContentNames?.activities) {
      if (this.medicalContentNames?.activities[activity.activityDefinitionId]?.title) {
        tooltip = `${this.medicalContentNames.activities[activity.activityDefinitionId]?.title[this.lang]} - ${this.formulateDate(activity.datetime)}`
      } else {
        tooltip = this.formulateDate(activity.datetime);
      };
    } else {
      tooltip = this.formulateDate(activity.datetime);
    };
    return tooltip;
  }

  formulateDate(dateTime: string): string {
    return moment(DateTimeUtilities.convertTZ(dateTime, this.medicalContent.timeZone)).format(this.medicalContent.formatting.shortDateFormat.replace("dd", "DD"));
}

  setCurrentIntervalClass(weekNumber: number): boolean {
    return weekNumber === this.getWeekNumberByMonth(moment().year(), moment().month(), moment().date()) && this.currentDate.year() === new Date().getFullYear() && this.isPreview;
  }

  setPrevIntervalClass(weekNumber: number): boolean {
    return weekNumber < this.getWeekNumberByMonth(moment().year(), moment().month(), moment().date()) && this.currentDate.year() <= new Date().getFullYear() && this.isPreview;
  }

  setDropdownbyStartDate(weekNumber: number) {
    return weekNumber === this.getWeekNumberByMonth(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()) && this.currentDate.year() === new Date().getFullYear() && this.isPreview;
  }

}