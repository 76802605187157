import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() isEditable?: boolean;
  @Input() isEditScreen: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  changeTelephoneNumber(): void {
    const telephoneNumer = this.formGroup.get('telephoneNumber').value.replace(/ /g, "");
    if(telephoneNumer.startsWith('06')) {
      this.formGroup.get('telephoneNumber').setValue(telephoneNumer.replace('0', '+31'))
    }
  }

}
