import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { PHONE_NUMBER_PATTERN } from "src/app/core/constants/app.constants";

export abstract class CreatePatientForms {

    generalFormGroup: FormGroup;
    contactFormGroup: FormGroup;
    careFormGroup: FormGroup;


    constructor(public formBuilder: FormBuilder) {
        this.generalFormGroup = this.formBuilder.group({
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            initials: ['', Validators.compose([Validators.required, this.initialLengthValidation()])],
            gender: ['', Validators.required],
            date: ['', Validators.compose([Validators.required, this.dateValidation()])],
            month: ['', Validators.compose([Validators.required, this.monthValidation()])],
            year: ['', Validators.compose([Validators.required, this.yearValidation()])]
        });
        this.contactFormGroup = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            telephoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN)])],
            externalId: ['', Validators.required]
        });
        this.careFormGroup = this.formBuilder.group({
            diseaseId: ['', Validators.required],
            practitionerId: ['', Validators.required],  
            programStartDate: [new Date(), Validators.compose([Validators.required, this.dateFormatValidation()
            ])],
        });
    }

    public initialLengthValidation(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const value = control.value?.replace(/\./g, "");
            if (!value) {
                return null;
            }
            const isValid = value.length <= 10;
            return !isValid ? { lengthExceeds: true } : null;
        }
    }

    public dateFormatValidation(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (! control?.value) {
              return {
                invalidDateFormat: true
              };
            }
        }
    }

    public dateValidation(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = parseInt(control.value);
            if (!value) {
                return null;
            }
            return value > 31 ? { invalidDate: true } : null;
        }
    }

    public monthValidation(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = parseInt(control.value);
            if (!value) {
                return null;
            }
            return value > 12 ? { invalidMonth: true } : null;
        }
    }

    public yearValidation(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = parseInt(control.value);
            if (!value) {
                return null;
            }
            return value > new Date().getFullYear() ? { invalidYear: true } : null;
        }
    }

}