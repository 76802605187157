import moment from 'moment'

export const doughnutChartOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: '88%',
  plugins: {
    tooltip: { enabled: false }
  },
  hover: { mode: null },
  layout: {
    padding: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    }
  },
  spanGaps: false,

  elements: {
    arc: {
      borderWidth: 0,
    },
    point: {
      radius: 1,
      hitRadius: 20,
      hoverRadius: 10,
      hoverBorderWidth: 2
    },
    rectangle: {}
  }
};

export function nonHaChartOptions(format: string) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: null },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            return `${parseInt(context.raw)} - ${moment(context.dataset.label).format(format.replace('dd', 'DD'))}`;
          }
        }
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }
    },
    scales: {

      x: {
        barThickness: 10,
        maxBarThickness: 10,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        maxBarThickness: 10,
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
          borderDash: [5, 2],
          color: '#F0F0F0'
        },
        ticks: {
          padding: 20,
          maxTicksLimit: 5,
        }
      }
    }
  }
}


export function barChartOptions(format: string) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: null },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        formatter: function (value, context) {
          return context.dataset.backgroundColor == '#16A0C1' ? value : '';
        },
        anchor: 'end',
        align: 'end',
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            return `${parseInt(context.raw)} - ${moment(context.dataset.label).format(format.replace('dd', 'DD'))}`;
          }
        }
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }
    },

    scales: {

      x: {
        barThickness: 10,
        maxBarThickness: 10,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        maxBarThickness: 10,
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
          borderDash: [5, 2],
          color: '#F0F0F0'
        },
        ticks: {
          padding: 20,
          maxTicksLimit: 5,
        }
      }
    }
  }
}

export function lineChartOptions(format: string) {
  return {
    maintainAspectRatio: false,
    bezierCurve: false,
    lineTension:0,
    responsive: true,
    elements: {
      line: {
        tension: 1,
        borderWidth: 1
      },
      point: {
        radius: 1,
        backgroundColor: '#16A0C1'
      }
    },
    scales : {
      x : {
        grid : {
          drawTicks: false
        }
      }, y : {
        grid : {
          drawTicks: false
        }
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            return `${parseInt(context.raw)}`;
          }
        }
      },
    }
  }
}

// export const nonHaChartOptions: any =  {

//     responsive: true,
//     maintainAspectRatio: false,
//     hover: {mode: null},
//     plugins: {
//       title: {
//         display: false,
//       },
//       legend: {
//         display: false,
//       },
//       tooltip: {
//          displayColors: false,
//          callbacks: {
//            label: function(context) {
//              return `${parseInt(context.raw) } - ${moment(context.dataset.label).format('DD/MM/YYYY')}`;
//            }
//          }
//        },
//     },
//     layout: {
//         padding: {
//             top: 0,
//             right: 0,
//             left: 0,
//             bottom: 0,
//         }
//     },
//     scales: {
//       x: {
//         grid:{
//           display: false,
//           drawBorder: false
//         },
//         ticks: {
//           autoSkip: false,
//           maxRotation: 0,
//           minRotation: 0
//         }
//       },
//       y: {
//       grid:{
//         drawTicks: false,
//         drawBorder: false,
//         borderDash: [5, 2],
//         color: '#F0F0F0'
//       },
//       ticks: {
//           padding: 20,
//           maxTicksLimit: 5,
//         }
//       }
//     }
// };
