<div class="chat-window flex-column">
  <div class="header pl-2 py-2">
    {{'chat_header' | translate }}
    <button mat-icon-button class="minus" (click)="closeChat()">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
  <div class="content">
    <div #chat class="chat">
      <div class="note pb-5 mb-4">
        <p class ="m-2">{{"chat_note" | translate}}</p>
      </div>
      <div *ngFor="let message of messages">
        <div class="ml-5 mr-2 mb-4">
          <span class="title pr-2">{{'chat_title' | translate}}</span>
          <span class="meta">{{message.createdAt | localdate: medicalContent.timeZone : medicalContent.formatting.dateTimeFormat}}</span>
          <div class="chat-message pl-3 py-3">
            <div [innerHTML]="message.contents"></div>
            <div class="check">
              <mat-icon fontSet="fa" fontIcon="fa-check-double"></mat-icon>
            </div>
          </div>
          <div class="meta">{{'chat_signature' | translate}} {{message.senderName}}</div>
        </div>
      </div>
    </div>
    <div class="control pl-3 py-1">
      <mat-form-field appearance="outline" class="chat-field">
        <textarea matInput placeholder="{{'chat_placeholder' | translate}}" [(ngModel)]="currmessage" (keyup.control.enter)="sendMessage()"></textarea>
      </mat-form-field>
      <button mat-icon-button aria-label="Send" (click)="sendMessage()" [disabled]="currmessage===''" title="{{'chat_send_button_tooltip' | translate}}">
        <mat-icon fontSet="fa" fontIcon="fa-paper-plane"></mat-icon>
      </button>
    </div>
  </div>
</div>

