
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateTimeUtilities } from 'src/app/dh-common/utilities/date-time.utilities';


@Pipe({
  name: 'localdate'
})
export class LocalDatePipe implements PipeTransform {
  transform(date: string, timeZone: string, format: string): string {
    date = (date.includes("T") && !date.toLowerCase().includes("z")) ?  date + "z" : date;
    return moment(DateTimeUtilities.convertTZ(date, timeZone)).format(format.replace("dd", "DD"))
  }
}