import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { StateService, LangType } from './core/services/state.service';
import { DatadogService } from './core/services/datadog.service';
import { AuthService } from './core/services/auth.service';
import { LaunchDarklyService } from './core/services/launchDarkly.service';
import * as ld from 'launchdarkly-js-client-sdk';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  title = 'dearhealth';
  timeId: number;
  _subscription: any;
  tld_language_map = { com: 'EN', eu: 'NL' };
  launchDarklyClient: ld.LDClient;
  showMaintenancePage: boolean;

  constructor(private stateService: StateService, private launchDarklyService: LaunchDarklyService, private datadogService: DatadogService, private authService: AuthService, private ngZone: NgZone) {
    this.stateService.setProviderId = sessionStorage.getItem('providerId');
    this.datadogService.init();
    const locale = localStorage.getItem('locale');
    if (locale) {
      this.stateService.setLang(LangType[locale.toUpperCase()]);
    }
    else if (this.stateService.getMedicalContent?.regions) {
      if (this.stateService.getMedicalContent.regions[0] == 'eu') {
        this.stateService.setLang(LangType['NL']);
      }
      else {
        this.stateService.setLang(LangType['EN']);
      }
    }
    else {
      var tld = this.getDomain(window.location.hostname);
      this.stateService.setLang(LangType[this.tld_language_map[tld] || 'EN']);
    }
  }


  @HostListener('document:mousemove')
  @HostListener('document:mouseleave')
  @HostListener('document:mousedown')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:wheel')
  resetTimer() {

    this.stateService.embeddedLaunch.subscribe((data) => {
      if (!data) {
        this.ngZone.runOutsideAngular(() => {
          clearTimeout(this.timeId);
          const automaticPractitionerLogOutTime = this.stateService.getMedicalContent?.automaticPractitionerLogoutTimeInMinutes ;
          const time = (automaticPractitionerLogOutTime ?? 100) * 60 * 1000;
          this.timeId = window.setTimeout(() => {
            if (this.authService.getPractitionerToken()) {
              this.authService.logout();
            }
          }, time)
        })
      } else {
        clearTimeout(this.timeId);
      }
    })
  }

  ngOnInit() {
    this.resetTimer();
    setTimeout(() => {
      this.showMaintenancePage = this.launchDarklyService.ldClient.variation('show-maintenance-page')
      this._subscription = this.launchDarklyService.flagChange.subscribe((flags) => {
        this.showMaintenancePage = flags['show-maintenance-page'].current;
      });
    }, 500);
  }

  getDomain(url: string) {
    var url_array = url.split('.')
    var tld_index = url_array.length - 1
    return url_array[tld_index]
  }
}
