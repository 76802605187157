import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { monthsMap } from 'src/app/core/constants/calendar.constant';
import * as moment from 'moment';
import { Activity, IEventActivityType } from '../../../../core/model/patient.interface';
import { EventDialogComponent } from '../../event-dialog/event-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { eventIconsMap } from '../../../../core/constants/calendar.constant';
import { MatMenuTrigger } from '@angular/material/menu';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { APP_CONSTANTS } from 'src/app/core/constants/app.constants';
import { DatePipe } from '@angular/common';
import { MedicalContent, MedicalContentNames } from 'src/app/core/model/common.interface';
import { DateTimeUtilities } from 'src/app/dh-common/utilities/date-time.utilities';

@Component({
    selector: 'app-monthly-calendar',
    templateUrl: './monthly-calendar.component.html',
    styleUrls: ['./monthly-calendar.component.scss']
})
export class MonthlyCalendarComponent implements OnInit {
    monthsMap = monthsMap;
    months = [];
    dateNow = moment();
    currentYear = this.dateNow.year();
    typesMap: Map<string, string> = eventIconsMap;
    lang: LangType;
    tmpMap: Map<string, Activity[]> = new Map();
    showAddAction: any = {};
    selectedMonth: string;
    medicalContentNames: MedicalContentNames;
    dateFormat: string;

    readonly defaultIconPath = '../assets/icons/';

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @Output() addActivity = new EventEmitter<{ year: number, month: string, activityType: IEventActivityType }>();
    @Output() editActivity = new EventEmitter<{ activityDetails: Activity }>();
    @Output() deleteActivity = new EventEmitter<string>();
    @Input() activityTypes: IEventActivityType;
    @Input() editable?: boolean;
    @Input() isPreview?: boolean;
    @Input() medicalContent: MedicalContent;
    @Input() startDate = moment();
    @Input() set activities(activities: Activity[]) {
        if (activities) {
            this.tmpMap = new Map();
            activities?.forEach(it => {
                const date: any = moment(it.datetime);
                const activityDate = moment(date, APP_CONSTANTS.DD_MM_YYYY_FORMAT);
                const startDate = moment(this.startDate, APP_CONSTANTS.DD_MM_YYYY_FORMAT);
                it['datePassed'] = ((this.isPreview) && activityDate.isBefore(startDate, 'day'));
                const key = date.year() + ' ' + date.month();
                if (this.tmpMap.has(key)) {
                    const existingValues: Activity[] = this.tmpMap.get(key);
                    existingValues.push(it);
                    this.tmpMap.set(date.format('YYYY') + ` ` + date.month(), existingValues);
                } else {
                    this.tmpMap.set(key, [it]);
                }
            });
        }
    }

    constructor(private dialogRef: MatDialog, private stateService: StateService, private viewRef: ViewContainerRef, private datePipe: DatePipe) {
        this.stateService.lang.subscribe(res => {
            this.lang = res;
        });
        this.stateService.medicalContentNamesObs.subscribe(data => {
            this.medicalContentNames = data;
        });
    }

    ngOnInit(): void {
        this.dateFormat = this.medicalContent.formatting.shortDateFormat;
        for (const key of monthsMap.keys()) {
            this.months.push(key);
        }
    }

    displayTooltipName(activity): string {
        let tooltip = "";
        if (this.medicalContentNames?.activities) {
            if (this.medicalContentNames?.activities[activity.activityDefinitionId]?.title) {
                tooltip = `${this.medicalContentNames.activities[activity.activityDefinitionId]?.title[this.lang]} - ${this.formulateDate(activity.datetime)}`
            } else {
                tooltip = this.formulateDate(activity.datetime);
            };
        } else {
            tooltip = this.formulateDate(activity.datetime);
        };
        return tooltip;
    }

    formulateDate(dateTime: string): string {
        return moment(dateTime).format(this.dateFormat.replace("dd", "DD"));
        // return moment(DateTimeUtilities.convertTZ(dateTime, this.medicalContent.timeZone)).format(this.medicalContent.formatting.shortDateFormat.replace("dd", "DD"));
    }

    showActivityTypes(ev: Event, data: string) {
        this.selectedMonth = data;
        ev.stopPropagation();
    }

    toggleHover(ev: Event) {
        this.showAddAction = !this.showAddAction;
        ev.stopPropagation();
    }

    triggerEmit(value: IEventActivityType) {
        this.editable ? this.addActivity.emit({ year: this.currentYear, month: this.selectedMonth, activityType: value }) : false;
    }

    trackElement(index: number, element: any): any {
        return element ? element.guid : null;
    }

    yearBack(): void {
        this.currentYear -= 1;
    }

    yearForward(): void {
        this.currentYear += 1;
    }

    getActivitiesForMonth(month: string): Activity[] {
        const monthNumber = monthsMap.get(month);
        const key: string = this.currentYear + ' ' + (monthNumber - 1).toString();
        if (this.tmpMap.has(key)) {
            return this.tmpMap.get(key);
        } else {
            return [];
        }
    }

    getColorClass(activity: Activity): any {
        if (activity.type === 'Operation') {
            return 'color-pink';
        } else {
            return 'color-purple';
        }
    }

    showInfo(activity: Activity): void {
        if (!this.isPreview) {
            if (!this.editable) {
                this.dialogRef.open(EventDialogComponent, {
                    width: '400px',
                    viewContainerRef: this.viewRef,
                    data: activity
                });
            } else {
                this.editActivity.emit({ activityDetails: activity });
            }
        }
    }

    openDetelePopup(ev: Event, activity: Activity) {
        this.deleteActivity.emit(activity.activityId)
        ev.stopPropagation();
    }

    setCurrentMonthClass(month: string): boolean {
        return this.monthsMap.get(month) === new Date().getMonth() + 1 && this.currentYear === new Date().getFullYear() && this.isPreview;
    }

    setDropdownbyStartDate(month: number) {
        return this.monthsMap.get(month) === this.startDate.month() + 1 && this.currentYear === this.startDate.year() && this.isPreview;
    }
}
