<form [formGroup]="formGroup" [ngClass]="{'label-container':!isEditable}" class="general-form" autocomplete="off">
    <form-field [isEdit]="isEditable" label="{{'create_patient.first_name_text' | translate }}"
        [value]="formGroup.value.firstName ? formGroup.value.firstName : '-'">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>{{"create_patient.first_name_text" | translate}}</mat-label>
            <input matInput formControlName="firstName" required>

            <mat-error *ngIf="formGroup.get('firstName').hasError('required')">
                {{"required_field_error_text" | translate}}</mat-error>
            <mat-error *ngIf="formGroup.get('firstName').hasError('pattern')">
                {{"create_patient.invalid_first_name" | translate}}</mat-error>
        </mat-form-field>
    </form-field>

    <form-field [isEdit]="isEditable" label="{{ 'create_patient.last_name_text' | translate }}"
        [value]="formGroup.value.lastName ? formGroup.value.lastName : '-'">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>{{"create_patient.last_name_text" | translate}}</mat-label>
            <input matInput formControlName="lastName" required>
            <mat-error *ngIf="formGroup.get('lastName').hasError('required')">
                {{"required_field_error_text" | translate}}</mat-error>
            <mat-error *ngIf="formGroup.get('lastName').hasError('pattern')">
                {{"create_patient.invalid_last_name" | translate}}</mat-error>
        </mat-form-field>
    </form-field>

    <form-field [isEdit]="isEditable" label="{{ 'create_patient.initial_text' | translate }}"
        [value]="formGroup.value.initials ? formGroup.value.initials : '-'">
        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>{{"create_patient.initial_text" | translate}}</mat-label>
            <input matInput formControlName="initials" required (blur)="changeInitial()">
            <mat-error *ngIf="formGroup.get('initials').hasError('required')">
                {{"required_field_error_text" | translate}}</mat-error>
            <mat-error *ngIf="formGroup.get('initials').hasError('lengthExceeds')">
                {{"create_patient.initial_max_allowed" | translate}}</mat-error>
            <mat-error *ngIf="formGroup.get('initials').hasError('pattern')">
                {{"create_patient.invalid_initial" | translate}}</mat-error>
        </mat-form-field>
    </form-field>

    <form-field [isEdit]="isEditable" label="{{ 'create_patient.gender_text' | translate }}"
        [value]="translatedGender">
        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>{{"create_patient.gender_text" | translate}}</mat-label>
            <mat-select formControlName="gender" required>
                <mat-option *ngFor="let gen of genderList" [value]="gen.value">
                    {{gen.name | translatable: lang}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('gender').hasError('required')">
                {{"required_field_error_text" | translate}}</mat-error>
        </mat-form-field>
    </form-field>

    <div>
        <h3 *ngIf="isEditable">{{'create_patient.dob_text' | translate}}</h3>

        <form-field *ngIf="!isEditable" [isEdit]="isEditable" label="{{'create_patient.dob_text' | translate }}"
        [value]="getDob() | date: dateFormat"></form-field>

        <form-field *ngIf="isEditable" [isEdit]="isEditable" label="{{'create_patient.date_text' | translate }}"
            [value]="formGroup.value.date ? formGroup.value.date : '-'">
            <mat-form-field appearance="outline" class="col-md-4">
                <mat-label>{{'create_patient.date_text' | translate}}</mat-label>
                <input matInput formControlName="date" required type="number" min="0" max="31" (blur)="changeDate()"
                    placeholder="DD">
                <mat-error *ngIf="formGroup.get('date').hasError('required')">
                    {{"required_field_error_text" | translate}}</mat-error>
                <mat-error *ngIf="formGroup.get('date').hasError('invalidDate')">
                    {{"create_patient.inalid_date_text" | translate}}</mat-error>
            </mat-form-field>
        </form-field>

        <form-field *ngIf="isEditable" [isEdit]="isEditable" label="{{'create_patient.month_text' | translate }}"
            [value]="formGroup.value.month ? formGroup.value.month : '-'">
            <mat-form-field appearance="outline" class="col-md-4">
                <mat-label>{{'create_patient.month_text' | translate}}</mat-label>
                <input matInput formControlName="month" required type="number" min="1" max="12" (blur)="changeMonth()"
                    placeholder="MM">
                <mat-error *ngIf="formGroup.get('month').hasError('required')">
                    {{"required_field_error_text" | translate}}</mat-error>
                <mat-error *ngIf="formGroup.get('month').hasError('invalidMonth')">
                    {{"create_patient.inalid_month_text" | translate}}</mat-error>
            </mat-form-field>
        </form-field>

        <form-field *ngIf="isEditable" [isEdit]="isEditable" label="{{'create_patient.year_text' | translate }}"
            [value]="formGroup.value.year ? formGroup.value.year : '-'">
            <mat-form-field appearance="outline" class="col-md-4">
                <mat-label>{{'create_patient.year_text' | translate}}</mat-label>
                <input matInput formControlName="year" [min]='calendarStartDate' [max]="calendarEndDate" required type="number" (blur)="checkDOB()"
                    placeholder="YYYY">
                <mat-error *ngIf="formGroup.get('year').hasError('required')">
                    {{"required_field_error_text" | translate}}</mat-error>
                <mat-error *ngIf="formGroup.get('year').hasError('invalidYear')">
                    {{"create_patient.inalid_year_text" | translate}}</mat-error>
            </mat-form-field>
        </form-field>
    </div>
</form>