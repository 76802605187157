import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { GET_LAST_X_SCORES } from 'src/app/core/constants/graphql.query.constants';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { StateService } from 'src/app/core/services/state.service';
import * as chartsData from '../../../shared/data/chartsjs';
import { MedicalContent } from 'src/app/core/model/common.interface';

const QUESTIONNAIRE = "QUESTIONNAIRE";
const QUESTIONNAIRE_COMPONENT = "QUESTIONNAIRE_COMPONENT";


@Component({
  selector: 'app-non-health-assesment-graph',
  templateUrl: './non-ha-graph.component.html',
  styleUrls: ['./non-ha-graph.component.scss']
})
export class NonHAGraphComponent implements OnInit {
  @Input() questionnaireType;

  medicalContents: MedicalContent
  names
  questionnaireScores = { conditions: [] };
  componentData;
  totalData;
  lang: string
  barChartOptions: any;

  constructor(private translateService: TranslateService, private stateService: StateService,
    public dialog: MatDialog, private viewRef: ViewContainerRef, private apollo: Apollo,
    private navigationService: NavigationService) {
    this.stateService.medicalContentObs.subscribe((data) => {
      this.medicalContents = data;
      this.barChartOptions = chartsData.nonHaChartOptions(this.medicalContents.formatting.shortDateFormat);
    });
    this.stateService.medicalContentNamesObs.subscribe((data) => {
      this.names = data['questionnaires']
    });
    this.lang = this.translateService.getLangs()[0];
    translateService.onLangChange.subscribe(event => {
      this.lang = event.lang;
      this.buildChart();
    });
  }

  shouldShowOneLine() {
    return this.names[this.questionnaireType].components.length > 2 && this.names[this.questionnaireType].components.length < 6
  }

  ngOnInit() {
    const components = this.names[this.questionnaireType]?.components.map(component => ({ questionnaireId: this.questionnaireType, measureName: QUESTIONNAIRE_COMPONENT, componentId: component.id, conditionId: component.id }))
    if (components) {
      components.push({ questionnaireId: this.questionnaireType, measureName: QUESTIONNAIRE, conditionId: this.questionnaireType })
      const querySubscription = this.apollo
        .query({
          query: GET_LAST_X_SCORES,
          variables: {
            conditionInput: components,
            limit: 3
          }
        }).subscribe((data) => {
          this.questionnaireScores = data['data']['getLastXQuestionnaireScores'];
          if(this.questionnaireScores?.conditions.length > 0) {
            this.buildChart()
          }
        });
    }
  }

  

  buildChart() {
    this.componentData = {
      labels: this.buildLabels(),
      datasets: this.buildData()
    };

    this.totalData = {
      labels: [this.translateService.instant('total')],
      datasets: this.buildTotal()
    };
  }

  setTab() {
    sessionStorage.setItem('showDetailsTab', 'true');
    this.navigationService.setTab('questionnaire');
  }

  buildData() {
    const data = this.buildDataSets(this.questionnaireScores.conditions[0].conditionScores, 0.5)
    const ids = this.names[this.questionnaireType].components.map(({ id, name }) => id);
    ids.forEach(id => {
      const condition = this.getElement(this.questionnaireScores.conditions, `${id}_score`, 'conditionId');
      condition.conditionScores.forEach(score => this.getElement(data, this.getLocalizedDate(score.time), 'label').data.push(score.value));
    });
    return data;
  }

  buildTotal() {
    const condition = this.getElement(this.questionnaireScores.conditions, `${this.questionnaireType}_score`, 'conditionId')
    const categoryPercentage = this.shouldShowOneLine() ? 0.5 : 0.3
    const data = this.buildDataSets(this.questionnaireScores.conditions[0].conditionScores, categoryPercentage)
    condition.conditionScores.forEach((score) => this.getElement(data, this.getLocalizedDate(score.time), 'label').data.push(score.value));
    return data;
  }

  getLocalizedDate(date : string) {
    date = date.includes('T') ? date : date.replace(' ', 'T');
    date = !date.toLowerCase().includes("z") ?  date + "z" : date;
    return new Date(date).toLocaleString("en-US", {timeZone: this.medicalContents.timeZone});   
  }

  buildDataSets(datasets, categoryPercentage) {
    return datasets.map((dataset, index) => ({
      categoryPercentage: categoryPercentage,
      minBarLength: 7,
      backgroundColor: index == datasets.length - 1 ? '#16A0C1' : '#BDBDBD50',
      data: [],
      label: this.getLocalizedDate(dataset.time)
    }));
  }

  buildLabels() {
    const labels = [];
    return this.names[this.questionnaireType].components.map(({ id, name }) => this.translateService.instant(name[this.lang]));
  }

  getElement(array, id, idFieldName) {
    return array.filter(element => element[idFieldName] === id)[0] ?? {};
  }
}