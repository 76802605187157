import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpContext, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

export const SHOULD_SKIP_LOADER = new HttpContextToken<boolean>(() => false);

@Injectable({
    providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
    requestNum: number;

    constructor(public loaderService: LoaderService) {
        this.requestNum = 0;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.context.get(SHOULD_SKIP_LOADER)){
            return next.handle(req)
        }

        if (this.requestNum === 0) {
            this.loaderService.show();
        }
        this.requestNum++;
        return next.handle(req).pipe(
            finalize(() => {
                this.requestNum--;
                if (this.requestNum === 0) { this.loaderService.hide(); }
            })
        );
    }
}
