import { Directive, Input, TemplateRef, ElementRef, OnInit, HostListener, ComponentRef, OnDestroy } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { RecommendationTooltipComponent } from './recommendation-tooltip/recommendation-tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
    selector: '[appRecommendationToolTip]'
})
export class ToolTipRendererDirective implements OnInit, OnDestroy{


    @Input() showToolTip: boolean = true;

    @Input(`customToolTip`) text: string;

    @Input() contentTemplate: TemplateRef<any>;

    private _overlayRef: OverlayRef;

    constructor(private _overlay: Overlay,
                private _overlayPositionBuilder: OverlayPositionBuilder,
                private _elementRef: ElementRef) { }


    ngOnInit(): void {

        if (!this.showToolTip) {
            return;
        }
        const positionStrategy = this._overlayPositionBuilder
            .flexibleConnectedTo(this._elementRef)
            .withPositions([{
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
                offsetY: 5
            }]);

        this._overlayRef = this._overlay.create({ positionStrategy, panelClass: ['tool-tip-overlay-pane']});
        this._overlayRef.addPanelClass('tool-tip-overlay-pane');
    }

    @HostListener('mouseenter')
    show(): void {
        if (this._overlayRef && !this._overlayRef.hasAttached()) {
            const tooltipRef: ComponentRef<RecommendationTooltipComponent> = this._overlayRef.attach(new ComponentPortal(RecommendationTooltipComponent));
            tooltipRef.instance.text = this.text;
            tooltipRef.instance.contentTemplate = this.contentTemplate;
        }
    }

    @HostListener('mouseleave')
    hide(): void {
        this.closeToolTip();
    }

    ngOnDestroy(): void {
        this.closeToolTip();
    }

    private closeToolTip(): void {
        if (this._overlayRef) {
            this._overlayRef.detach();
        }
    }

}
