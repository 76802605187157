import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { ChartConfiguration } from 'chart.js';
import { GET_METRICS } from 'src/app/core/constants/graphql.query.constants';
import { MedicalContent } from 'src/app/core/model/common.interface';
import { StateService } from 'src/app/core/services/state.service';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { combineLatest } from 'rxjs';
import { LangType } from 'src/app/core/services/state.service';
import { DateTimeUtilities } from 'src/app/dh-common/utilities/date-time.utilities';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { LineGraphComponent } from 'src/app/dh-common/components/graphs/line-graph/line-graph.component';

export interface MetricDatas {
  recommenderKey: string;
  values: MetricValue[];
}
export interface MetricValue {
  data: [MetricData]
}
export interface MetricData {
  time: string;
  value: number;
}

export interface RecommenderKeysValueNamesInput {
  recommenderKey: string;
  valueNames: string;
}

@Component({
  selector: 'app-disease-specific-graph',
  templateUrl: './disease-specific-graph.component.html',
  styleUrls: ['./disease-specific-graph.component.scss']
})

export class DiseaseSpecificGraphComponent implements OnInit {

  medicalContents: MedicalContent;
  metricDatas: MetricDatas[];
  configGraphs: any = [];
  flattenConfigGraphRows: any = new Map();
  recommenderKeysValueNamesInput: RecommenderKeysValueNamesInput[] = [];
  rowsChartData: any[] = [];
  limit: number = 3;
  type: string = 'bar';
  title: string = '';
  lang: LangType;
  constructor(private apollo: Apollo, private stateService: StateService, private navigationService: NavigationService,
    private patientStateService: PatientStateService, public dialog: MatDialog, private viewRef: ViewContainerRef) { }

  ngOnInit(): void {

    this.stateService.lang.subscribe(res => {
      this.lang = res;
    });
    // this.configGraphs = 
    // [
    //   {
    //     "title": "FOSI",
    //     "rows": [
    //       [
    //         {
    //           "recommenderKey": "Q_OVARIANCANCER_FOSI_QUEST_1",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "score",
    //           "xAxisLabel": {
    //             "en": "Lack of energy",
    //             "nl": "Gebrek aan energie"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         },
    //         {
    //           "recommenderKey": "Q_OVARIANCANCER_FOSI_QUEST_2",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "score",
    //           "xAxisLabel": {
    //             "en": "Pain",
    //             "nl": "Pijn"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         },
    //         {
    //           "recommenderKey": "Q_OVARIANCANCER_FOSI_QUEST_3",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "score",
    //           "xAxisLabel": {
    //             "en": "Cramps",
    //             "nl": "Krampen"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         },
    //         {
    //           "recommenderKey": "Q_OVARIANCANCER_FOSI_QUEST_4",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "score",
    //           "xAxisLabel": {
    //             "en": "Swelling",
    //             "nl": "Zwelling"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         },
    //         {
    //           "recommenderKey": "Q_OVARIANCANCER_FOSI_QUEST_5",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "score",
    //           "xAxisLabel": {
    //             "en": "Worry",
    //             "nl": "Zorgen"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         },
    //         {
    //           "recommenderKey": "Q_OVARIANCANCER_FOSI_QUEST_6",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "score",
    //           "xAxisLabel": {
    //             "en": "Nausea",
    //             "nl": "Misselijkheid"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         },
    //         {
    //           "recommenderKey": "Q_OVARIANCANCER_FOSI_QUEST_7",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "score",
    //           "xAxisLabel": {
    //             "en": "Vomiting",
    //             "nl": "Overgeven"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         },
    //         {
    //           "recommenderKey": "Q_OVARIANCANCER_FOSI_QUEST_8",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "score",
    //           "xAxisLabel": {
    //             "en": "Quality of life",
    //             "nl": "Kwaliteit van leven"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         }
    //       ],
    //       [
    //         {
    //           "recommenderKey": "HA_OVARIANCANCER",
    //           "type": "bar",
    //           "history": 3,
    //           "maxScore": 4,
    //           "minScore": 0,
    //           "valueField": "raw_score",
    //           "xAxisLabel": {
    //             "en": "Total score",
    //             "nl": "Totaalscore"
    //           },
    //           "yAxisLabel": {
    //             "en": "",
    //             "nl": ""
    //           }
    //         }
    //       ]
    //     ]
    //   }
    // ];
    // if (this.configGraphs.length > 0) {
    //   this.title = this.configGraphs[0].title;
    //   this.configGraphs.forEach((configGraph) => {
    //     configGraph.rows.forEach((row) => {
    //       this.type = row[0].type;
    //       row.forEach((graph) => {
    //         this.flattenConfigGraphRows.set(graph.recommenderKey, graph);
    //         this.recommenderKeysValueNamesInput.push({ recommenderKey: graph.recommenderKey, valueNames: graph.valueField });
    //       });
    //     });
    //   });

    //   this.apollo
    //     .query({
    //       query: GET_METRICS,
    //       variables: {
    //         recommenderKeysValueNamesInput: this.recommenderKeysValueNamesInput
    //       }
    //     }).subscribe((response) => {
    //       this.metricDatas = response.data['getMetrics'];
    //       this.recommenderKeysValueNamesInput = [];
    //       if (this.metricDatas.length > 0) {
    //         this.buildChart();
    //       }
    //     });
    // }

    combineLatest([this.stateService.medicalContentObs, this.patientStateService.diseaseIdSubject$]).subscribe(([medicalContent, diseaseId]) => {
      this.medicalContents = medicalContent;
      if (this.medicalContents && diseaseId) {
        const diseaseConfig = this.medicalContents.diseases.find((disease) => disease.id === diseaseId);
        this.configGraphs = diseaseConfig.graphs;
      }
      if (this.configGraphs?.length > 0) {
        this.title = this.configGraphs[0].title[this.lang];
        this.configGraphs.forEach((configGraph) => {
          configGraph.rows.forEach((row) => {
            this.type = row[0].type;
            row.forEach((graph) => {
              this.flattenConfigGraphRows.set(graph.recommenderKey, graph);
              this.recommenderKeysValueNamesInput.push({ recommenderKey: graph.recommenderKey, valueNames: graph.valueField });
            });
          });
        });

        this.apollo
          .query({
            query: GET_METRICS,
            variables: {
              recommenderKeysValueNamesInput: this.recommenderKeysValueNamesInput
            }
          }).subscribe((response) => {
            this.metricDatas = response.data['getMetrics'];
            this.recommenderKeysValueNamesInput = [];
            if (this.metricDatas.length > 0) {
              this.buildChart();
            }
          });
      }
    }, error => {
      console.log(error)
    });

  }

  buildChart() {
    this.configGraphs.forEach((configGraph) => {
      this.rowsChartData = [];
      configGraph.rows.forEach((row) => {
        const metricData = [];
        row.forEach((graph) => {
          metricData.push(this.metricDatas.find((metricData) => metricData.recommenderKey === graph.recommenderKey));
        });
        switch (row[0].type) {
          case 'bar':
            this.rowsChartData.push(this.buildBarChartProps(metricData));
            break;
          case 'line':
            this.rowsChartData.push(this.buildLineChartProps(metricData));
            break;
        }
      });
    });
  }

  buildBarChartProps(metricDatas: MetricDatas[]): ChartConfiguration<'bar'>['data'] {
    return {
      labels: metricDatas.map((metricData, index) => this.flattenConfigGraphRows.get(metricData.recommenderKey).xAxisLabel[this.lang]),
      datasets: this.buildBarDataSet(metricDatas)
    }
  }

  buildBarDataSet(metricDatas: MetricDatas[]) {
    const dataSets = [];
    metricDatas.forEach((metricData, i) => {
      metricData.values[0].data.slice(this.limit * -1).forEach((metric, j) => {
        dataSets[j] = dataSets[j] || {
          data: [],
          label: metric.time,
          backgroundColor: metricData.values[0].data.slice(this.limit * -1).length - 1 === j ? '#16A0C1' : '#BDBDBD50',
          categoryPercentage: 0.5,
          minBarLength: 7
        };
        dataSets[j].data[i] = metric.value;
      });
    });
    return dataSets;
  }

  buildLineChartProps(metricDatas: MetricDatas[]): ChartConfiguration<'line'>['data'] {
    return {
      labels: this.getLineChartLabels(metricDatas),
      datasets: this.buildLineDataSet(metricDatas)
    }
  }

  getLineChartLabels(metricDatas: MetricDatas[]): string[] {
    const labels = [];
    metricDatas[0].values[0].data.forEach((metric) => {
      const utcDate = moment.utc(metric.time).toDate();
      labels.push(moment(DateTimeUtilities.convertTZ(utcDate, this.medicalContents.timeZone)).format(this.medicalContents?.formatting.shortDateFormat.replace("dd", "DD")));
    });
    return labels;
  }

  buildLineDataSet(metricDatas: MetricDatas[]) {
    const dataSets = [];
    metricDatas.forEach((metricData, i) => {
      let lineData = [];
      metricData.values[0].data.forEach((metric, j) => {
        lineData.push(metric.value);
      });
      dataSets.push({
        data: lineData,
        label: this.flattenConfigGraphRows.get(metricData.recommenderKey).xAxisLabel[this.lang],
        borderColor: this.flattenConfigGraphRows.get(metricData.recommenderKey).color,
        backgroundColor: this.flattenConfigGraphRows.get(metricData.recommenderKey).color,
        pointBackgroundColor: this.flattenConfigGraphRows.get(metricData.recommenderKey).color,
        pointStyle: 'circle',
        borderDash: [5, 5],
        pointRadius: 4,
      });
    });
    return dataSets;
  }

  setTab() {
    this.navigationService.setTab('questionnaire');
  }

  // openPopup() {
  //   this.dialog.open(LineGraphComponent, {
  //     maxWidth: '50vw',
  //     viewContainerRef: this.viewRef,
  //     data: { chartDatas: this.rowsChartData[0], customLegend: true, type: 'line' },
  //   });
  // }
}