import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangType, StateService } from '../../../core/services/state.service';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    "(window:click)": "closeDropdown()"
  }
})
export class HeaderComponent implements OnInit {
  lang: LangType;
  currentUserInitials: string;
  currentUserFullName: string;
  isSso: boolean;
  constructor(private stateService: StateService, private translate: TranslateService, private authService: AuthService, 
    private router: Router) {

  }

  ngOnInit(): void {
    this.stateService.lang.subscribe((lang) => {
      this.lang = lang;
      this.translate.use(lang);
    });

    this.stateService.sso.subscribe((data) => {
      this.isSso = data;
    });

    this.setCurrentUserNameAndInitials();
  }

  currentLanguage() {
    return this.translate.currentLang;
  }

  setCurrentUserNameAndInitials() {
    const currentUser = sessionStorage.getItem('currentUser') ? JSON.parse(sessionStorage.getItem('currentUser')) : undefined;
    this.currentUserInitials = currentUser ? (currentUser.firstName.substring(0, 1) + currentUser.lastName.substring(0, 1)).toUpperCase() : '';
    this.currentUserFullName = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '';
  }

  nlLanguage(): void {
    this.switchLanguage('nl');
    this.stateService.setLang(LangType.NL);
  }

  enLanguage(): void {
    this.switchLanguage('en');
    this.stateService.setLang(LangType.EN);
  }

  switchLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('locale', language);
  }

  signOut() {
    this.authService.logout();
  }

  displayMenu($event) {
    $event.stopPropagation();
    document.getElementById("profile").classList.toggle("show");
  }

  toggleLang($event) {
    $event.stopPropagation();
    document.getElementById("languages").classList.toggle("show");
  }
  closeDropdown() {
    document.getElementById("languages").classList.remove('show');
    document.getElementById("profile").classList.remove("show");
  }

  navigateToOverview() {
    if(!this.isSso) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
    }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl('/overview');
    }
  }
}
