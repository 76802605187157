import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog'
import { DialogResponse } from 'src/app/core/constants/dialog_response.constant'

@Component({
  selector: 'reject-recommended-scenario-modal.component',
  templateUrl: 'reject-recommended-scenario-modal.component.html',
})
export class RejectRecommendedScenarioModal {
  constructor(public dialogRef: MatDialogRef<RejectRecommendedScenarioModal>) { }

  closeConfirm() {
    this.dialogRef.close(DialogResponse.CONFIRM);
  }

  closeCancel() {
    this.dialogRef.close(DialogResponse.CANCEL);
  }
}