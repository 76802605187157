import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicalContentNames } from 'src/app/core/model/common.interface';
import { Activity } from 'src/app/core/model/patient.interface';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { getTranslationForLanguage } from 'src/app/shared/pipes/translatable.pipe';

@Component({
  selector: 'app-delete-activity',
  templateUrl: './delete-activity.component.html',
  styleUrls: ['./delete-activity.component.scss']
})
export class DeleteActivityComponent implements OnInit {


  lang: LangType;
  calendarOpenDate: Date;
  carePathwayActivities: Activity[];
  selectedActivityName: string;
  medicalContentNames: MedicalContentNames;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private stateService: StateService,
              private dialogRef: MatDialogRef<DeleteActivityComponent>, private patientService: PatientService,
              private patientStateService: PatientStateService) {
                this.stateService.medicalContentNamesObs.subscribe(data => {
                  this.medicalContentNames = data;
              });
  }

  ngOnInit(): void {
    this.stateService.lang.subscribe(res => {
      this.lang = res;
    });
    this.patientStateService.carePathWayActivities$.subscribe((activities: Activity[]) => {
      this.carePathwayActivities = activities;
    });
    const selectedActivityDetails = this.carePathwayActivities.filter((data) => {
      return data.activityId === this.data;
    })[0];
    this.selectedActivityName = this.medicalContentNames?.activities[selectedActivityDetails['activityDefinitionId']]?.title[this.lang]
  }

  delete(): void {
    this.patientService.deleteActivity(this.stateService.providerId, this.patientStateService.patientId, this.data)
    .subscribe(() => {
      this.carePathwayActivities = this.carePathwayActivities.filter((data) => {
        return data.activityId !== this.data;
      });
      this.patientStateService.setCarePathwayActivites = [...this.carePathwayActivities];
      this.dialogRef.close();
    });
  }
}
