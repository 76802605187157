import { gql } from 'apollo-angular';
import { PatientOverviewSource, SortOrder, PatientOverviewOrderBy, NOTIFICATION_TYPES } from './app.constants'


export const GET_PATIENT_QUERY = gql`
query GetPatient {
  getPatient {
    diseasesAndPractitioners
    dob
    email
    externalId
    firstName
    gender
    lastName
    patientId
    initials
    programStartDate
    telephoneNumber
    departments
  }
}
`;

export const GET_ACTIVITIES_QUERY = gql`query GetActivities {
  getActivities {
    activityDefinitionId
    activityId
    datetime
    details {
      en
      nl
    }
    diseaseId
    name {
      en
      nl
    }
    providerPatientId
    questionnaireId
    statusNew
    source
    type
  }
}`;

interface ConditionInput{
  questionnaireId: string,
  conditionId: string,
  measureName: string
  componentId: string
}

export const GET_LAST_X_SCORES = gql`query GetLastXQuestionnaireScores($conditionInput: [ConditionInput]!, $limit: Int!) {
  getLastXQuestionnaireScores(conditionInput: $conditionInput, numberOfScores: $limit) {
    conditions {
      conditionScores {
        time
        value
      }
      conditionId
    }
  }
}`

export const GET_RECOMMENDER_KEYS = gql`query GetRecommenderKeys($keys: [String]) {
  getRecommenderKeys(keys: $keys)
}`;

export const SAVE_PATIENT_PROFILE_DATA = gql`mutation savePatientProfileData($inputJson: String) {
  savePatientProfileData(inputJson: $inputJson)
}`;

export const ACTIVATE_SCENARIO = gql`
mutation ActivateScenario($diseaseId: String, $scenarioIdToActivate: String, $startDate: String, $startOffset: Int) {
  activateScenario(input: {diseaseId: $diseaseId, scenarioIdToActivate: $scenarioIdToActivate, startDate: $startDate, startOffsetInDays: $startOffset}) {
      addedActivities {
        activityDefinitionId
        activityId
        name {
            en
            nl
        }
        type
        details {
            en
            nl
        }
        providerPatientId
        diseaseId
        datetime
        status
        questionnaireId
      }
      canceledActivities {
        activityId
        name {
            en
            nl
        }
        type 
        details {
            en
            nl
        }
        providerPatientId
        diseaseId
        datetime
        status
        questionnaireId
      }
  }
}
`;


export const DEACTIVATE_SCENARIO = gql`
mutation DeActivateScenario($diseaseId: String, $scenarioIdToDeactivate: String) {
  deactivateScenario(input: {diseaseId: $diseaseId, scenarioIdToDeactivate: $scenarioIdToDeactivate}) {
      canceledActivities {
        activityId
        name {
            en
            nl
        }
        type
        details {
            en
            nl
        }
        providerPatientId
        diseaseId
        datetime
        status
        questionnaireId
      }
  }
}
`;

export const GET_ADDON = gql`query GetAddons($addonId: String!, $diseaseId: String!, $scenarioId: String!) {
  getAddons(addOnIds: $addonId, diseaseId: $diseaseId, scenarioId: $scenarioId) {
    id
    intervals {
      activityDefinitionId
      name {
        en
        nl
      }
      offset
      questionnaireId
      scenarioActivityId
      title {
        en
        nl
      }
      type
    }
  }
}`;

type InputActivateAddon = {
  addonId: string,
  startDate: string,
  startInterval: Number
};


export const SUBMIT_PRACTITIONER_QUESTIONNAIRE = gql`
mutation SubmitPractitionerQuestionnaireResponse($input: InputQuestionnaireResponse){
  submitPractitionerQuestionnaireResponse(input: $input)
}
`

export const ACTIVATE_ADDON = gql`
mutation ActivateAddons($diseaseId: String!, $scenarioId: String!, $addonsToActivate: [AddonToActivate]! ) {
  activateAddons(diseaseId: $diseaseId, scenarioId: $scenarioId, addonsToActivate: $addonsToActivate) {
      addedActivities {
        activityId
        name {
            en
        }
        type
        details {
            en
        }
        providerPatientId
        diseaseId
        datetime
        status
        source
        questionnaireId
        activityDefinitionId
      }
  }
}`;

type DiseasePractitionerMappingInput = {
  diseaseId: string,
  practitionerId: string,
}


type UpdatePatientInput = {
  patientid: string,
  firstName: string,
  lastName: string,
  initials: string,
  gender: string,
  dob: string,
  email: string,
  telephoneNumber: string,
  externalId: string,
  diseasePractitionerMapping: [DiseasePractitionerMappingInput],
  departments: string[]
}


export const UPDATE_PATIENT = gql`
mutation UpdatePatient($input: UpdatePatientInput) {
  updatePatient(input: $input)
}`;

export const DELETE_PATIENT = gql`
mutation DeletePatient($patientId: String!) {
  deletePatient(patientId: $patientId)
}`;

export const DEACTIVATE_ADDON = gql`
mutation DeactivateAddon($diseaseId: String!, $scenarioId: String!, $addonIdsToDeactivate: [String!]!) {
  deactivateAddons(diseaseId: $diseaseId, scenarioId: $scenarioId, addonIdsToDeactivate: $addonIdsToDeactivate) {
      canceledActivities {
        activityId
        name {
            en
        }
        type
        details {
            en
        }
        providerPatientId
        diseaseId
        datetime
        status
        questionnaireId
      }
  }
}`;

type InputAddActivity = {
  providerId: String,
  patientId: String
  activityId: String,
  datetime: String,
  activityDefinitionId: String
  diseaseId: String,
  type: String
}

export const ADD_ACTIVITY = gql`mutation MyMutation($input: InputAddActivity) {
  addActivity(input: $input) {
    activityDefinitionId
    activityId
    datetime
    details {
      en
      nl
    }
    diseaseId
    name {
      en
      nl
    }
    providerPatientId
    questionnaireId
    source
    status
    statusNew
    type
  }
}`;

export const GET_PHYSICIANS_BY_DISEASE = gql`query GetPhysiciansByDisease($diseaseId: String!) {
  getPhysiciansByDisease(diseaseId: $diseaseId) {
    diseaseId
    practitioners {
        id
        name
    }
  }
}`;

type NotificationType = NOTIFICATION_TYPES;

type PatientOverviewFilterBy = {
  diseases: [String]
  scenarios: [String]
  notificationTypes: [NotificationType]
}

type PatientOverviewInput = {
  source: PatientOverviewSource
  filterBy: PatientOverviewFilterBy
  orderByField: PatientOverviewOrderBy
  sortOrder: SortOrder
  limit: number
  offset: number
  search: String
}

export const GET_OVERVIEW = gql`query GetOverview($patientOverviewInput: PatientOverviewInput) {
  getPatientOverview(patientOverviewInput: $patientOverviewInput) {
    count
    patients {
      birthDate
      externalId
      initials
      lastLogin
      lastName
      notifications {
        notificationId
        message
        score
        type
      }
      patientId
      diseaseId
      scenarioId
      
    }
  }
}`;

export const GET_TREATMENT_PLAN_FILTER = gql`query GetTreatmentPlanFilter{
  getTreatmentPlanFilter {
    diseaseId
    diseasename {
      en
      nl
    }
    scenarioId
    scenarioname {
      en
      nl
    }
  }
}
`;

type InputReadNotification = {
  patientId: String
  diseaseId: String
  notificationIds: [String]
}
export const READ_NOTIFICATION = gql`
mutation ReadNotification($input: InputReadNotification) {
  readNotification(input: $input) {
    notificationId
  }
}`;

export const GET_CURRENT_TREATMENT_PLAN =  gql`query GetCurrentTreatmentPlan {
  getCurrentTreatmentPlan {
    activeaddons {
      addonId
      startDate
      startInterval
      startOffset
    }
    activescenarios {
      startDate
      startInterval
      startOffset
      scenarioId
    }
  }
}`;

export const GET_SCENARIO_ACTIVITIES = gql`query GetScenarioActivities($scenarioId: String, $startDate: String, $startOffsetInDays: Int) {
  getScenarioActivities(scenarioId: $scenarioId, startDate: $startDate, startOffsetInDays: $startOffsetInDays) {
    activityDefinitionId
    activityId
    datetime
    questionnaireId
    type
  }
}`;


export const GET_CONVERSATIONS = gql `query GetConversations {
  getConversations {
    items {
      conversation {
        category
        createdAt
        status
        providerConversationId
      }
    }
  }
}
`;

export const CREATE_CONVERSATION = gql `
mutation MyMutation {
  createConversation {
    category
    createdAt
    providerConversationId
    status
  }
}`

type InputSendMessageToConversation = {
	conversationId: string,
	contents: string
}

export const SEND_MESSAGE = gql`
mutation sendMessage($input: InputSendMessageToConversation!) {
  sendMessageToConversation(input: $input) {
    contents
    createdAt
    createdAtMessageId
    providerConversationId
    senderType
  }
}`


type OnboardPatientInput = {
  firstName: string,
  lastName: string,
  initials: string,
  gender: string,
  dob: string,
  email: string,
  telephoneNumber: string,
  externalId: string,
  programStartDate: string,
  diseasePractitionerMapping: [DiseasePractitionerMappingInput]
}



export const CREATE_PATIENT = gql`
mutation onboardPatient($input: OnboardPatientInput!) {
  onboardPatient(input: $input) {
      patientId
  }
}`;

type MetricRecommenderKeyValueNames = {
  recommenderKey: string,
  valueNames: [string]
}

export const GET_METRICS = gql `query MyQuery($recommenderKeysValueNamesInput: [MetricRecommenderKeyValueNames]) {
  getMetrics(recommenderKeysValueNamesInput: $recommenderKeysValueNamesInput) {
    recommenderKey
    values {
      data {
        time
        value
      }
      valueName
    }
  }
}`;


export const GET_MESSAGE_BY_CONVERSATION_ID = gql `query GetMessagesByConversationId($conversationId: String!, $limit: Int, $nextToken: String) {
  getMessagesByConversationId(conversationId: $conversationId) {
    conversation {
      category
      status
      messages(limit: $limit, nextToken: $nextToken) {
        items {
          contents
          createdAt
          createdAtMessageId
          providerConversationId
          senderId
          senderName
          senderType
        }
        nextToken
      }
    }
  }
}`