import {Component} from '@angular/core';


@Component({
    selector: 'app-questionnaire-response',
    templateUrl: './questionnaire-response.component.html',
    styleUrls: ['./questionnaire-response.component.scss']
})
export class QuestionnaireResponseComponent {
    selectedIndexBinding: number = 0;
    constructor() {
        if(JSON.parse(sessionStorage.getItem('showDetailsTab')) == true) {
            this.selectedIndexBinding = 1;
            sessionStorage.removeItem('showDetailsTab');
        }
    }
}
