<div class="container auth-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="">
    <div>
      <img src="../../../assets/images/logo/dear_logo.png" alt="DEARHealth">
    </div>
    <form [formGroup]="form">
      <div>
        <mat-label>{{'passwordLess.email' | translate}}</mat-label>
      </div>
      <div class="field">
        <mat-form-field appearance="outline">
          <input #email autofocus matInput formControlName="email" placeholder="{{'passwordLess.enter_your_email' | translate}}" />
          <mat-error *ngIf="form.get('email')?.errors?.['email']">{{'passwordLess.Invalid_mail_code' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="text-center">
        <button mat-raised-button
          class="mat-focus-indicator btn btn-primary dh-login shadow-none mat-flat-button mat-button-base"
          (click)="initiateEmailVerification()">{{'passwordLess.login' | translate}}</button>
      </div>
    </form>
  </div>
</div>