import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  QuestionnaireComponentResponse,
  QuestionnaireResponse,
  QuestionResponse,
  ResponseOption,
} from '../../../../../core/model/patient.interface';
import { PatientService } from '../../../../../core/services/patient.service';
import { TranslateService, } from '@ngx-translate/core';
import {
  LangType,
  StateService,
} from '../../../../../core/services/state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ECARE_QUESTIONNAIRE,
  scoreSmiles,
} from '../../../../../core/constants/questionnaire.constant';
import { APP_CONSTANTS } from '../../../../../core/constants/app.constants';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSelectChange } from '@angular/material/select';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { getTranslationForLanguage } from 'src/app/shared/pipes/translatable.pipe';
import { MedicalContent, Questionnaire } from '../../../../../core/model/common.interface'
import * as utils from '../utils'

const fractionTypes: string[] = ["cutoff", "nothing"]

@Component({
  selector: 'app-questionnaire-response-modal',
  templateUrl: './questionnaire-response-modal.component.html',
  styleUrls: ['./questionnaire-response-modal.component.scss'],
})
export class QuestionnaireResponseModalComponent
  implements OnInit {
  ECARE = ECARE_QUESTIONNAIRE;

  currentResponse: QuestionnaireResponse;
  previousResponse: QuestionnaireResponse;

  comparison: ResponseComparison[];
  compareEnabled = false;
  columns: string[] = ['questionnaire', 'result'];

  dateCompletedColumns: string[] = ['date-completed', 'current-date-completed'];

  questionColumns: string[] = ['question'];
  responseColumns: string[] = ['response'];

  ecareDates: string[] = [];

  lang: LangType;
  smiles: Map<string, string> = scoreSmiles;
  defaultImagePath = APP_CONSTANTS.DEFAULT_IMAGE_PATH;
  dateTimeFormat: string;
  medicalContent: MedicalContent
  public sourceId: string;
  public questionnaireType: string;
  public questionnaireId: string;
  public questionnaireData: Questionnaire
  public questionnaireName: string


  sourceDate: string = null;
  compareDate: string = null;

  constructor(
    public dialogRef: MatDialogRef<QuestionnaireResponseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private patientService: PatientService,
    private translate: TranslateService,
    private stateService: StateService,
    private patientStateService: PatientStateService
  ) {
    this.sourceId = data['sourceId'];
    this.questionnaireType = data['type'];
    this.questionnaireId = data['questionnaireId'];
    this.questionnaireData = data['questionnaireData'];
    this.questionnaireName = data['questionnaireName']
    this.getEcareResponseDates(this.questionnaireId)
  }

  ngOnInit(): void {
    this.stateService.lang.subscribe((res) => {
      this.lang = res;
    });
    this.getResponses();
    this.medicalContent = this.stateService.getMedicalContent;
    this.dateTimeFormat = this.medicalContent.formatting.shortDateFormat
  }

  getResponses(selectedCompareDate?: string, selectedSourceDate?: string): any {
    this.patientStateService.patientIdSubject$.subscribe(
      (patientId) => {
        this.patientService
          .getQuestionnaireResponseComparison(
            patientId,
            this.sourceId,
            selectedCompareDate,
            selectedSourceDate
          )
          .subscribe((response) => {
            this.currentResponse = response.current;
            this.previousResponse = response.previous;
            this.comparison = [];

            if (this.currentResponse?.dateCompleted) {
              this.currentResponse.dateCompleted = this.currentResponse.dateCompleted;
            }

            this.currentResponse.componentResponses.forEach((r, index) => {
              let compare: QuestionnaireComponentResponse = null;

              if (
                this.previousResponse != null &&
                index < this.previousResponse.componentResponses.length
              ) {
                compare = this.previousResponse.componentResponses[index];
              }

              this.comparison.push(new ResponseComparison(r, compare));
              if (!this.sourceDate) {
                this.sourceDate = this.currentResponse.dateCompleted;
              }
              if (!this.compareDate) {
                this.compareDate = this.previousResponse?.dateCompleted;
              }
            });
          });
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.currentResponse = null;
          this.previousResponse = null;
        }
      }
    );
  }

  getResponseOptionsToView(options: ResponseOption[]): string {
    return Array.isArray(options) ?
     options.map((o) =>  getTranslationForLanguage(o.optionText, this.lang)).join(', ') :  '';
  }

  getComponentScore(component: QuestionnaireComponentResponse) {
    const componentData = this.getComponentData(component.component.id)
    return utils.showComponentAsFraction(componentData)
      ? `${component.score} / ${component.maxScore}`
      : `${Math.round(component.score)}%`
  }

  getQuestionnaireScore(response: QuestionnaireResponse) {
    return utils.showQuestionnaireAsFraction(this.questionnaireData)
      ? `${response.score} / ${utils.getMaxScore(response)}`
      : `${Math.round(response.score)}%`

  }

  isScorable() {
    return this.questionnaireData.scorable || this.questionnaireData.isHealthAssesment
  }

  getComponentData(componentId: string) {
    return this.questionnaireData.components.filter(component => component.id == componentId)[0];
  }

  enableComparison(event: MatSlideToggleChange, questionnaireId: string) {
    this.compareEnabled = event.checked;
    if (event.checked) {
      this.columns.push('compare');
      this.dateCompletedColumns.push('previous-date-completed');
    } else {
      this.columns.pop();
      this.dateCompletedColumns.pop();
    }
  }

  getEcareResponseDates(questionnaireId: string): void {
    this.patientStateService.patientIdSubject$.subscribe((patientId) => {
      this.patientService
        .getQuestionnaireResponseDates(patientId, questionnaireId)
        .subscribe((dates) => {
          dates.map((date) => {
            this.ecareDates.push(date);
          });
        });
    });
  }

  selectEcareDate(event: MatSelectChange): void {
    if (event.source.id === 'source_date_select') {
      this.sourceDate = event.value;
    }
    if (event.source.id === 'compare_date_select') {
      this.compareDate = event.value;
    }
    if (this.compareDate && this.sourceDate) {
      this.getResponses(this.compareDate, this.sourceDate);
    }
  }
}

export interface IResponseComparison {
  current: QuestionnaireComponentResponse;
  compare: QuestionnaireComponentResponse;
}

class QuestionResponseComparison {
  current: QuestionResponse;
  compare: QuestionResponse;
  constructor(current: QuestionResponse, compare: QuestionResponse) {
    this.current = current;
    this.compare = compare;
  }
}
class ResponseComparison implements IResponseComparison {
  current: QuestionnaireComponentResponse;
  compare: QuestionnaireComponentResponse;
  responses: QuestionResponseComparison[];
  constructor(
    current: QuestionnaireComponentResponse,
    compare: QuestionnaireComponentResponse
  ) {
    this.current = current;
    this.compare = compare;

    if (compare) {
      const prev = compare.responses.reduce((map, obj) => {
        map[obj.questionId] = obj;
        return map;
      }, {});

      this.responses = this.current.responses.map((val) => new QuestionResponseComparison(val, prev[val.questionId]))
        .filter((val) => (val.current?.responseText || val.compare?.responseText));
    } else {
      this.responses = this.current.responses.map((val) => new QuestionResponseComparison(val, null))
        .filter((val) => val.current?.responseText);
    }
  }
}
