import {services} from './services';

// TODO: Make the services overrideble as function method.

export const environment = {
    env: 'dev',
    production: false,
    endpoint: '/api',
    medicalContentEndpoint: 'https://medical-contents.dev.dearhealth.com',
    practitionerAuthTokenApi: 'https://adrws577z2.execute-api.us-east-1.amazonaws.com/dev/api/auth/token?providerId=',
    practitionerPatientAccessApi: 'https://8hxvcyyu4g.execute-api.us-east-1.amazonaws.com/dev/api/auth/patient/token?patientId=',
    region: 'us-east-1',
    launchDarklyId: '636a266cda69c411211e13c3',
    cognitoConfig: {
      region: 'eu-west-2',
      userPoolId: 'eu-west-2_5wFFWS6Ql',
      clientId: '5ok8ptnv0pfku0538li157nfqn'
    },
    services
};
