<form [formGroup]="formGroup" [ngClass]="{'label-container':!isEditable}" autocomplete="off">
    <form-field [isEdit]="isEditable" label="{{'create_patient.email_text' | translate }}"
        [value]="formGroup.value.email ? formGroup.value.email : '-'">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>{{"create_patient.email_text" | translate}}</mat-label>
            <input matInput formControlName="email" required>
            <mat-error *ngIf="formGroup.get('email').hasError('required')">
                {{"required_field_error_text" | translate}}</mat-error>
        </mat-form-field>
    </form-field>

    <form-field [isEdit]="isEditable" label="{{'create_patient.telephone_text' | translate }}"
        [value]="formGroup.value.telephoneNumber ? formGroup.value.telephoneNumber : '-'">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>{{"create_patient.telephone_text" | translate}}</mat-label>
            <input matInput formControlName="telephoneNumber" required (blur)="changeTelephoneNumber()">
            <mat-error *ngIf="formGroup.get('telephoneNumber').hasError('required')">
                {{"required_field_error_text" | translate}}</mat-error>
            <mat-error *ngIf="formGroup.get('telephoneNumber').hasError('pattern')">
                {{"create_patient.invalid_phone_number" | translate}}</mat-error>
        </mat-form-field>
    </form-field>

    <form-field [isEdit]="isEditable" label="{{'create_patient.mrn_text' | translate }}"
        [value]="formGroup.value.externalId ? formGroup.value.externalId : '-'" [isEditScreen]="isEditScreen">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>{{"create_patient.mrn_text" | translate}}</mat-label>
            <input matInput formControlName="externalId" required>
            <mat-error *ngIf="formGroup.get('externalId').hasError('required')">
                {{"required_field_error_text" | translate}}</mat-error>
        </mat-form-field>
    </form-field>
</form>