let resultsPromise: Record<string,Promise<unknown>> = {};

export function preventMultipleConcurrentExecutions<T>(key: string,fn: () => Promise<T>): Promise<T>{
  if(resultsPromise[key]){
    return resultsPromise[key] as Promise<T>;
  }
  resultsPromise[key] = new Promise((resolve, reject) => {
     fn()
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resultsPromise[key] = null);
  });
  return resultsPromise[key] as Promise<T>;
};