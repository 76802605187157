<div>
    <div>
        <h2 mat-dialog-title>{{"create_patient.new_patient_text" | translate}}</h2>
    </div>
    <mat-dialog-content class="mat-typography">
        <mat-horizontal-stepper #stepper>
            <mat-step [stepControl]="generalFormGroup">
                <ng-template matStepLabel>{{"create_patient.general_text" | translate}}</ng-template>
                <app-general-form [formGroup]="generalFormGroup" [isEditable]="true"></app-general-form>
            </mat-step>
            <mat-step [stepControl]="contactFormGroup">
                <ng-template matStepLabel>{{"create_patient.technical_text" | translate}}</ng-template>
                <app-contact-form [formGroup]="contactFormGroup" [isEditable]="true"></app-contact-form>
            </mat-step>
            <mat-step [stepControl]="careFormGroup">
                <ng-template matStepLabel>{{"create_patient.care_text" | translate}}</ng-template>
                <app-care-form [formGroup]="careFormGroup" [isEditable]="true"></app-care-form>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-dialog-content>
    <mat-dialog-actions align="end">

        <button class="mat-focus-indicator dh-btn dh-btn-secondary mat-button mat-button-base" (click)="dialogRef.close(false);"
        type="button" *ngIf="stepper.selectedIndex === 0">{{"close" | translate}}</button>

        <button class="mat-focus-indicator dh-btn dh-btn-secondary mat-button mat-button-base" (click)="goBack(stepper)"
            type="button" *ngIf="stepper.selectedIndex > 0">{{"back" | translate}}</button>

        <button class="dh-btn dh-btn-primary mat-button mat-button-base" (click)="goForward(stepper)" type="button"
            *ngIf="stepper.selectedIndex < 2">{{"next" | translate}}</button>

        <button class="dh-btn dh-btn-primary mat-button mat-button-base" (click)="createPatient()" type="button"
            [disabled]="!generalFormGroup.valid || !contactFormGroup.valid || !careFormGroup.valid"
            *ngIf="stepper.selectedIndex == 2">{{"confirm" | translate}}</button>

    </mat-dialog-actions>
</div>

