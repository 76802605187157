import { Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Activity, IEventActivityType } from 'src/app/core/model/patient.interface';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { DeleteActivityComponent } from './delete-activity/delete-activity.component';
import { UpdateActivityComponent } from './update-activity/update-activity.component';
import { datadogLogs } from '@datadog/browser-logs';
import { MedicalContent, MedicalContentNames } from 'src/app/core/model/common.interface';
import _ from 'lodash';

@Component({
  selector: 'app-edit-care-pathway',
  templateUrl: './edit-care-pathway.component.html',
  styleUrls: ['./edit-care-pathway.component.scss']
})
export class EditCarePathwayComponent implements OnInit {

  activityTypes: IEventActivityType[] = [];
  lang: LangType;
  medicalContent: MedicalContent;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  constructor(private patientService: PatientService, private stateService: StateService,
    private patientStateService: PatientStateService,
    @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialog, private viewRef: ViewContainerRef) {
      this.medicalContent = this.stateService.getMedicalContent;  
  }



  ngOnInit(): void {

    if (this.patientStateService.activityTypes.length === 0) {
      this.getActivityTypes();
    } else {
      this.activityTypes = this.patientStateService.activityTypes;
    }
    this.stateService.lang.subscribe(res => {
      this.lang = res;
    });

    this.patientStateService.carePathWayActivities$.subscribe((data: Activity[]) => {
      this.data.carePathWayActivities = _.cloneDeep(data);
    });
  }



  /**
  * Function call to get the details of activity Types
  */
  getActivityTypes() {
    const medicalContentNames: MedicalContentNames = this.stateService.getMedicalContentNames;
    const filteredDisease = this.medicalContent.diseases.filter((disease) => disease.id === this.patientStateService.diseaseId)[0];
    const uniquetypes = [...new Set(filteredDisease.availableActivities.map(item => item.type))];
    uniquetypes.forEach((type) => {
      const filteredTypes = filteredDisease.availableActivities.filter((activity) => activity.type === type);
      this.activityTypes.push({
        type: type,
        activities: filteredTypes,
        name: medicalContentNames.activityLabels[type]
      })
    });
    this.patientStateService.setActivityTypes = this.activityTypes;
  }

  /**
   * Function to open the add activity popup
   * @param value - value to pass to api call while saving
   */
  addActivity(value: { year: number, month: string, activityType: IEventActivityType }) {
    const dialogRef = this.dialogRef.open(UpdateActivityComponent, {
      viewContainerRef: this.viewRef,
      width: '450px',
      height: '250px',
      data: value,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
   * Funcion to open the edit activity popup
   */
  editActivity({ activityDetails }) {
    const editActivityData = {
      selectedActivityData: {
        startDate: new Date(activityDetails.date),
        activity: activityDetails
      },
      edit: true
    };

    this.dialogRef.open(UpdateActivityComponent, {
      viewContainerRef: this.viewRef,
      width: '400px',
      height: '250px',
      data: editActivityData,
      disableClose: true
    });
  }

  deleteActivity(cpActivityId: string) {
    this.dialogRef.open(DeleteActivityComponent, {
      viewContainerRef: this.viewRef,
      width: '400px',
      data: cpActivityId,
      disableClose: true
    });
  }

}
