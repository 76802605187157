import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AboutComponent } from '../about/about.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  page_url: string = window.location.href;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { }

  openAboutModal(): any {
    const modalRef = this.modalService.open(AboutComponent);
    modalRef.componentInstance.name = 'AboutComponent';
  }

}
