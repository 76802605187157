<div class="session-logout-page-wrapper">
    <div>
        <div>
            <img src="assets/images/logo/maintenance.png" alt="maintenance page">
        </div>
        <div class="info-text">{{'sso_session_logout_text' | translate}}</div>
        
        <div class="logo-container">
            <img src="assets/images/logo/logo.png" alt="DEARHealth">
        </div>
        
    </div>
</div>