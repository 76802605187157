import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()
export class NavigationService {

  private tabSubject$: BehaviorSubject<string> = new BehaviorSubject('summary');
  tabObs = this.tabSubject$.asObservable();

  private chatSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  chatObs = this.chatSubject$.asObservable();

  setTab(value: string): void {
    this.tabSubject$.next(value);
  }

  setChat(value: boolean): void {
    this.chatSubject$.next(value);
  }
}