import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class CommunicationService {

    private deactivateTreatmentPlanSource = new Subject<object>();
    private reloadTreatmentPlansSource = new Subject<Map<string, string>>();
    private reloadActivitiesSource = new Subject<void>();
    private reloadOverviewSource = new Subject<void>();
    private refreshOverviewSource = new Subject<string>();
    private rejectOpenRecommendationSource = new Subject<string>();
    private activateRecommendationScenarioSource = new Subject<string>();
    private reloadPatientPropertiesSource = new Subject<string>();

    deactivateTreatmentPlan$ = this.deactivateTreatmentPlanSource.asObservable();
    reloadTreatmentPlans$ = this.reloadTreatmentPlansSource.asObservable();
    reloadActivities$ = this.reloadActivitiesSource.asObservable();
    reloadOverview$ = this.reloadOverviewSource.asObservable();
    refreshOverviewSource$ = this.refreshOverviewSource.asObservable();
    rejectOpenRecommendation$ = this.rejectOpenRecommendationSource.asObservable();
    activateRecommendationScenario$ = this.activateRecommendationScenarioSource.asObservable();
    reloadPatientProperties$ = this.reloadPatientPropertiesSource.asObservable();

    deactivateTreatmentPlan(scenarioId: string, openRecommendationId: string): void {
        this.deactivateTreatmentPlanSource.next({scenarioId, openRecommendationId});
    }

    reloadTreatmentPlans(activityFilter?: Map<string, string>): void {
        this.reloadTreatmentPlansSource.next(activityFilter);
    }

    rejectOpenRecommendation(openRecommendationId: string): void {
        this.rejectOpenRecommendationSource.next(openRecommendationId);
    }

    reloadActivities(): void {
        this.reloadActivitiesSource.next();
    }

    reloadOverview() : void {
        this.reloadOverviewSource.next();
    }

    refreshOverviewCreatePatient(patientData: any) : void {
        this.refreshOverviewSource.next(patientData);
    }

    activateRecommendationScenario(scenario: string): void {
        this.activateRecommendationScenarioSource.next(scenario)
    }

    reloadPatientProperties(): void {
        this.reloadPatientPropertiesSource.next();
    }
}
