<div class="row feature-block">
    <div *ngIf="questionnaireScores && questionnaireScores?.conditions?.length > 0" class="card col-md-12 ">
        <div class="card-header pb-0 pt-4 pl-3">
            <div class="card-title m-0 pl-1 float-left">
                {{names[questionnaireType]?.name | translatable}}
            </div>
            <div class="m-0 float-right" style="width: 35%">
                <span class="float-left mr-4"><div class="square past"></div> {{'questionnaire_response.past_results' | translate }}</span>
                <span class="float-left ml-4"><div class="square latest"></div> {{'questionnaire_response.last_results' | translate }}</span>
                <span class="float-right"><a class="button-link" (click)="setTab()" >Details</a></span>
            </div>
        </div>
        <div class="card-content-row pr-3 pb-2">
            <div class="non-ha" [ngClass]="{'oneline-non-ha' :  shouldShowOneLine()}">
                <canvas baseChart [data]="componentData" [type]="'bar'" [options]="barChartOptions" >
                </canvas>
            </div>
            <div *ngIf="this.names[this.questionnaireType].components.length > 2" class="total" [ngClass]="{'online-total' :  shouldShowOneLine()}">
                <canvas baseChart [data]="totalData" [type]="'bar'" [options]="barChartOptions" >
                </canvas>
            </div>
        </div>
    </div>
</div>