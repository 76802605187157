import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormModalComponent } from '../form-modal/form-modal.component';
import * as moment from 'moment';
import { DateTimeUtilities } from 'src/app/dh-common/utilities/date-time.utilities';
import { MedicalContent } from 'src/app/core/model/common.interface';
import { StateService } from 'src/app/core/services/state.service';

@Component({
    selector: 'app-form-display',
    templateUrl: './form-display.component.html',
    styleUrls: ['../formly.component.scss'],
})
export class FormDisplayComponent {
    @Input() form: any;
    @Input() formData: any;
    @Input() displayMode: boolean;
    @Output() formSubmittedEvent = new EventEmitter<any>(); 


    flattenFormFields: any = [];
    medicalContent: MedicalContent

    constructor(private dialog: MatDialog, private stateService: StateService) { }
    ngOnChanges() {
        this.flattenFormFields = this.form.fields.reduce((acc, curr) => {
            return [...acc, ...curr.fieldGroup];
        }, []);
        this.medicalContent = this.stateService.getMedicalContent;
    }

    displayFieldValue(key: string, value: any): string {
        const field = this.flattenFormFields.find((field) => field.key === key);
        switch (field?.type) {
            case 'datepicker':
                return this.formatDate(value);
            case 'select':
            case 'radio':        
                return this.formatSelect(value, field);
            case 'number':
                return this.formatDecimals(value, field);
            case 'input':
                return this.formatDecimals(value, field);
            default:
                return value || '-';
        }
    }

    formatDecimals(value: any, field: any): string {
        if (value === null || value === undefined) {
            return '-';
        } else if (Number.isInteger(value)) {
            return value.toString();
        } else if (field.templateOptions.decimalPlaces) {
            return parseFloat(value).toFixed(field.templateOptions.decimalPlaces);
        } else {
            return value;
        }
    }

    formatSelect(value: any, field: any): string {
        const selectedOption = field.templateOptions.options.find((option) => option.value === value);
        return selectedOption?.label || '-';
    }

    formatDate(value: any): string {
        if (moment(value, moment.ISO_8601, true).isValid()) {
            const utcDate = moment.utc(value).toDate();
            return moment(DateTimeUtilities.convertTZ(utcDate, this.medicalContent.timeZone)).format(this.medicalContent?.formatting.shortDateFormat.replace("dd", "DD"));
        }
        return value || '-';
    }

    openFormModal() {
        this.flattenFormFields.forEach((field) => {
            this.formData[field.key] = this.formData[field.key] === null && (field.expressions && field.expressions['props.disabled'] === "true") ? '-' : this.formData[field.key];
            if((field.type === 'number' || field.type==='input') && field.templateOptions.decimalPlaces && field.templateOptions.decimalPlaces > 0 ) {
                this.formData[field.key] = parseFloat(this.formData[field.key]).toFixed(field.templateOptions.decimalPlaces)
            }
        });

        const dialogRef = this.dialog.open(FormModalComponent, {
            maxWidth: '50vw',
            data: { form: this.form, formData: { ...this.formData } },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.formData = result;
                const data = {
                    formName: this.form.formName,
                    formData: result,
                }
                this.formSubmittedEvent.emit(data);
            }
        });
    }
}