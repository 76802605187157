<div>
    <h2 mat-dialog-title>{{'multi_scenario.deactivate_scenario_title' | translate}}</h2>
    <mat-dialog-content class="mat-typography py-4">
        <div
            [innerHTML]="'multi_scenario.switch_deactivate_scenario_confirmation' | translate : { SCENARIO_NAME : scenarioName }">
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close class="dh-btn dh-btn-secondary" (click)=cancel()> {{'cancel' | translate }}</button>
        <button mat-button mat-dialog-close class="dh-btn dh-btn-primary" (click)=confirm()> {{'confirm' | translate }}</button>
    </mat-dialog-actions>
</div>

