import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientAuthResolver } from 'src/app/core/resolvers/patient.auth.resolver';
import { PatientComponent } from './patient.component';

const routes: Routes = [

  {
    path: ':patientId/questionnaire',
    component: PatientComponent,
    resolve: {patientacessToken: PatientAuthResolver},
  },
  {
    path: ':patientId/:editScreen',
    component: PatientComponent,
    resolve: {patientacessToken: PatientAuthResolver},
  },
  {
    path: ':patientId',
    component: PatientComponent,
    resolve: {patientacessToken: PatientAuthResolver},
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientRoutingModule { }
