<ng-container *ngIf="chartDatas">
        <div class="row">
                <!-- Legends on the left for big screens -->
                <div class="d-none d-lg-block" [ngStyle]="{'width': calculateLeftLegendWidth()}">
                        <div class="custom-legend-left d-flex flex-column flex-wrap">
                                <ng-container *ngTemplateOutlet="legendTemplate"></ng-container>
                        </div>
                </div>

                <!-- Chart on the right for big screens -->
                <div class="chart" [ngStyle]="{'width': calculateWidth()}">
                        <div>
                                <canvas baseChart [data]="chartDatas" [options]="chartOptions" [type]="type"
                                        [legend]="false" style="height: 320px;"></canvas>
                        </div>
                </div>

                <!-- Legends below the chart for small screens -->
                <div class="col-12 d-block d-lg-none">
                        <div class="custom-legend-bottom">
                                <ng-container *ngTemplateOutlet="legendTemplate"></ng-container>
                        </div>
                </div>
        </div>
</ng-container>

<ng-template #legendTemplate>
        <div *ngFor="let dataset of chartDatas.datasets; let i = index" class="card legend-card mb-2">
                <div class="card-body" (click)="toggleLine(i)" [ngClass]="{'disabled': dataset.hidden}">
                        <div class="d-flex justify-content-between">
                                <span class="">{{ dataset.label }}</span>
                                <span class="font-weight-bold" [ngStyle]="{'color': dataset.backgroundColor}">{{dataset.data[dataset.data.length -1] | number: '1.0-0' }} </span>
                        </div>
                        <div class="progress">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{'background-color': dataset.backgroundColor}"
                                    style.width="{{dataset.data[dataset.data.length -1]}}%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100">
                                </div>
                        </div>
                </div>
        </div>
</ng-template>

<!-- <ng-template #legendTemplate>
        <div class="row">
          <div *ngFor="let dataset of chartDatas.datasets; let i = index" class="col-md-4 mb-2">
            <div class="card legend-card">
              <div class="card-body" (click)="toggleLine(i)" [ngClass]="{'disabled': dataset.hidden}">
                <div class="d-flex justify-content-between">
                  <span class="">{{ dataset.label }}</span>
                  <span class="font-weight-bold" [ngStyle]="{'color': dataset.backgroundColor}">{{dataset.data[dataset.data.length -1] | number: '1.0-0' }} </span>
                </div>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" [ngStyle]="{'background-color': dataset.backgroundColor}"
                    style.width="{{dataset.data[dataset.data.length -1]}}%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template> -->
