import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { StateService } from '../../../core/services/state.service';
import { PatientService } from 'src/app/core/services/patient.service';

@Component({
  selector: 'app-health-assessment',
  templateUrl: './health-assessment.component.html',
  styleUrls: ['./health-assessment.component.scss']
})
export class HealthAssessmentComponent implements OnInit {

  mappedResponse: any;
  patientId: string;
  diseaseId: string;
  disId: string;
  providerId = '';
  totalScore: string;
  healthPercentage: '60';

  constructor(private http: HttpClient, private route: ActivatedRoute, private stateService: StateService,
              private patientService: PatientService) {
    this.patientId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getHealthAssesment();
  }

  getHealthAssesment(): any {
    this.totalScore = '42';
    this.mappedResponse = [];
  }
}
