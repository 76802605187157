<h2 mat-dialog-title> {{formName}}</h2>
<mat-dialog-content class="mat-typography dialog-content">
    <form [formGroup]="form">
        <div *ngFor="let fieldGroup of formFields; let i = index" class="row">
              <div *ngFor="let field of fieldGroup.fieldGroup; let j = index" [ngClass]="field.className" class="pl-0">
                <label [ngClass]="{'disabled-label':field?.expressions && field?.expressions['props.disabled'] == 'true'}">{{ field.templateOptions.label }}</label>
                <formly-form [form]="form" [fields]="[field]" [model]="formData"></formly-form>
              </div>
          </div>
      </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="mat-focus-indicator dh-btn dh-btn-secondary mat-button mat-button-base" mat-button (click)="onCancelClick()">Cancel</button>
    <button class="dh-btn dh-btn-primary mat-button mat-button-base"  mat-button [mat-dialog-close]="formData" (click)="saveProfileData()" cdkFocusInitial [disabled]="isSaveDisabled()">Save</button>
</mat-dialog-actions> 
