import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'scenario-not-found-modal.component',
  templateUrl: 'scenario-not-found-modal.component.html',
})
export class ScenarioNotFoundModal {
  constructor(public dialogRef: MatDialogRef<ScenarioNotFoundModal>) { }

  closeDialog() {
    this.dialogRef.close();
  }
}