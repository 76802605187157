<form [formGroup]="formGroup" [ngClass]="{'label-container':!isEditable}" autocomplete="off">
    <form-field [isEdit]="isEditable" label="{{'patient_profile.disease' | translate }}"
        [value]="diseaseValue" [isEditScreen]="isEditScreen">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>{{"create_patient.select_disease_text" | translate}}</mat-label>
            <mat-select formControlName="diseaseId" required (selectionChange)="getPhysicians($event.value)">
                <mat-option *ngFor="let dis of diseaseList" [value]="dis.id">
                    {{dis.name | translatable: lang}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form-field>
    <form-field [isEdit]="isEditable" label="{{'patient_profile.doctor' | translate }}"
        [value]="physicianValue">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>{{"create_patient.select_doctor_text" | translate}}</mat-label>
            <mat-select formControlName="practitionerId" required>
                <mat-option *ngFor="let phy of physiciansList" [value]="phy.id">
                    {{phy.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form-field>

    <form-field [isEdit]="isEditable" label="{{'create_patient.start_text' | translate }}"
        [value]="programStartDateValue"
        [isEditScreen]="emrIntergrationEnabled" >
        <mat-form-field appearance="outline" [ngClass]="{'col-md-6' : !isEditScreen}">
            <mat-label>{{'create_patient.start_text' | translate}}</mat-label>
                <input [required]="true" matInput (click)='progpicker.open()'
                formControlName='programStartDate' [matDatepicker]="progpicker" >
                <mat-datepicker-toggle matSuffix [for]="progpicker"></mat-datepicker-toggle>
                <mat-datepicker #progpicker></mat-datepicker>
                <mat-error *ngIf="formGroup.get('programStartDate').hasError('invalidDateFormat')"
                >{{"create_patient.inalid_date_text" | translate}}</mat-error
              >
        </mat-form-field>
    </form-field>

</form>