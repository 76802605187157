<h3 class="quote-style">{{(activity|async)?.name | translatable:lang}}</h3>
<hr>
<table [cellPadding]="5" style="width: 100%;" class="text-center">
    <tr>
        <td>
            <span>{{'care_pathway.activity_details.dates_to_complete' | translate}}</span>
        </td>
        <td>
            <span>{{'care_pathway.activity_details.current_state' | translate}}</span>
        </td>
        <td></td>
    </tr>
    <tr>
        <td>{{(activity|async)?.datetime | localdate: medicalContent.timeZone : medicalContent.formatting.shortDateFormat}}</td>
        <td>{{'care_pathway.activity_details.status.' + (activity|async)?.status | translate}}</td>
        <td>
<!--            <button type="button" class="btn btn-success">{{'care_pathway.activity_details.cancel_order' | translate}}</button>-->
        </td>
    </tr>
</table>
<div class="m-2">
    <button *ngIf="(activity|async)?.status == 'COMPLETED' && (activity|async)?.responseId" 
            mat-button class="dh-btn dh-btn-secondary mat-button mat-button-base" (click)="openQuestionnaire()" style="height: auto;">
       {{'questionnaire_response.questionnaire_title' | translate}}
    </button>
</div>

<hr>
<div id="footer">
    <button mat-button class="dh-btn dh-btn-secondary mat-button mat-button-base float-right shadow-none" (click)="onCancel()" style="height: auto;"> 
        {{'close' | translate }}
    </button>
</div>

