import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StateService } from './state.service';
import { AuthTokenResponse } from '../model/auth.interface';
import { PasswordLessAuthService } from './passwordless.service';
import { preventMultipleConcurrentExecutions } from '../../dh-common/utilities/async-utils'
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private httpClient: HttpClient;
    private currentPatientId: string;
    private patientAccessToken: string;
    endpoint = {
        exchange_token: '/api' + environment.services.auth + 'exchange-token',
        after_launch: environment.services.epic_auth + 'afterlaunch',
        dev_token: environment.services.dev + 'generate-dev-token',
        refresh_token: '/api' + environment.services.auth + 'login/practitioner/refresh-token',
        hix_authorize(providerId: string): string {
            return '/api' + environment.services.hix_auth.replace('{providerId}', providerId) + 'authorize';
        },
        practitioner_patient_access(patientId: string): string {
            return `${environment.practitionerPatientAccessApi}${patientId}`
        }
    };

    constructor(private http: HttpClient, private stateService: StateService, private router: Router,
        private httpFactory: HttpBackend, private passwordLessAuthService: PasswordLessAuthService) {
        this.httpClient = new HttpClient(httpFactory);
    }

    handleAuthResponse(response: AuthTokenResponse): void {
        sessionStorage.setItem('pracAuthToken', response.token);
        sessionStorage.setItem('providerId', response.providerId);
        this.stateService.setProviderId = response.providerId;
    }

    refreshToken(): Observable<any> {
        const data = {
            "refreshToken": this.getRefreshToken()
        };
        return this.httpClient.post(this.endpoint.refresh_token, data);
    }

    getDevToken(providerId?: string, practitionerId?: string, patientId?: string): Observable<AuthTokenResponse> {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        let params = new HttpParams();
        if (providerId) { params = params.set('provider_id', providerId); }
        if (practitionerId) { params = params.set('practitioner_id', practitionerId); }
        if (patientId) { params = params.set('patient_id', patientId); }

        const response$ = this.http.get<AuthTokenResponse>(this.endpoint.dev_token, { headers, params }).pipe(shareReplay(1));
        response$.subscribe(response => { this.handleAuthResponse(response) });
        return response$;
    }

    getTokenFromEpicAfterLaunch(code: string, state: string): Observable<AuthTokenResponse> {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        const params = new HttpParams().set('code', code).set('state', state);
        const response$ = this.http.get<AuthTokenResponse>(this.endpoint.after_launch, { headers, params }).pipe(shareReplay(1));
        response$.subscribe(response => this.handleAuthResponse(response));
        return response$;
    }

    getHixAuthorizationToken(providerId: string, physicianId: string, patientId: string, time: string,
        hash: string): Observable<AuthTokenResponse> {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        const params = new HttpParams().set('physicianId', physicianId).set('patientId', patientId).set('time', time).set('hash', hash);
        const response$ = this.http.get<AuthTokenResponse>(this.endpoint.hix_authorize(providerId), { headers, params })
            .pipe(shareReplay(1));
        response$.subscribe(response => this.handleAuthResponse(response));
        return response$;
    }

    getPractitionerToken(): string {
        return sessionStorage.getItem('pracAuthToken');
    }

    getRefreshToken(): string {
        return sessionStorage.getItem('refreshToken');
    }

    getPatientAccessToken(): string {
        return this.patientAccessToken;
    }

    async setCurrentPatientId(patientId: string, patientAccessToken: string | null = null): Promise<string> {
        return preventMultipleConcurrentExecutions('getPatientId', async () => {
            if (patientAccessToken) {
                this.patientAccessToken = patientAccessToken;
                return patientAccessToken;
            } else {
                const response = await this.http.get<string>(
                    this.endpoint.practitioner_patient_access(patientId), 
                    { headers: { 'Authorization': this.getPractitionerToken() } 
                }).toPromise();
                this.patientAccessToken = response['patientAccessToken'];
                sessionStorage.setItem('patientAccessToken', response['patientAccessToken']);
                return response['patientAccessToken'];
            }
        })
    }

    getApiToken(): string {
        return this.patientAccessToken ? this.patientAccessToken : this.getPractitionerToken();
    }

    tokenValid(): boolean {
        return !!sessionStorage.getItem('pracAuthToken');
    }

    logout(): void {
        sessionStorage.clear();
        this.stateService.resetPatientOverviewSettings();
        this.stateService.getSso ? this.router.navigateByUrl("/sessionlogout") : this.router.navigateByUrl("/email");
        this.patientAccessToken = null;
        this.passwordLessAuthService.signOut();
    }
}
