<div class="card col-md-12 care-pathway treatment-plan" *ngIf="hasDataLoaded && hasMedicalDataLoaded">
    <div class="card-header">
        <div class="card-title">
            {{'care_pathway_title' | translate}}
        </div>
        <div class="button-container" *ngIf='carePathWayActivities?.length > 0'>
            <a href="javascript:void(0);" (click)="openEditCarePathway();"
                class="button-link">{{'care_pathway.activity_details.edit' | translate}}</a>
        </div>
    </div>

    <div class="card-content row treatment-plan">
        <div class="col-md-12">
            <div class="row">
                    <div class="margin-side-25" *ngIf="carePathWayActivities?.length > 0 ;  else defaultMessage">
                        <app-monthly-calendar [medicalContent]="medicalContent"
                            [activities]="carePathWayActivities"></app-monthly-calendar>
                    </div>
            </div>
        </div>
    </div>
</div>
<ng-template #defaultMessage>
    <div class="margin-side-25" [innerHTML]="'care_pathway.no_activities' | translate"></div>
</ng-template>