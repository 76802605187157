import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private injector: Injector, private translateService: TranslateService) { }

  showError(error: string) {
    const snackbar = this.injector.get(MatSnackBar);
    this.translateService.get('X').subscribe((message: string) => {
      snackbar.open(error, message, {
        panelClass: 'error',
        duration:  2000
      });
    });
  }

  showHttpError(error: HttpErrorResponse) {
    if (error.status === 403 || error.status === 401) {
      this.translateService.get('error_message.http.403').subscribe((message: string) => {
          this.showError(message);
        });
    } else if (error.status === 404) {
      // Ignore, expected behaviour
    } else {
      this.translateService.get('error_message.http.other', {status: error.status}).subscribe((message: string) => {
        this.showError(message);
      });
    }
  }

  showGenericError() {
    this.translateService.get('error_message.other').subscribe((message: string) => {
      this.showError(message);
    });
  }

  showSuccessMessage(data: string) {
    const snackbar = this.injector.get(MatSnackBar);
    snackbar.open(data, 'X', {
      duration: 3 * 1000,
      panelClass: 'success'
    });

  }
}
