<app-loader></app-loader>

<div *ngIf="hasError" class="error-page-wrapper">
    <div>
        <div>
            <img src="assets/images/logo/error-img.jpg" alt="Error page">
        </div>
        <div class="error-text" *ngIf="hasNoPractitioner">{{'afterLaunch.no_provider_found' | translate}}</div>
        <div class="error-text no-patient" *ngIf="hasNoPatient">
            {{'afterLaunch.no_patient_found' | translate}}
            <p>{{'afterLaunch.no_patient_found1' | translate}}</p>
        </div>

        <div class="error-text" *ngIf="!hasNoPractitioner && !hasNoPatient" [innerHTML]="'error_message.other' | translate "></div>

        <div class="logo-container">
            <img src="assets/images/logo/logo.png" alt="DEARHealth">
        </div>
    </div>
</div>