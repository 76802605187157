import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {
    OF_LABEL = 'overview_page.paginator.of_label';

    public constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
        this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
    }

    public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
        return `0 / ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
    };

    public getAndInitTranslations(): void {
    this.translate.get([
        "overview_page.paginator.items_per_page",
        "overview_page.paginator.of_label",
        "overview_page.paginator.next_page",
        "overview_page.paginator.prev_page"
    ])
        .subscribe((translation: any) => {
        this.itemsPerPageLabel = translation["overview_page.paginator.items_per_page"];
        this.previousPageLabel = translation['overview_page.paginator.prev_page'];
        this.nextPageLabel = translation['overview_page.paginator.next_page'];
        this.OF_LABEL = translation["overview_page.paginator.of_label"];

        this.changes.next();
        });
    }
}