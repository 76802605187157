import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DhCommonModule } from '../../../dh-common/dh-common.module';
import { SharedModule } from '../../shared.module';
import { AfterLaunchComponent } from './after-launch.component';
import { PipesModule } from '../../pipes/pipes.module';



@NgModule({
  declarations: [
    AfterLaunchComponent
  ],
  imports: [
    CommonModule,
    DhCommonModule,
    SharedModule,
    PipesModule
  ]
})
export class AfterLaunchModule { }
