import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { StateService } from '../../../core/services/state.service';
@Component({
  selector: 'app-provider-selection',
  templateUrl: './provider-selection.component.html',
  styleUrls: ['./provider-selection.component.scss']
})
export class ProviderSelectionComponent {
 
  @Input() providers: any[];
  @Output() providerSelected = new EventEmitter<any>();
  
  filterdProvidersList: any[];
  selectedProvider: any;
  constructor(private stateService: StateService) {

  }

  ngOnChanges() {
    this.filterdProvidersList = [...this.providers];
  }

  onKey(searchValue: string) {
    this.filterdProvidersList = this.providers.filter(provider => provider.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
  }

onProviderCardClick(item: any) {
    this.selectedProvider = item;
    this.stateService.setProviderName = this.selectedProvider.id.replace(/\s+/g, '_');
    this.providerSelected.emit(this.selectedProvider);
  }
}
