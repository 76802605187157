import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Activity } from '../../../core/model/patient.interface';
import { Subscription } from 'rxjs';
import { distinct, filter } from 'rxjs/operators';
import { PatientService } from 'src/app/core/services/patient.service';
import { CommunicationService } from 'src/app/core/services/communication.service'
import { MatDialog } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import { EditCarePathwayComponent } from './edit-care-pathway/edit-care-pathway.component';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { GET_ACTIVITIES_QUERY } from 'src/app/core/constants/graphql.query.constants';
import _ from 'lodash'
import { StateService } from 'src/app/core/services/state.service';
import { MedicalContent } from 'src/app/core/model/common.interface';

@Component({
  selector: 'app-care-pathway',
  templateUrl: './care-pathway.component.html',
  styleUrls: ['./care-pathway.component.scss']
})
export class CarePathwayComponent implements OnInit {
  carePathWayActivities: Activity[];
  hasActivities = true;
  hasDataLoaded = false;
  hasMedicalDataLoaded = false;
  medicalContent: MedicalContent;
  private querySubscription: Subscription;

  constructor(private patientService: PatientService, private stateService: StateService,
    private patientStateService: PatientStateService, public dialog: MatDialog,
    private communicationService: CommunicationService,
    private viewRef: ViewContainerRef, private apollo: Apollo) {
    communicationService.reloadTreatmentPlans$.subscribe((activityFilter: Map<string, string>) => {
      this.reloadActivities(activityFilter);
    });
  }

  ngOnInit(): void {
    this.getCarePathwayEvents();
    this.stateService.medicalContentObs.subscribe((data) => {
      if(data !== null) {
       this.medicalContent = data;
       this.hasMedicalDataLoaded = true;
      }
    });
    this.patientStateService.carePathWayActivities$.subscribe((data: Activity[]) => {
      this.carePathWayActivities = _.cloneDeep(data);
    });
  }

  getCarePathwayEvents(): void {
    this.patientStateService.patientIdSubject$.pipe(filter(id => !!id), distinct()).subscribe(patientId => {
      this.querySubscription = this.apollo
        .query({
          query: GET_ACTIVITIES_QUERY,
        }).subscribe((data) => {
          this.hasDataLoaded = true;
          this.patientStateService.setCarePathwayActivites = data.data['getActivities'];
        });
    });
  }

  reloadActivities(activityFilter: Map<string, string>): void {
    this.patientService.getCarePathway(this.patientStateService.patientId, activityFilter).subscribe((data) => {
      this.patientStateService.setCarePathwayActivites = data ?? [];
    });
  }

  openEditCarePathway(): void {
    this.dialog.open(EditCarePathwayComponent, {
      viewContainerRef: this.viewRef,
      minWidth: '500px',
      autoFocus: false,
      data: {
        carePathWayActivities: this.carePathWayActivities
      }
    });
  }
}
