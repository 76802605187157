<div>
  <h2 mat-dialog-title>{{"care_pathway.edit_care_pathway_text" | translate}}</h2>
  <mat-dialog-content class="mat-typography">
    <!-- <app-activity-types [showSeprately]='true' [activityTypes]='activityTypes'></app-activity-types> -->
    <app-monthly-calendar [medicalContent]="medicalContent" [activityTypes]='activityTypes' [editable]='true' (editActivity)="editActivity($event)" 
     (addActivity)="addActivity($event)" (deleteActivity)="deleteActivity($event)"
    [activities]="data.carePathWayActivities"></app-monthly-calendar>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="dh-btn dh-btn-secondary">{{'close' | translate}}</button>
  </mat-dialog-actions>
</div>
