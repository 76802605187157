import * as moment from 'moment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, of, Subscription } from 'rxjs';
import { LangType, StateService } from '../../../core/services/state.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { distinct, filter } from 'rxjs/operators';
import { HealthAssessment } from '../../../core/model/patient.interface';
import { PatientStateService } from 'src/app/core/services/patient-state.service';

export const DISEASE_CONTROL_EN = "disease";
export const DISEASE_CONTROL_NL = "ziektecontrole";
export const QUALITY_OF_LIFE_EN = "quality";
export const QUALITY_OF_LIFE_NL = "kwaliteit";
export const PRODUCTIVITY_EN = 'productivity';
export const PRODUCTIVITY_NL = 'productiviteit';

@Component({
  selector: 'app-health-assessment',
  templateUrl: './health-assessment.component.html',
  styleUrls: ['./health-assessment.component.scss']
})
export class HealthAssessmentComponent implements OnInit, OnDestroy {

  mappedResponse: any;
  historyResponses: any;
  patientId: string;
  diseaseId: string;
  disId: string;
  providerId = '';
  totalScore: string;
  cpActivityId: string;
  lang: LangType;
  hasHealthAssessment = true;
  loadingCompleted: boolean;
  activityId: any;
  questionnaireId: string;
  getHealthAssesmentSubscription: Subscription;
  getHealthAssesmentHistorySubscription: Subscription;
  questionnaireShownOnDashboard: Array<string>

  constructor(private http: HttpClient, private route: ActivatedRoute, private stateService: StateService,
    private patientStateService: PatientStateService, private patientService: PatientService) {
    this.patientId = this.route.snapshot.paramMap.get('id');
    this.stateService.lang.pipe(distinct()).subscribe((lang) => {
      this.lang = lang;
    });
    combineLatest([
      this.stateService.medicalContentObs,
      this.patientStateService.diseaseIdSubject$.pipe(filter(id => !!id), distinct())
    ]).subscribe(([data, diseaseId]) => {
      this.questionnaireShownOnDashboard = data.diseases
        .filter(disease => disease.id === diseaseId)[0].questionnaireShownOnDashboard ?? []
    });
  }

  ngOnInit(): void {
    this.getHealthAssesment();
    this.getHealthAssesmentHistory();
  }

  getHealthAssesment(): any {
    this.getHealthAssesmentSubscription = combineLatest([
      this.patientStateService.patientIdSubject$.pipe(filter(id => !!id), distinct()),
      this.stateService.providerIdObs.pipe(filter(id => !!id), distinct()),
      this.patientStateService.diseaseIdSubject$.pipe(filter(id => !!id), distinct()),
    ]).subscribe(([patientId, providerId, diseaseId]) => {
      this.patientService.getHealthAssessment(providerId, patientId, diseaseId).subscribe((response: HealthAssessment) => {
        this.totalScore = response.totalScore;
        this.cpActivityId = response.cpActivityId;
        this.activityId = response.activityId;
        this.questionnaireId = response.questionnaireId
        this.mappedResponse = Object.keys(response.components).map(key => {
          const it = response.components[key];
          return {
            score: it.score,
            id: it.id,
            name: it.name,
            color: it.color1,
          };
        });
        this.loadingCompleted = true;
      }, (error: HttpErrorResponse) => {
        if (error.status == 404) {
          this.hasHealthAssessment = false;
        }
        this.loadingCompleted = true;
      });
    });
  }

  getHealthAssesmentHistory(): any {
    this.getHealthAssesmentHistorySubscription = combineLatest([
      this.patientStateService.patientIdSubject$.pipe(filter(id => !!id), distinct()),
      this.stateService.providerIdObs.pipe(filter(id => !!id), distinct()),
      this.patientStateService.diseaseIdSubject$.pipe(filter(id => !!id), distinct()),
    ]).subscribe(([patientId, providerId, diseaseId]) => {
      const fromDate = moment().subtract(2, 'years').format('YYYY-MM-DD');
      this.patientService.getHealthAssessmentHistory(providerId, patientId, diseaseId, fromDate)
        .subscribe((response: HealthAssessment[]) => {
          this.historyResponses = response;
        })
    });
  }

  ngOnDestroy() {
    this.getHealthAssesmentSubscription.unsubscribe();
    this.getHealthAssesmentHistorySubscription.unsubscribe();
  }

}
