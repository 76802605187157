<div class="solve-notification-dialog">
    <div mat-dialog-title>
        <h2>{{ 'overview_page.mark_as_solved_text' | translate }}</h2>
       
        <i class="fa fa-info-circle" matTooltip="{{'overview_page.notification_info_text' | translate}}" matTooltipPosition="right"></i>
    </div>
    <mat-dialog-content class="mat-typography content">
        <div *ngFor="let item of data.notification">
            <mat-checkbox (change)="toggleNotification(item.notificationId)">
                <ng-container [ngSwitch]="item.type">
                    <div *ngSwitchCase="notificationTypes.NEW_RESULTS" >
                        {{'overview_page.new_results' | translate}}
                    </div>
                    <div *ngSwitchCase="notificationTypes.WORSENED_RESULTS">
                        {{'overview_page.worsened_results' | translate}}
                    </div>
                    <div *ngSwitchCase="notificationTypes.LOW_HEALTH_RESULTS">
                        {{'overview_page.low_health_results' | translate}}
                    </div>
                    <div *ngSwitchCase="notificationTypes.NOT_WORSENED_RESULTS"
                        class="notification-block">
                        {{'overview_page.not_worsened_results' | translate}}
                    </div>
                    <div *ngSwitchCase="notificationTypes.RECOMMENDATION_MADE">
                        {{'overview_page.recommendation_made' | translate}}
                    </div>
                </ng-container>
            </mat-checkbox>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close class="dh-btn dh-btn-secondary mat-button mat-button-base">{{'cancel' |
            translate}}</button>
        <button (click)="solveNotification()" class="dh-btn dh-btn-primary mat-button mat-button-base"
            mat-button>{{'confirm' | translate}}</button>
    </mat-dialog-actions>
</div>