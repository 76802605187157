export const services = {
  patient: '/{providerId}/patient/{patientId}',
  patient_overview_remove_notification: '/{providerId}/patient/overview/{patientId}/notification',
  disease: '/{providerId}/disease',
  care_pathway: '/{providerId}/patient/{patientId}/care-pathway/activities',
  care_pathway_activity_details: '/{providerId}/patient/{patientId}/care-pathway/activity-details/{cpActivityId}',
  care_pathway_architecture: '/{providerId}/care-pathway-architecture',
  addons_details: '/{providerId}/care-pathway-architecture/{diseaseId}/scenario/{scenarioId}/addons',
  health_assesment: '/{providerId}/patient/{patientId}/health-assessment/{diseaseId}',
  health_assesment_history: '/{providerId}/patient/{patientId}/health-assessment/{diseaseId}/history',
  treatment_plan: '/{providerId}/patient/{patientId}/treatment-plan',
  questionnaire: '/{providerId}/questionnaire-component',
  questionnaire_res: '/{providerId}/patient/{patientId}/questionnaire-response',
  update_activity:'/{providerId}/patient/{patientId}/care-pathway/activities',
  auth: '/auth/',
  epic_auth: '/epic/',
  dev: '/dev/',
  recommendations: '/{providerId}/patient/{patientId}/recommendation',
  hix_auth: '/{providerId}/hix/auth/',
  provider_list: '/providers',
  graphql: '/graphql',
  login_practitioner: '/auth/login/practitioner',
  practitioner_challenge: '/auth/login/challenge/practitioner',
  force_new_password: '/auth/force-new-password',
  forgot_password: '/auth/forgot-password',
  forgot_password_confirm: '/auth/confirm-forgot-password',
  get_practitioner: '/{providerId}/practitioner/{practitionerId}'
};
