import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MedicalContent, MedicalContentNames, Questionnaire, QuestionnaireNames } from 'src/app/core/model/common.interface';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { APP_CONSTANTS } from '../../../../../core/constants/app.constants';
import { ECARE_QUESTIONNAIRE } from '../../../../../core/constants/questionnaire.constant';
import { QuestionnaireResponseDto } from '../../../../../core/model/patient.interface';
import { PatientService } from '../../../../../core/services/patient.service';
import { LangType, StateService } from '../../../../../core/services/state.service';
import { QuestionnaireResponseModalComponent } from '../questionnaire-response-modal/questionnaire-response-modal.component';
import * as utils from '../utils';

@Component({
    selector: 'app-questionnaire-response-list',
    templateUrl: './questionnaire-response-list.component.html',
    styleUrls: ['./questionnaire-response-list.component.scss']
})
export class QuestionnaireResponseListComponent implements OnInit {

    ECARE = ECARE_QUESTIONNAIRE

    @Input() questionnaireType: string;

    dataSource: QuestionnaireResponseDto[] = [];

    columns: string[] = ['name', 'date', 'result'];

    lang: LangType;
    dateFormat: string;
    medicalContent: MedicalContent;
    diseaseName: string;
    questionnaireNames: QuestionnaireNames;
    questionnaireData: Array<Questionnaire>

    constructor(private route: Router, private patientService: PatientService,
        private translate: TranslateService, private patientStateService: PatientStateService,
        private stateService: StateService,
        public datepipe: DatePipe,
        public dialog: MatDialog,
        private viewRef: ViewContainerRef) {
        this.stateService.lang.subscribe(res => {
            this.lang = res;
        });

        this.questionnaireNames = this.stateService.getMedicalContentNames.questionnaires
        this.stateService.medicalContentObs.subscribe((data) => {
            this.questionnaireData = data.questionnaires;
        });
    }

    ngOnInit(): void {
        this.getResponses();
        this.diseaseName = this.patientStateService.diseaseDetail.diseaseName;
        this.medicalContent = this.stateService.getMedicalContent;
        this.dateFormat = this.medicalContent.formatting.shortDateFormat
    }

    getResponses(): void {
        this.patientStateService.patientIdSubject$.subscribe(patientId => {
            this.patientService.getQuestionnaireResponse(patientId, this.questionnaireType)
                .subscribe(responses => {
                    responses.map(r => {
                        let date = new Date(r.dateCompleted);
                        r.dateCompleted = this.datepipe.transform(date, this.dateFormat)
                        return r;
                    })
                    this.dataSource = responses;
                }, (error: HttpErrorResponse) => {
                    if (error.status == 404) {
                        this.dataSource = [];
                    }
                });
        });
    }

    buildQuestionnaireName(questionnaireResponse: QuestionnaireResponseDto): any {
        const questionnareId = questionnaireResponse.questionnaireId;
        const nameFromMedicalContents = this.questionnaireNames ? this.questionnaireNames[questionnareId].name : null;
        return nameFromMedicalContents ? nameFromMedicalContents : questionnaireResponse.componentResponses[0].component.name;
    }

    openModal(sourceId: string, questionnaireId: string): void {
        const modalRef = this.dialog.open(QuestionnaireResponseModalComponent, {
            width: `1000px`,
            height: '95%',
            data: {
                'sourceId': sourceId,
                'questionnaireId': questionnaireId,
                'questionnaireName': this.questionnaireNames[questionnaireId].name,
                'type': this.questionnaireType,
                'questionnaireData': this.getQuestionnaire(questionnaireId)
            },
            viewContainerRef: this.viewRef,
            panelClass: 'question-response-dialog'
        });
    }

    getScore(element: QuestionnaireResponseDto) {
        const questionnaire = this.getQuestionnaire(element.questionnaireId)
        return utils.showQuestionnaireAsFraction(questionnaire)
            ? `${element.score} / ${utils.getMaxScore(element)}`
            : `${Math.round(element.score)}%`;
    }

    getQuestionnaire(questionnaireId: string) {
        return this.questionnaireData.filter(questionnaire => questionnaire.id == questionnaireId)[0];
    }

    badScore(element: QuestionnaireResponseDto) {
        const questionnaire = this.getQuestionnaire(element.questionnaireId);
        return utils.isInverted(questionnaire)
            ? element.score > questionnaire.alertThreshold
            : element.score < questionnaire.alertThreshold;
    }

    isScorable(questionnaireId: string) {
        const questionnaire = this.getQuestionnaire(questionnaireId);
        return questionnaire?.scorable || questionnaire?.isHealthAssesment;
    }
}
