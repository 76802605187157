import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StateService } from 'src/app/core/services/state.service';
import { ChartConfiguration } from 'chart.js';
import { MedicalContent } from 'src/app/core/model/common.interface';
import * as chartsData from '../../../../shared/data/chartsjs';

@Component({
  selector: 'app-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.scss']
})
export class BarGraphComponent implements OnInit, OnChanges {


  @Input() chartDatas: ChartConfiguration<'bar'>['data'];
  @Input() type: string;

  medicalContents: MedicalContent;
  public chartOptions = {};


  constructor(private stateService: StateService) {
    this.stateService.medicalContentObs.subscribe((data) => {
      this.medicalContents = data;

    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type?.currentValue !== changes.type?.previousValue) {
      this.chartOptions = chartsData.barChartOptions(this.medicalContents.formatting.shortDateFormat);
    }
  }

  ngOnInit(): void {
    
  }

}
