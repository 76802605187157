<div class="add-activity-container">
    <h2 mat-dialog-title>{{'care_pathway.remove_activity.header' | translate}}</h2>
    <mat-dialog-content class="mat-typography">
        <p class="info-text">{{'care_pathway.remove_activity.info_text' | translate: {name: selectedActivityName} }}</p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="dh-btn dh-btn-secondary">{{'cancel' | translate}}</button>
      <button (click)="delete()" class="dh-btn dh-btn-primary"
       mat-button> {{'care_pathway.remove_activity.remove_text' | translate}}</button>
    </mat-dialog-actions>
  </div>
  