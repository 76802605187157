<app-header></app-header>
<div class="main-content row">
    <div class="container" *ngIf="hasMedicalContentLoaded">
        <app-loader></app-loader>
        <div class="section-wrapper">
            <app-property></app-property>
            <app-patient-menu></app-patient-menu>
        </div>
        <app-navbar></app-navbar>
        <div>
            <mat-button-toggle-group class="tab-panel" [(ngModel)]="selectedTab">
                <mat-button-toggle value="summary">
                    <strong>
                        <i class="fa fa-chart-area" aria-hidden="true"></i>
                        {{'summary_tab_title' | translate}}
                    </strong>
                </mat-button-toggle>
                <mat-button-toggle value="profile">
                    <strong>
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{'profile_tab_title' | translate}}
                    </strong>
                </mat-button-toggle>
                <mat-button-toggle value="questionnaire">
                    <strong>
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                        {{'questionnaire_tab_title' | translate}}
                    </strong>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <ng-container [ngSwitch]="selectedTab">
            <div *ngSwitchCase="'summary'" class="mt-3">
                <app-questionnaire></app-questionnaire>
                <app-health-assessment></app-health-assessment>
                <app-disease-specific-graph></app-disease-specific-graph>
                <div class="row feature-block">
                    <!-- <app-treatment-plan *ngIf="!enableMultiScenario" class="col-xl-6"></app-treatment-plan> -->
                    <app-multi-treatment-plan class="col-xl-6"></app-multi-treatment-plan>
                    <app-care-pathway class="col-xl-6"></app-care-pathway>
                </div>
            </div>
            <div *ngSwitchCase="'questionnaire'" class="mt-3">
                <app-questionnaire-response></app-questionnaire-response>
            </div>
            <div *ngSwitchCase="'profile'">
                <app-profile></app-profile>
            </div>
        </ng-container>

        <ng-container>
            <button *ngIf="!showChat" mat-fab class="chat-button" aria-label="Chat button" (click)="openChat()">
                <mat-icon fontSet="fa" fontIcon="fa-comment-medical"></mat-icon>
            </button>
            <app-chat *ngIf="showChat"></app-chat>
        </ng-container>
    </div>
</div>