import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DialogResponse } from 'src/app/core/constants/dialog_response.constant'
import { LangType, StateService } from 'src/app/core/services/state.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { distinct, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ScenarioAlreadyActivatedModal } from './scenario-already-activated-modal/scenario-already-activated-modal.component'
import { CarePathwayRemindedModal } from './care-pathway-reminded-modal/care-pathway-reminded-modal.component'
import { ScenarioNotFoundModal } from './scenario-not-found-modal/scenario-not-found-modal.component'
import { CommunicationService } from 'src/app/core/services/communication.service'
import { MessageService } from 'src/app/core/services/message.service';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { getTranslationForLanguage, TranslatablePipe } from 'src/app/shared/pipes/translatable.pipe';
import jsonLogic from 'json-logic-js';
import { SUBMIT_PRACTITIONER_QUESTIONNAIRE } from 'src/app/core/constants/graphql.query.constants';
import { LaunchDarklyService } from 'src/app/core/services/launchDarkly.service';
import { Apollo } from 'apollo-angular';

const RECOMMENDER = "RECOMMENDER"

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
    title = 'DEAR-Recommender ©';
    isLinear = true;
    completed = false;
    questionData: any;
    questionnaireThroughQsr: boolean = false
    activeQuestionData: any;
    questionnaireData: any;
    stepNumber = 0;
    maxStepNum = 0;
    lang: LangType;
    myFormGroup: FormGroup;
    recommendationFormGroup: FormGroup;
    questionnaireId: string;
    recommendations: any;
    showRecommender: boolean;
    names: any
    processingRecommendation: boolean = false

    constructor(
        private patientService: PatientService, private stateService: StateService, private patientStateService: PatientStateService,
        private builder: FormBuilder,
        public dialog: MatDialog, private communicationService: CommunicationService,
        private messageService: MessageService,
        private viewRef: ViewContainerRef,
        private apollo: Apollo,
        private launchDarklyService: LaunchDarklyService
    ) {
        this.myFormGroup = this.builder.group({});
        this.recommendationFormGroup = new FormGroup({
            formArray: this.builder.array([])
        });
        this.stateService.medicalContentNamesObs.subscribe(result =>
            this.names = result
        )
        communicationService.rejectOpenRecommendation$.subscribe(
            openRecommendationId => {
                this.rejectRecommendation(openRecommendationId);
            }
        )
    }

    ngOnInit(): void {
        this.stateService.lang.subscribe(res => {
            this.lang = res;
        });
        this.getRecommendations();
        this.getQuestionnaire();
        this.questionnaireThroughQsr = this.launchDarklyService.ldClient.variation('recommendations-through-qsr');
        this.launchDarklyService.flagChange.subscribe((flags) => {
            this.questionnaireThroughQsr = flags['recommendations-through-qsr'].current;
        });
    }

    getQuestionText(question) {
        return question.question[this.lang];
    }
    getRecommendations(skip_loader: boolean = false): void {
        combineLatest([
            this.patientStateService.patientIdSubject$.pipe(filter(id => !!id), distinct()),
        ]).subscribe(([patientId]) => {
            this.patientService.getRecommendations(patientId, skip_loader).subscribe(r => {
                if (r[`openRecommendations`].length >= 1)
                    this.processingRecommendation = false
                this.recommendations = r[`openRecommendations`];
            });
        });
    }

    getQuestionnaire(): void {
        this.patientStateService.diseaseIdSubject$.pipe(filter(id => !!id), distinct()).subscribe(diseaseId => {
            const recommenderId = this.stateService.getMedicalContent.diseases
                .filter(diseaseConfig => diseaseConfig.id === diseaseId)[0].recommender;
            if (recommenderId) {
                this.stateService.getRecommenderData(recommenderId).subscribe(result => {
                    this.questionnaireId = recommenderId;
                    this.questionData = result['components'][0].questions;
                    this.showRecommender = true;
                    this.setActiveQuestionData();
                    this.setMaxStepNum();
                    const group = {};
                    this.questionData.forEach((ques: any) => {
                        group[ques.id] = [null, Validators.required];
                    });
                    this.myFormGroup = this.builder.group(group);
                });
            } else {
                this.showRecommender = false
            }
        });
    }

    shouldShowQuestion(question): boolean {
        const values = this.myFormGroup.value;
        return jsonLogic.apply(question.conditionalLogic, values)
    }

    setActiveQuestionData(): void {
        this.activeQuestionData = this.questionData.filter((question: any) => this.shouldShowQuestion(question));
    }

    setMaxStepNum(): void {
        this.maxStepNum = this.activeQuestionData.length + 1;
    }

    showStep(show: boolean): void {
        const welcomeDiv = document.getElementById('welcome');
        if (welcomeDiv) {
            welcomeDiv.style.display = show ? 'none' : 'block';
        }

        const stepDiv = document.getElementById('steps');
        if (stepDiv) {
            stepDiv.style.display = show ? 'block' : 'none';
        }

        const recommendationsFormDiv = document.getElementById('recommendationsForm');
        if (recommendationsFormDiv) {
            recommendationsFormDiv.style.display = show ? 'none' : 'block';
        }
    }

    stepValueSelect(): void {
        setTimeout(() => {
            this.setActiveQuestionData();
            this.setMaxStepNum();
        }, 0);
    }

    restoreQuestionInitialState(conditionalLogic: any): void {
        let targetQuestion = this.questionData.find((q: any) => q.id === conditionalLogic.targetQuestion);
        targetQuestion.currentState = targetQuestion.initialState;
        this.setActiveQuestionData();
    }

    stepNumInc(key, stepper): void {
        if (key && this.myFormGroup.get(key).value != null) {
            stepper.next();
        }
        this.stepNumber++;
        if (this.stepNumber > this.maxStepNum) {
            this.stepNumber = this.maxStepNum;
        }
    }

    stepNumDec(): void {
        this.stepNumber--;
        if (this.stepNumber < 0) {
            const welcomeDiv = document.getElementById('welcome');
            const stepDiv = document.getElementById('steps');
            welcomeDiv.style.display = 'block';
            stepDiv.style.display = 'none';
            this.stepNumber = 0;
        }
    }

    onSubmit(stepper: any): void {
        const formData = this.myFormGroup.value;
        const response = this.buildResponse();
        if (this.questionnaireThroughQsr) {
            this.apollo.mutate({
                mutation: SUBMIT_PRACTITIONER_QUESTIONNAIRE,
                variables: {
                    input: {
                        diseaseId: this.patientStateService.diseaseId,
                        questionnaireId: this.questionnaireId,
                        responses: response,
                        type: RECOMMENDER
                    }
                }
            }).subscribe(async ({ data }) => {
                this.goBackToRecommendations(stepper);
                this.processingRecommendation = true
                let counter = 0;
                while (this.processingRecommendation && counter <= 30) {
                    this.getRecommendations(true);
                    await this.delay(2000);
                    counter++;
                }
            });
        } else {
            this.patientService.postQuestionnaire1(this.patientStateService.patientId, this.patientStateService.diseaseId, JSON.stringify(response)).subscribe(
                value => {
                    this.recommendations = [value];
                    this.goBackToRecommendations(stepper);
                }
            );
        }
    }
    goBackToRecommendations(stepper: any): void {
        this.showStep(false);
        this.stepNumber = 0;
        this.myFormGroup.reset();
        this.setActiveQuestionData();
        try {
            stepper.reset();
        } catch (Exception) {
            // reset function, throws undefined harmless exception, wrap just to remove it from console
        }
    }

    acceptRecommendation(recommendationId: string, activateScenario: string, action?: string): void {
        if (this.questionnaireThroughQsr) {
            if (action === 'ACTIVATE_SCENARIO') {
                this.communicationService.activateRecommendationScenario(activateScenario);
            }
        } else {
            this.communicationService.activateRecommendationScenario(activateScenario);
        }
        this.updateRecommendation(recommendationId, `ACCEPT`);
    }

    rejectRecommendation(recommendationId: string): void {
        this.updateRecommendation(recommendationId, `REJECT`);
    }

    updateRecommendation(recommendationId: string, actionn: string): void {
        const payload: any = {
            action: actionn,
            diseaseId: this.patientStateService.diseaseId
        };
        this.patientService.acceptRejectRecommendation(this.patientStateService.patientId, recommendationId, JSON.stringify(payload))
            .subscribe(
                value => this.onSuccessAcceptRejectRecommendation(value, recommendationId, actionn !== 'REJECT'),
                error => this.onErrorAcceptRejectRecommendation(error, recommendationId)
            );
    }

    delay = ms => new Promise(res => setTimeout(res, ms));


    private onErrorAcceptRejectRecommendation(error: any, recommendationId: string) {
        const reason = JSON.parse(error.error?.error)?.error;
        switch (reason) {
            case 'PATIENT_ALREADY_ON_THIS_SCENARIO_EXCEPTION':
                const alreadyOnScenarioDialog = this.dialog.open(ScenarioAlreadyActivatedModal, { viewContainerRef: this.viewRef });

                alreadyOnScenarioDialog.afterClosed().subscribe(result => {
                    if (result === DialogResponse.CONFIRM) {
                        this.rejectRecommendation(recommendationId);
                    } else if (result === DialogResponse.REJECT) {
                        const scenarioId = error.error?.details?.data?.scenario_id
                        if (scenarioId) {
                            this.communicationService.deactivateTreatmentPlan(scenarioId, recommendationId)
                        }
                        this.dialog.open(CarePathwayRemindedModal, { viewContainerRef: this.viewRef });
                    }
                });
                break;
            case 'COMPLETE_SCENARIO_NOT_FOUND_EXCEPTION':
                const notFounDialog = this.dialog.open(ScenarioNotFoundModal, { viewContainerRef: this.viewRef });
                notFounDialog.afterClosed().subscribe(result => {
                    this.rejectRecommendation(recommendationId);
                })
                break;
            default:
                this.messageService.showHttpError(error);
                break;
        }
    }

    private onSuccessAcceptRejectRecommendation(value: any, recommendationId: string, reloadCarePathway: boolean) {
        // Remove target open recommendation from the list of recommendation. If we just instantly reload list, recommendation could be still in response.
        this.recommendations.splice(this.recommendations.map((r) => r.recommendationId).indexOf(recommendationId), 1);
        if (reloadCarePathway) {
            this.communicationService.reloadActivities();
        }
    }

    public optionCompareFunc(option, value): boolean {
        return option != null && value != null && option.id === value.id;
    }

    public getOptionQsrValue(option: string, questionId: string) {
        const question = this.questionData.filter(question => question.id === questionId)[0];
        return question.optionMapping[option].index;
    }

    private buildResponse() {
        let response = [];
        const formData = this.myFormGroup.value;
        if (this.questionnaireThroughQsr) {
            this.activeQuestionData.forEach(question => {
                let qData = formData[question.id];
                let questionOptions
                if (Array.isArray(qData)) {
                    questionOptions = qData.map(value => this.getOptionQsrValue(value, question.id)).join();
                } else {
                    questionOptions = String(this.getOptionQsrValue(qData, question.id));
                }
                response.push({ questionId: question.id, response: questionOptions })
            });
            return response
        }
        const oldResponse = {};
        this.activeQuestionData.forEach(question => {
            let questionOptions = [];
            let qData = formData[question.id];
            if (Array.isArray(qData)) {
                questionOptions = qData.map(el => el);
            } else {
                questionOptions.push(qData);
            }
            oldResponse[question.id] = questionOptions;
        });
        return oldResponse;
    }
}
