<div class="navbar">
    <mat-button-toggle-group name="Navigation" class="nav-toggle-group" aria-label="Navigation"
        [(ngModel)]="selectedTab">
        <ng-container *ngFor="let tab of tabs">
            <mat-button-toggle value="{{ tab }}"> {{tab}}</mat-button-toggle>
        </ng-container>
    </mat-button-toggle-group>
</div>


<ngContainer *ngIf="selectedTab=='Patient'; else showForm">
    <div class="profile" *ngIf="hasLoaded && medicalContent">
        <div class="card-form">
            <div class="header">
                <div class="card-title">
                    {{ 'patient_profile.general_info_title' | translate }}
                </div>
                <div>
                    <a class="button-link cancel" (click)="cancelGeneralInfo()">
                        {{ isGeneralInfoEditable ? ('cancel' | translate) : '' }}
                    </a>
                    <a class="button-link" [ngClass]="{'disabled': !this.generalFormGroup.valid}"
                        (click)="this.generalFormGroup.valid && changeGeneralInfoEditState()">{{ isGeneralInfoEditable ?
                        ('patient_profile.save' | translate) :
                        ('patient_profile.edit' | translate) }}</a>
                </div>
            </div>
            <div class="content">
                <app-general-form [formGroup]="generalFormGroup"
                    [isEditable]="isGeneralInfoEditable"></app-general-form>
            </div>
        </div>

        <div class="card-form">
            <div class="header">
                <div class="card-title">
                    {{ 'patient_profile.contact_info_title' | translate }}
                </div>
                <div>
                    <a class="button-link cancel" (click)="cancelContact()">
                        {{ isContactEditable ? ('cancel' | translate) : '' }}
                    </a>
                    <a class="button-link" [ngClass]="{'disabled': !this.contactFormGroup.valid}"
                        (click)="this.contactFormGroup.valid && changeContactEditState()">{{ isContactEditable ?
                        ('patient_profile.save'
                        | translate) :
                        ('patient_profile.edit' | translate) }}</a>
                </div>
            </div>
            <div class="content">
                <app-contact-form [formGroup]="contactFormGroup" [isEditable]="isContactEditable"
                    [isEditScreen]="true"></app-contact-form>
            </div>
        </div>

        <div class="card-form">
            <div class="header">
                <div class="card-title">
                    {{ 'patient_profile.care_title_text' | translate }}
                </div>
                <div>
                    <a class="button-link cancel" (click)="cancelCare()">
                        {{ isCareEditable ? ('cancel' | translate) : '' }}
                    </a>
                    <a class="button-link" [ngClass]="{'disabled': !this.careFormGroup.valid}"
                        (click)="this.careFormGroup.valid && changeCareEditState()">{{ isCareEditable ?
                        ('patient_profile.save' | translate) :
                        ('patient_profile.edit' | translate) }}</a>
                </div>

            </div>
            <div class="content">
                <app-care-form [formGroup]="careFormGroup" [isEditable]="isCareEditable" [isEditScreen]="true"
                    [emrIntergrationEnabled]="emrIntergrationEnabled">
                </app-care-form>
            </div>
        </div>
    </div>
</ngContainer>

<ng-template #showForm>
    <ng-container>
        <div class="profile">
            <ng-container *ngFor="let tabData of tabDatas">
                <ng-container *ngIf="tabData.tabName == selectedTab">
                    <div *ngFor="let form of tabData.forms" class="card-form">
                        <app-form-display (formSubmittedEvent)=updateFormDataMap($event) [form]="form"
                            [formData]="formDatasMap.get(form.formName)"
                            [displayMode]="form.readOnly || false"></app-form-display>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-template>