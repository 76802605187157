import { Component, OnInit, AfterViewInit, ViewChild, Output, OnDestroy, ViewContainerRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { PatientOverviewItem } from '../../../core/model/patient.interface';
import { APP_CONSTANTS, NOTIFICATION_TYPES, PatientOverviewSource, SortOrder } from "../../../core/constants/app.constants";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PatientService } from 'src/app/core/services/patient.service';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { PatientListDataSource } from './patient-list-data-source';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { TranslateService } from '@ngx-translate/core';
import { CreatePatientComponent } from '../create-patient/create-patient.component';
import { CareTeam, MedicalContent, Scenario, TreatmentPlanTypesConfig } from 'src/app/core/model/common.interface';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SolveNotificationComponent } from './solve-notification/solve-notification.component';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { Apollo } from 'apollo-angular';
import { FormControl } from '@angular/forms';
import jwt_decode from "jwt-decode";
import { Disease } from '../../../core/model/common.interface';


export enum SEARCH_TYPES {
  STRING_SEARCH = 'search',
  YEAR_SEARCH = 'search_birth_year',
  DATE_SEARH = 'search_birth_date',
  MONTH_DAY_SEARCH = 'search_birth_month_day',
}

export const ACCESSED_VIA = 'access_via';
export const PRACTITIONER_ACCESS = 'practitioner';
export const DEPARTMENT_ACCESS = 'department'

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})


export class PatientListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  displayedColumns = ['externalId', 'lastName', 'initials', 'birthDate', 'lastLogin', 'disease', 'treatmentPlan','notifications', 'more'];
  dataSource: PatientListDataSource;
  feedbacks: PatientOverviewItem[] = [];
  pageSize = localStorage.getItem('pageSize') === null ? 10 : parseInt(localStorage.getItem('pageSize'));
  pageSizeSubject$ = new BehaviorSubject<number>(localStorage.getItem('pageSize') === null ? this.pageSize : parseInt(localStorage.getItem('pageSize')));
  lengthSubject$ = new BehaviorSubject<number>(null);
  filter = '';
  searchEvent = new Subject<string>();
  dateFormat: string;
  lang: LangType;
  notificationTypes = NOTIFICATION_TYPES;
  initialSort = '';
  initialDirection = '';
  currentElement: any;
  environment: any;
  sourceTypes = PatientOverviewSource;
  source = PatientOverviewSource.BY_BOTH;
  searchData: any = {};
  filterData: any = {}
  hasFilterEnabled: boolean = false;
  diseases: Disease[];
  scenarios: Scenario[] = [] as Scenario[];

  notificationFilter = new FormControl('');
  diseaseFilter = new FormControl('');
  scenarioFilter = new FormControl('');

  private subs: Subscription[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() loaderState = new BehaviorSubject<boolean>(true);
  @Input() medicalContent:MedicalContent;

  constructor(private route: Router, private patientService: PatientService, private communicationService: CommunicationService,
    private translate: TranslateService, private stateService: StateService, private http: HttpClient,
    private dialogRef: MatDialog, private viewRef: ViewContainerRef, private apollo: Apollo) {
    stateService.patientOverviewSettings.subscribe((settings: object) => {
      this.initialSort = settings['sort'];
      this.initialDirection = settings['direction'].toLowerCase();
    });
    this.dataSource = new PatientListDataSource(this.patientService, this.translate, this.stateService, this.apollo, this.http, this.communicationService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.medicalContent.currentValue){
    this.dataSource.loadPatientList(0, this.pageSizeSubject$.value, null, this.initialSort, this.initialDirection?.toUpperCase());
    }
  }

  ngOnInit(): void {
    const decoded: any = jwt_decode(sessionStorage.getItem('pracAuthToken'));
    this.environment = environment;
    this.lengthSubject$ = this.dataSource.lengthSubject$;
    this.stateService.lang.subscribe(res => {
      this.lang = res;
    });

    this.communicationService.reloadOverview$.subscribe(() => {
      this.loadPatientListPage();
    });


    this.stateService.medicalContentObs.subscribe((data: MedicalContent) => {
      if(data) {
        const careTeams: string[] = decoded.careTeams;
        const filteredDiseases = [];
        careTeams.forEach((careTeamId: string) => {
          const filderedData = data.careTeams?.filter((careTeam: CareTeam) => {
           return careTeamId === careTeam.id
          })[0];
          if(filderedData) {
            filteredDiseases.push(filderedData.diseases);
          }
        });
        this.diseases = filteredDiseases.flat() as Disease[];
      }
    });

    this.stateService.medicalContentObs.subscribe((data) => {
      this.medicalContent = this.stateService.getMedicalContent;
      this.dateFormat = this.medicalContent?.formatting.shortDateFormat;
    });

    this.searchEvent.pipe(
      debounceTime(300),
      map(value => (value.length >= 2 ? value : '')),
      distinctUntilChanged())
      .subscribe(value => {
        if (!this.dataSource) { return; }
        this.filter = value;
        this.loadPatientListPage();
        this.paginator.pageIndex = 0;
        this.dataSource.clearPageKeys();
      });
  }

  sortData(event: object) {
    this.stateService.setPatientOverviewSettings(event['active'], event['direction']);
  }

  ngOnDestroy(): void {
    this.searchEvent.unsubscribe();
    this.subs.forEach((s) => s.unsubscribe());
  }

  loadPatientListPage(): void {
    // Update page size with total length of response. The first time it will emit
    // the existing value of the behavior, so wait for the second value.
    this.lengthSubject$.pipe(take(2)).subscribe(length => {
      if(this.lengthSubject$.value < this.pageSize){
        this.pageSizeSubject$.next(this.lengthSubject$.value);
      }else{
        this.pageSizeSubject$.next(this.pageSize);
      }
    });
    this.searchData = this.filter.trim() !== '' ? this.getFilterObject(this.filter.trim()) : {};
    this.dataSource.loadPatientList(this.paginator.pageIndex, this.pageSize, this.searchData, this.sort.active, SortOrder[this.sort.direction], this.source, this.filterData);
  }

  selectPatientsAssigned(event: MatRadioChange): void {
    this.source = event.value;
    this.dataSource.clearPageKeys();
    this.paginator.pageIndex = 0;
    this.loadPatientListPage();
  }

  clearSearch(): void {
    this.searchEvent.next('');
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadPatientListPage()
    })
  }

  onChangePageSize(pe: PageEvent) {
    this.pageSize = pe.pageSize;
    this.pageSizeSubject$.next(this.pageSize);
    localStorage.setItem("pageSize", this.pageSize.toString());
    this.loadPatientListPage();
  }

  goToPatient(patientId: string): void {
    sessionStorage.setItem('patientId', patientId);
    this.route.navigate([`/patient/${patientId}`], { state: { patientId: patientId } });
  }

  goToEditPatient(): void {
    sessionStorage.setItem('patientId', this.currentElement.patientId);
    this.route.navigate([`/patient/${this.currentElement.patientId}/${true}`], { state: { patientId: this.currentElement.patientId } });
  }

  goToQuestionnaire(element?: any): void {
    if (element) {
      this.setCurrentElement(element);
    }
    sessionStorage.setItem('patientId', this.currentElement.patientId);
    this.route.navigate([`/patient/${this.currentElement.patientId}/questionnaire`], { state: { patientId: this.currentElement.patientId } });
  }

  /**
   * Function to formulate the input search string according to the needs of backend
   * @param searchCriteria 
   * returns the search criteria in the format of key, value format
   */
  getFilterObject(searchCriteria: string): any {
    return {
      'search': searchCriteria
    }
  }

  openCreatePatientModal() {
    this.dialogRef.open(CreatePatientComponent, {
      width: '700px',
      disableClose: true,
      viewContainerRef: this.viewRef,
      data: this.diseases
    })
  }

  setCurrentElement(element) {
    this.currentElement = element;
  }

  openSolveNotificationDialog(): void {
    const solveNotificationModal = this.dialogRef.open(SolveNotificationComponent, {
      width: '400px',
      data: { notification: this.currentElement.notifications, patientId: this.currentElement.patientId, diseaseId: this.currentElement.diseaseId }
    });

    solveNotificationModal.afterClosed().subscribe((notificationIds: string[]) => {
      if (notificationIds.length > 0) {
        const filteredNotifications = this.currentElement.notifications.filter((data) => !notificationIds.includes(data.notificationId));
        this.currentElement.notifications = filteredNotifications;
        this.dataSource.refreshData();
      }
    });
  }
  toggleFilter(): void {
    this.hasFilterEnabled = !this.hasFilterEnabled;
    if (!this.hasFilterEnabled) {
      this.notificationFilter.setValue('');
      this.diseaseFilter.setValue('');
      this.scenarioFilter.setValue('');
      this.updateFilterData();
      this.dataSource.loadPatientList(this.paginator.pageIndex, this.pageSize, this.searchData, this.sort.active, SortOrder[this.sort.direction], this.source, this.filterData);
    }
  }
  updateFilterData() {
    this.filterData = {
      ...(this.scenarioFilter.value) && { scenarios: [this.scenarioFilter.value] },
      ...(this.diseaseFilter.value) && { diseases: [this.diseaseFilter.value] },
      ...(this.notificationFilter.value) && { notificationTypes: [NOTIFICATION_TYPES[this.notificationFilter.value]] }
    }
    this.dataSource.loadPatientList(this.paginator.pageIndex, this.pageSize, this.searchData, this.sort.active, SortOrder[this.sort.direction], this.source, this.filterData);
  }
  updateDiseaseFilter() {
    this.scenarioFilter.setValue('');
    this.updateFilterData();
    this.setScenarios();
    
  }

  setScenarios() {
    this.scenarios = [] as Scenario[];
    const allScenarios: Scenario[] = this.stateService.getScenarios.filter(data => data.diseaseId === this.diseaseFilter.value);
    const filteredDisease: Disease = this.stateService.getMedicalContent.diseases.filter(disease => disease.id === this.diseaseFilter.value)[0];
    const mainScenarioIds: String[] = filteredDisease.treatmentPlanTypesConfig.filter(treatmentPlanType => treatmentPlanType.showAsActiveScenarioTitle === true)[0].scenarioIds;
    allScenarios.forEach((scenario: Scenario) => {
      if(mainScenarioIds.includes(scenario.id)) {
        this.scenarios.push(scenario);
      }
    })
  }
}
